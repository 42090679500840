<template>
  <v-dialog v-model="show_hide" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-toolbar dark color="black">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title v-html="bloc.name" v-if="bloc"></v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-container v-if="bloc">
        <span class="title">Titre du bloc de texte</span>
        <vue-editor style="height:100px;" :editor-toolbar="customTitleToolbar" v-model="bloc.name" />
        <v-divider></v-divider>
        <p></p>
        <v-divider></v-divider>
        <v-tabs v-model="tab" background-color="blue" color="basil" grow>
          <v-tab v-for="item in levels" :key="item">
            <v-icon small color="black">mdi-lead-pencil</v-icon>
            &nbsp;{{ item }}
          </v-tab>
          <v-tab key="settings" class="orange">
            <v-icon small color="white">mdi-settings-pencil</v-icon>&nbsp;Paramètres
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card color="basil" flat>
              <vue-editor :editor-toolbar="customToolbar" v-model="bloc.content.level_1.html" />
              <div class="flex">
                <v-text-field
                  label="URL de l'image associée (biographie, ...)"
                  prepend-inner-icon="mdi-file-image"
                  v-model="bloc.content.level_1.url"
                  id="v-text-field-for-image"
                ></v-text-field>
                <v-btn
                  @click="showMediasLibrary(bloc.content.level_1)"
                  class="mx-2"
                  dark
                  color="primary"
                >
                  <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp; Bibliothèque
                </v-btn>
              </div>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="bloc.content.level_1.source"
                    filled
                    label="Légende de l'image"
                    row
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7">
                  <v-textarea
                    v-model="bloc.content.level_1.credits"
                    filled
                    label="Crédits de l'image"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
                <v-col cols="5">
                  <v-textarea
                    v-model="bloc.content.level_1.credits_more"
                    filled
                    label="Précision sur les crédits (non affiché)"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header color="indigo lighten-5">Texte alternatif</v-expansion-panel-header>
                <v-expansion-panel-content color="indigo lighten-5">
                  <tiptap-vuetify
                    v-model="bloc.content.level_1.alter_html"
                    :extensions="extensions"
                    class="medium"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <bloc-corriges
              :title="'Corrigés'"
              v-bind:bloc="bloc"
              v-bind:element="bloc.settings.infos.level_1"
              v-bind:level="'level_1'"
              :eleve="false"
              :professeur="true"
              :exercice="false"
            ></bloc-corriges>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <vue-editor :editor-toolbar="customToolbar" v-model="bloc.content.level_2.html" />
              <div class="flex">
                <v-text-field
                  label="URL de l'image associée (biographie, ...)"
                  prepend-inner-icon="mdi-file-image"
                  v-model="bloc.content.level_2.url"
                  id="v-text-field-for-image"
                ></v-text-field>
                <v-btn
                  @click="showMediasLibrary(bloc.content.level_2)"
                  class="mx-2"
                  dark
                  color="primary"
                >
                  <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp; Bibliothèque
                </v-btn>
              </div>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="bloc.content.level_2.source"
                    filled
                    label="Légende de l'image"
                    row
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7">
                  <v-textarea
                    v-model="bloc.content.level_2.credits"
                    filled
                    label="Crédits de l'image"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
                <v-col cols="5">
                  <v-textarea
                    v-model="bloc.content.level_2.credits_more"
                    filled
                    label="Précision sur les crédits (non affiché)"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header color="indigo lighten-5">Texte alternatif</v-expansion-panel-header>
                <v-expansion-panel-content color="indigo lighten-5">
                  <tiptap-vuetify
                    v-model="bloc.content.level_2.alter_html"
                    :extensions="extensions"
                    class="medium"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <bloc-corriges
              :title="'Corrigés'"
              v-bind:bloc="bloc"
              v-bind:element="bloc.settings.infos.level_2"
              v-bind:level="'level_2'"
              :eleve="false"
              :professeur="true"
              :exercice="false"
            ></bloc-corriges>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <vue-editor :editor-toolbar="customToolbar" v-model="bloc.content.level_3.html" />
              <div class="flex">
                <v-text-field
                  label="URL de l'image associée (biographie, ...)"
                  prepend-inner-icon="mdi-file-image"
                  v-model="bloc.content.level_3.url"
                  id="v-text-field-for-image"
                ></v-text-field>
                <v-btn
                  @click="showMediasLibrary(bloc.content.level_3)"
                  class="mx-2"
                  dark
                  color="primary"
                >
                  <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp; Bibliothèque
                </v-btn>
              </div>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="bloc.content.level_3.source"
                    filled
                    label="Légende de l'image"
                    row
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7">
                  <v-textarea
                    v-model="bloc.content.level_3.credits"
                    filled
                    label="Crédits de l'image"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
                <v-col cols="5">
                  <v-textarea
                    v-model="bloc.content.level_3.credits_more"
                    filled
                    label="Précision sur les crédits (non affiché)"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header color="indigo lighten-5">Texte alternatif</v-expansion-panel-header>
                <v-expansion-panel-content color="indigo lighten-5">
                  <tiptap-vuetify
                    v-model="bloc.content.level_3.alter_html"
                    :extensions="extensions"
                    class="medium"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <bloc-corriges
              :title="'Corrigés'"
              v-bind:bloc="bloc"
              v-bind:element="bloc.settings.infos.level_3"
              v-bind:level="'level_3'"
              :eleve="false"
              :professeur="true"
              :exercice="false"
            ></bloc-corriges>
          </v-tab-item>
          <v-tab-item>
            <bloc-settings v-bind:bloc="bloc"></bloc-settings>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      <v-card-actions class="bottom">
        <v-spacer></v-spacer>
        <v-btn outlined color="red darken-1" text @click="cancel">Annuler</v-btn>
        <v-btn outlined class="mr-2" text color="green darken-1" @click="save">Enregistrer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BlocSettings from "../blocSettings/BlocSettings.vue";
import BlocCorriges from "../../Shared/BlocCorriges.vue";
import { VueEditor } from "vue2-editor";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  Image,
  History
} from "tiptap-vuetify";
export default {
  components: {
    VueEditor,
    TiptapVuetify,
    "bloc-corriges": BlocCorriges,
    "bloc-settings": BlocSettings
  },
  data() {
    return {
      tab: null,
      levels: this.$store.getters.levels,
      name: "",
      retainFocusValue: new Boolean(false),
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ script: "sub" }, { script: "super" }],
        [{ header: [false, 3, 4, 5, 6] }],
        [{ size: ["small", false] }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        ["clean"]
      ],
      customTitleToolbar: [
        ["bold", "italic", "underline"],
        [{ script: "sub" }, { script: "super" }],
        ["clean"]
      ],
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        Bold,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3, 4, 5, 6]
            }
          }
        ],
        HorizontalRule,
        Paragraph,
        HardBreak,
        Image
      ]
    };
  },
  props: ["show_hide", "bloc"],
  methods: {
    cancel() {
      this.$emit("close");
    },
    save() {
      const payload = this.bloc;
      this.$emit("save", payload);
    },
    showMediasLibrary(field) {
      this.$emit("setSelectedMediaItem", field);
      this.$emit("showmedias", "IMAGE");
    }
  }
};
</script>

<style scoped>
.tiptap-vuetify-editor {
  border: 1px solid grey;
  margin: 10px 0;
}

.tiptap-vuetify-editor img {
  width: 100%;
  height: auto;
}

.tiptap-vuetify-editor code,
code {
  padding: 0 !important;
  background-color: transparent !important;
  top: -9px;
  position: relative;
  color: darkorange !important;
  font-weight: 700;
  margin: 0 !important;
  font-size: 13px;
}

.tiptap-vuetify-editor code:before,
code:before {
  content: "(" !important;
}

.tiptap-vuetify-editor code:after,
code:after {
  content: ")" !important;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>