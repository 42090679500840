<template>
  <v-dialog
    v-model="show_hide"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-toolbar dark color="black">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Exercice sans réponse demandée</v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-container v-if="bloc">
        <v-tabs v-model="tab" background-color="blue" color="basil" grow>
          <v-tab v-for="item in levels" :key="item">{{ item }}</v-tab>
          <v-tab class="orange">
            <v-icon small color="white">mdi-settings-pencil</v-icon
            >&nbsp;Paramètres
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-textarea
              v-model="bloc.settings.infos.level_1.consigne"
              outlined
              label="Consigne de l'exercice"
              auto-grow
              rows="2"
            ></v-textarea>
            <v-textarea
              background-color="yellow lighten-5"
              v-model="bloc.settings.infos.level_1.hint"
              filled
              label="Aide / Indice"
              auto-grow
              rows="1"
              prepend-inner-icon="mdi-lightbulb-on"
            ></v-textarea>
            <v-card color="basil" flat>
              <v-row>
                <v-col cols="12">
                  <div class="mb-12">
                    <vue-editor
                      class="mb-6"
                      style="height:140px;"
                      :editor-toolbar="customToolbar"
                      v-model="bloc.content.level_1.html"
                    />
                  </div>
                </v-col>
                <v-col cols="3">
                  <div class="img-layout">
                    <img
                      v-if="bloc.content.level_1.url && getFileType(bloc.content.level_1.url) == 'image'"
                      :src="bloc.content.level_1.url"
                      alt
                    />
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="flex">
                    <div style="flex:auto;">
                      <v-text-field
                      label="URL de l'image"
                      readonly
                      prepend-inner-icon="mdi-file-image"
                      v-model="bloc.content.level_1.url"
                      id="v-text-field-for-image"
                    ></v-text-field>
                      <v-text-field
                        label="Nom du fichier téléchargé par l'élève"
                        v-model="bloc.content.level_1.filename"
                        id="v-text-field-for-image"
                      ></v-text-field>
                    </div>
                    <v-btn
                      @click="showMediasLibrary(bloc.content.level_1)"
                      class="mx-2"
                      dark
                      color="primary"
                    >
                      <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp;
                      Bibliothèque
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-textarea
                    v-model="bloc.content.level_1.credits"
                    filled
                    label="Crédits de l'image"
                    auto-grow
                    row
                    rows="2"
                  ></v-textarea>
                </v-col>
                <v-col cols="5">
                  <v-textarea
                    v-model="bloc.content.level_1.credits_more"
                    filled
                    label="Précision sur les crédits (non affiché)"
                    auto-grow
                    row
                    rows="2"
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <div class="mt-2">
                    <bloc-corriges
                      :title="'Corrigés'"
                      v-bind:bloc="bloc"
                      v-bind:element="bloc.settings.infos.level_1"
                      v-bind:level="'level_1'"
                      :eleve="true"
                      :professeur="true"
                    ></bloc-corriges>
                  </div>
                </v-col> </v-row
            ></v-card>
          </v-tab-item>
          <v-tab-item>
            <v-textarea
              v-model="bloc.settings.infos.level_2.consigne"
              outlined
              label="Consigne de l'exercice"
              auto-grow
              rows="2"
            ></v-textarea>
            <v-textarea
              background-color="yellow lighten-5"
              v-model="bloc.settings.infos.level_2.hint"
              filled
              label="Aide / Indice"
              auto-grow
              rows="1"
              prepend-inner-icon="mdi-lightbulb-on"
            ></v-textarea>
            <v-card color="basil" flat>
              <v-row>
                <v-col cols="12">
                  <div class="mb-12">
                    <vue-editor
                      class="mb-6"
                      style="height:140px;"
                      :editor-toolbar="customToolbar"
                      v-model="bloc.content.level_2.html"
                    />
                  </div>
                </v-col>
                <v-col cols="3">
                  <div class="img-layout">
                    <img
                      v-if="bloc.content.level_2.url"
                      :src="bloc.content.level_2.url"
                      alt
                    />
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="flex">

                    <div style="flex:auto;">
                      <v-text-field
                        label="URL de l'image"
                        readonly
                        prepend-inner-icon="mdi-file-image"
                        v-model="bloc.content.level_2.url"
                        id="v-text-field-for-image"
                      ></v-text-field>
                      <v-text-field
                        label="Nom du fichier téléchargé par l'élève"
                        v-model="bloc.content.level_2.filename"
                        id="v-text-field-for-image"
                      ></v-text-field>
                    </div>
                    <v-btn
                      @click="showMediasLibrary(bloc.content.level_2)"
                      class="mx-2"
                      dark
                      color="primary"
                    >
                      <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp;
                      Bibliothèque
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-textarea
                    v-model="bloc.content.level_2.credits"
                    filled
                    label="Crédits de l'image"
                    auto-grow
                    row
                    rows="2"
                  ></v-textarea>
                </v-col>
                <v-col cols="5">
                  <v-textarea
                    v-model="bloc.content.level_2.credits_more"
                    filled
                    label="Précision sur les crédits (non affiché)"
                    auto-grow
                    row
                    rows="2"
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <div class="mt-2">
                    <bloc-corriges
                      :title="'Corrigés'"
                      v-bind:bloc="bloc"
                      v-bind:element="bloc.settings.infos.level_2"
                      v-bind:level="'level_2'"
                      :eleve="true"
                      :professeur="true"
                    ></bloc-corriges>
                  </div>
                </v-col> </v-row
            ></v-card>
          </v-tab-item>
          <v-tab-item>
            <v-textarea
              v-model="bloc.settings.infos.level_3.consigne"
              outlined
              label="Consigne de l'exercice"
              auto-grow
              rows="2"
            ></v-textarea>
            <v-textarea
              background-color="yellow lighten-5"
              v-model="bloc.settings.infos.level_3.hint"
              filled
              label="Aide / Indice"
              auto-grow
              rows="1"
              prepend-inner-icon="mdi-lightbulb-on"
            ></v-textarea>
            <v-card color="basil" flat>
              <v-row>
                <v-col cols="12">
                  <div class="mb-12">
                    <vue-editor
                      class="mb-6"
                      style="height:140px;"
                      :editor-toolbar="customToolbar"
                      v-model="bloc.content.level_3.html"
                    />
                  </div>
                </v-col>
                <v-col cols="3">
                  <div class="img-layout">
                    <img
                      v-if="bloc.content.level_3.url"
                      :src="bloc.content.level_3.url"
                      alt
                    />
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="flex">
                    <div style="flex:auto;">
                      <v-text-field
                        label="URL de l'image"
                        readonly
                        prepend-inner-icon="mdi-file-image"
                        v-model="bloc.content.level_3.url"
                        id="v-text-field-for-image"
                      ></v-text-field>
                      <v-text-field
                        label="Nom du fichier téléchargé par l'élève"
                        v-model="bloc.content.level_3.filename"
                        id="v-text-field-for-image"
                      ></v-text-field>
                    </div>
                    <v-btn
                      @click="showMediasLibrary(bloc.content.level_3)"
                      class="mx-2"
                      dark
                      color="primary"
                    >
                      <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp;
                      Bibliothèque
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-textarea
                    v-model="bloc.content.level_3.credits"
                    filled
                    label="Crédits du fichier"
                    auto-grow
                    row
                    rows="2"
                  ></v-textarea>
                </v-col>
                <v-col cols="5">
                  <v-textarea
                    v-model="bloc.content.level_3.credits_more"
                    filled
                    label="Précision sur les crédits (non affiché)"
                    auto-grow
                    row
                    rows="2"
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <div class="mt-2">
                    <bloc-corriges
                      :title="'Corrigés'"
                      v-bind:bloc="bloc"
                      v-bind:element="bloc.settings.infos.level_3"
                      v-bind:level="'level_3'"
                      :eleve="true"
                      :professeur="true"
                    ></bloc-corriges>
                  </div>
                </v-col> </v-row
            ></v-card>
          </v-tab-item>
          <v-tab-item>
            <bloc-settings v-bind:bloc="bloc"></bloc-settings>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      <v-card-actions class="bottom">
        <v-spacer></v-spacer>
        <v-btn outlined color="red darken-1" text @click="cancel"
          >Annuler</v-btn
        >
        <v-btn outlined class="mr-2" text color="green darken-1" @click="save"
          >Enregistrer</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BlocSettings from "../../Editors/blocSettings/BlocSettings.vue";
import BlocCorriges from "../../Shared/BlocCorriges.vue";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    "bloc-settings": BlocSettings,
    "bloc-corriges": BlocCorriges,
    VueEditor,
  },
  data() {
    return {
      tab: null,
      levels: this.$store.getters.levels,
      name: "",
      html_level1: "",
      html_level2: "",
      html_level3: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ script: "sub" }, { script: "super" }],
        ["link"],
        ["clean"],
      ],
    };
  },
  props: ["show_hide", "bloc"],
  methods: {
    showMediasLibrary(field) {
      this.$emit("setSelectedMediaItem", field);
      this.$emit("showmedias", "IMAGE");
    },
    cancel() {
      this.$emit("close");
    },
    getFileType(url) {
      const parts = url.split("/");
      const fileName = parts[parts.length - 1];
      const fileNameParts = fileName.split(".");
      let type = null;

      if (fileNameParts.length > 1) {
        let extension = fileNameParts[fileNameParts.length - 1];
        if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
          return "image";
        } else if (extension === "pdf") {
          return "pdf";
        }
      }

      return type;
    },
    save() {
      const payload = this.bloc;
      this.$emit("save", payload);
    },
  },
};
</script>

<style>
.tiptap-vuetify-editor {
  border: 1px solid grey;
  margin: 10px 0;
}
.tiptap-vuetify-editor img {
  width: 100%;
  height: auto;
}
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.img-layout {
  background-color: gray;
}
.img-layout img {
  width: 100%;
  height: auto;
}
</style>
