<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-app-bar-nav-icon v-bind="attrs" v-on="on">
        <v-icon color="yellow" v-on:click="askForLogout()"> mdi-logout </v-icon>
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="dialog_logout"
          max-width="290"
        >
          <v-card>
            <v-card-title class="text-h5">Deconnexion</v-card-title>
            <v-card-text>
              Vous allez vous déconnecter. Toute les modifications non envoyées
              sur le serveur
              <strong>resteront stockées sur cet appareil.</strong>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="dialog_logout = false">
                Annuler
              </v-btn>
              <v-btn color="green darken-1" text @click="logout()">
                Se déconnecter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-app-bar-nav-icon>
    </template>
    <span>Déconnexion</span>
  </v-tooltip>
</template>

<script>
export default {
  data() {
    return {
      dialog_logout: false,
    };
  },

  methods: {
    askForLogout: function () {
      this.dialog_logout = true;
    },
    logout: function () {
      this.dialog_logout = false;
      this.$emit("logout");
    },
  },
};
</script>
