<template>
  <v-dialog v-model="subsection_dialog" persistent max-width="600px">
    <template></template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Sous-section</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="8">
              <v-text-field
                label="Titre de la sous-section"
                required
                v-model="subsection_template.title"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="subsection_template.display_title"
                label="Afficher ce titre"
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Problématique"
                v-model="subsection_template.problematique"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="subsection_types"
                label="Type de la sous-section"
                v-model="subsection_template.name"
                required
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="subsection_visibility"
                label="Visibilité"
                v-model="subsection_template.visibility"
                required
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              v-if="subsection_template.name == 'PPO Point de passage'"
            >
              <v-text-field
                label="Sous-titre spécifique étude PPO"
                required
                v-model="subsection_template.ppo"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancel()">Annuler</v-btn>
        <v-btn color="green darken-1" text @click="save()">Valider</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      subsection_visibility: ["visible", "non-visible"],
      subsection_types: [
        "--- Sous sections Histoire ---",
        "Introduction",
        "Repères",
        "Etude",
        "Le coin de l'historien/de l'historienne",
        "PPO Point de passage",
        "Serious game",
        "Visite d'archive",
        "Pour aller plus loin",
        "Cours",
        "Je teste mes connaissances",
        "Je teste mes connaissances et capacités",
        "Méthode",
        "A voir, à lire, à écouter",
        "Synthèse",
        "--- Sous sections Géographie ---",
        "Entrée dans le thème",
        "Introduction",
        "Etude de cas",
        "Cartes",
        "Dossier d'actualité",
        "Géographie en débat",
        "Le coin du géographe/de la géographe",
        "Serious game",
        "Pour aller plus loin",
        "Cours",
        "Schéma bilan",
        "Je teste mes connaissances",
        "Je teste mes connaissances et capacités",
        "Méthode",
        "Le numérique en géographie",
        "Les outils du géographe",
        "A voir, à lire, à écouter",
        "Synthèse",
      ],
    };
  },
  props: ["subsection_dialog", "subsection_template"],
  methods: {
    cancel() {
      this.$emit("cancel-subsection-dialog");
    },
    save() {
      const subsection = {
        id: this.subsection_template.id,
        index: this.subsection_template.index,
        blocs: this.subsection_template.blocs,
        infos: {
          title: this.subsection_template.title,
          uuid: this.subsection_template.uuid,
          settings: {
            display_title: this.subsection_template.display_title,
            name: this.subsection_template.name,
            visibility:
              this.subsection_template.visibility !== undefined
                ? this.subsection_template.visibility
                : "visible",
            ppo: this.subsection_template.ppo,
            problematique: this.subsection_template.problematique,
          },
        },
      };
      this.$emit("save-subsection-dialog", subsection);
    },
  },
};
</script>
<style></style>
