<template>
  <v-form class="pa-4">
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="bloc.settings.only_for"
          :items="available_levels"
          label="Contenu disponible pour les niveaux :"
        ></v-select>
        <v-text-field
          label="Nom du questionnaire par étape (regroupement d'exercices)"
          v-model="bloc.settings.exercise_group"
          hide-details="auto"
        ></v-text-field>
        <em>
          <small>
            <v-icon small color="green darken-2">mdi-information</v-icon>Veuillez attribuer
            <strong>strictement le même nom</strong> à
            chaque exercice de la sous-section devant faire partie d'un même
            regroupement et
            <strong>ne pas attacher cet exercice</strong> à un
            autre contenu.
          </small>
        </em>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Nom de la section du cours"
          v-model="bloc.settings.lesson_group"
          hide-details="auto"
        ></v-text-field>
        <em>
          <small>
            <v-icon small color="green darken-2">mdi-information</v-icon>Veuillez attribuer
            <strong>strictement le même nom</strong> à
            chaque bloc de la sous-section devant faire partie d'un même cours.
          </small>
        </em>
      </v-col>
      <v-col v-if="hasAttachableBloc(bloc)" cols="12">
        <v-select
          value
          :items="bloc.exercices_list"
          v-model="bloc.settings.attached"
          label="Exercice attaché à ce bloc"
        ></v-select>
      </v-col>
      <v-col v-if="hasAttachableBloc(bloc)" cols="12">
        <v-select
          value
          :items="bloc.other_list"
          v-model="bloc.settings.other_attached"
          label="Bloc complémentaire attaché (biographie, vocabulaire, bloc attachable...)"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h4>Niveau d'importance du bloc</h4>
      </v-col>
      <v-col cols="12">
        <v-slider
          v-model="bloc.settings.level"
          label="De 0 à 10"
          max="10"
          min="0"
          thumb-color="green"
          thumb-label="always"
        ></v-slider>
      </v-col>
    </v-row>
    <v-row v-if="hasAttachableBloc(bloc)" class="pt-3">
      <v-col cols="12">
        <h4>Apparence spécifique du bloc</h4>
      </v-col>
      <v-col v-for="item in classes" v-bind:key="item.class" cols="2">
        <v-checkbox v-model="bloc.settings.classes" :label="item.label" :value="item.class"></v-checkbox>
      </v-col>
    </v-row>
    <v-row class="pt-1">
      <v-col cols="12">
        <h4>Capacités</h4>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="bloc.settings.capacities"
          :items="skills"
          :filter="customFilter"
          item-text="label"
          chips
          multiple
        ></v-autocomplete>
      </v-col>

      <v-col v-if="bloc.settings.capacities" cols="12">
        <h4>Capacités à afficher dans le bloc</h4>
      </v-col>
      <v-col v-if="bloc.settings.capacities" cols="12">
        <v-autocomplete
          v-model="bloc.settings.displayed_capacities"
          :items="bloc.settings.capacities"
          :filter="customFilter"
          item-text="label"
          chips
          multiple
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="pt-3" v-if="bloc.kind != 'FREEQUESTION' && bloc.kind != 'QCM'">
      <v-col cols="12">
        <h4>Tags (mots-clés)</h4>
      </v-col>
      <v-col cols="12">
        <v-card color="basil">
          <v-tabs v-model="tag_tabs" background-color="transparent" color="basil" grow>
            <v-tab v-for="(tag, tag_index) in bloc.settings.tags" :key="tag_index">{{ tag.label }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tag_tabs">
            <v-tab-item v-for="(tag, tag_index) in bloc.settings.tags" :key="tag_index">
              <v-card color="basil" flat>
                <v-card-text>
                  <v-combobox :label="tag.label" v-model="tag.words" multiple chips></v-combobox>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      tag_tabs: true,
      available_levels: [
        {
          text: "Élèves de tous les niveaux (par défaut)",
          value: "level_1;level_2;level_3"
        },
        {
          text: 'Élèves de niveau "Intermédiaire"',
          value: "level_1"
        },
        { text: 'Élèves de niveau "Novice"', value: "level_2" },
        { text: 'Élèves de niveau "Expert"', value: "level_3" },
        {
          text: 'Élèves de niveau "Novice" + "Intermédiaire"',
          value: "level_1;level_2"
        },
        {
          text: 'Élèves de niveau "Intermédiaire" + "Expert"',
          value: "level_1;level_3"
        }
      ],
      classes: [
        { label: "Biographie", class: "bloc-biographie" },
        { label: "Capacités", class: "bloc-capacites" },
        { label: "Cours", class: "bloc-cours" },
        { label: "Introduction", class: "bloc-introduction" },
        { label: "Notions", class: "bloc-notions" },
        { label: "Vocabulaire", class: "bloc-vocabulaire" },
        { label: "Pour aller plus loin", class: "bloc-plus-loin" },
        {
          label: "Pour aller plus loin dépliable",
          class: "bloc-aller-plus-loin"
        },
        { label: "Encadré informatif", class: "bloc-informatif" },
        { label: "Bloc attachable", class: "bloc-attachable" },
        { label: "Bloc d'aide", class: "bloc-aide" },
        {
          label: "Marges gauches/droite",
          class: "bloc-spacing-lr"
        },
        {
          label: "Marges gauches/droite ++",
          class: "bloc-spacing-lr-plus"
        },
        {
          label: "Image pleine largeur",
          class: "bloc-full-width"
        }
      ],
      skills: this.$store.getters.skills
    };
  },

  props: ["bloc", "exercices_list", "other_list"],
  mounted() {},
  watch: {
    model(val) {
      if (val.length > 5) {
        this.$nextTick(() => this.model.pop());
      }
    }
  },
  methods: {
    hasAttachableBloc(bloc) {
      let kinds = [
        "HTML",
        "IMAGE",
        "VIDEO",
        "CAPTION",
        "SVGMAP",
        "TRUEFALSE",
        "ASSOCIATIONS",
        "TEXTWITHGAPS",
        "QCM",
        "ASSOCIATIONSLINKS",
        "DRAGANDDROP",
        "WRITTENTRACE",
        "FREEQUESTION",
        "NOANSWERNEEDED",
        "TIMELINE",
        "CAPTIONDROP"
      ];
      return kinds.includes(bloc.kind) ? true : false;
    },
    isExercise(bloc) {
      let kinds = [
        "TRUEFALSE",
        "ASSOCIATIONS",
        "TEXTWITHGAPS",
        "QCM",
        "ASSOCIATIONSLINKS",
        "DRAGANDDROP",
        "WRITTENTRACE",
        "FREEQUESTION",
        "NOANSWERNEEDED"
      ];
      return kinds.includes(bloc.kind) ? true : false;
    },

    customFilter(item, queryText) {
      const textOne = item.label.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    }
  }
};
</script>

<style>
</style>