<template>
  <v-dialog v-model="show_hide" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-toolbar dark color="black">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Texte à remettre en ordre</v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-container v-if="bloc">
        <v-tabs v-model="tab" background-color="blue" color="basil" grow>
          <v-tab v-for="item in levels" :key="item">{{ item }}</v-tab>
          <v-tab class="orange">
            <v-icon small color="white">mdi-settings-pencil</v-icon>&nbsp;Paramètres
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card color="basil" flat>
              <editor
                placeholder="Consigne de l'exercice"
                v-model="bloc.settings.infos.level_1.consigne"
                :api-key="tinymceKey"
                :init="tinyMceInit"
              />
              <p></p>
              <v-alert
                outlined
                border="left"
                type="warning"
              >Veuillez saisir les différents segments du texte dans le bon ordre (le mélange se fera dans l'exercice). Séparez les différents blocs de texte par deux barres obliques '//'</v-alert>
              <p></p>
              <editor
                placeholder="Séparer les différents blocs de texte par les caractères '//'"
                v-model="bloc.content.level_1.html"
                :api-key="tinymceKey"
                :init="tinyMceCustomInit"
              />

              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_1"
                v-bind:level="'level_1'"
                :eleve="true"
                :professeur="true"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <editor
                placeholder="Consigne de l'exercice"
                v-model="bloc.settings.infos.level_2.consigne"
                :api-key="tinymceKey"
                :init="tinyMceInit"
              />
              <p></p>
              <v-alert
                outlined
                border="left"
                type="warning"
              >Veuillez saisir les différents segments du texte dans le bon ordre (le mélange se fera dans l'exercice). Séparez les différents blocs de texte par deux barres obliques '//'</v-alert>
              <p></p>
              <editor
                placeholder="Séparer les différents blocs de texte par les caractères '//'"
                v-model="bloc.content.level_2.html"
                :api-key="tinymceKey"
                :init="tinyMceCustomInit"
              />

              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_2"
                v-bind:level="'level_2'"
                :eleve="true"
                :professeur="true"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <editor
                placeholder="Consigne de l'exercice"
                v-model="bloc.settings.infos.level_3.consigne"
                :api-key="tinymceKey"
                :init="tinyMceInit"
              />
              <p></p>
              <v-alert
                outlined
                border="left"
                type="warning"
              >Veuillez saisir les différents segments du texte dans le bon ordre (le mélange se fera dans l'exercice). Séparez les différents blocs de texte par deux barres obliques '//'</v-alert>
              <p></p>
              <editor
                placeholder="Séparer les différents blocs de texte par les caractères '//'"
                v-model="bloc.content.level_3.html"
                :api-key="tinymceKey"
                :init="tinyMceCustomInit"
              />

              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_3"
                v-bind:level="'level_3'"
                :eleve="true"
                :professeur="true"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <bloc-settings v-bind:bloc="bloc"></bloc-settings>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      <v-card-actions class="bottom">
        <v-spacer></v-spacer>
        <v-btn outlined color="red darken-1" text @click="cancel">Annuler</v-btn>
        <v-btn outlined class="mr-2" text color="green darken-1" @click="save">Enregistrer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BlocSettings from "../../Editors/blocSettings/BlocSettings.vue";
import BlocCorriges from "../../Shared/BlocCorriges.vue";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    "bloc-settings": BlocSettings,
    "bloc-corriges": BlocCorriges,
    editor: Editor
  },
  data() {
    return {
      tab: null,
      levels: this.$store.getters.levels,
      name: "",
      html_level1: "",
      html_level2: "",
      html_level3: "",
      tinymceKey: this.$store.getters.tinymceKey,
      tinyMceInit: this.$store.getters.tynimceReducedConfig,
      tinyMceCustomInit: {
        selector: "textarea",
        statusbar: false,
        menubar: false,
        height: 300,
        plugins: "powerpaste",
        paste_as_text: true,
        toolbar:
          "undo redo | bold italic underline strikethrough superscript subscript "
      },
      retainFocusValue: new Boolean(false)
    };
  },
  props: ["show_hide", "bloc"],
  methods: {
    cancel() {
      this.$emit("close");
    },
    save() {
      const payload = this.bloc;
      this.$emit("save", payload);
    }
  }
};
</script>

<style>
.tiptap-vuetify-editor {
  border: 1px solid grey;
  margin: 10px 0;
}
.tiptap-vuetify-editor img {
  width: 100%;
  height: auto;
}
</style>