<template>
  <div>
    <v-dialog
      :retain-focus="retainFocusValue"
      v-model="show_hide"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="dialog-editor"
    >
      <v-toolbar dark color="black">
        <v-btn icon dark @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Question libre</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-container v-if="bloc">
          <v-tabs v-model="tab" background-color="blue" color="basil" grow>
            <v-tab v-for="item in levels" :key="item">{{ item }}</v-tab>
            <v-tab class="orange">
              <v-icon small color="white">mdi-settings-pencil</v-icon>&nbsp;Paramètres
            </v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-textarea
                v-model="bloc.settings.infos.level_1.consigne"
                outlined
                label="Consigne de l'exercice"
                auto-grow
                rows="2"
              ></v-textarea>
              <v-card v-if="bloc.content.level_1" color="basil" flat>
                <div v-for="(subitem, subindex) in bloc.content.level_1" :key="subindex">
                  <v-card v-if="subitem" class="pa-2" shaped style="width: 100%">
                    <v-row>
                      <v-col cols="11">
                        <vue-editor
                          class="mb-6"
                          style="height:140px;"
                          :editor-toolbar="customToolbar"
                          v-model="subitem.question"
                        />
                        <br />
                        <v-textarea
                          background-color="yellow lighten-5"
                          v-model="subitem.hint"
                          filled
                          label="Aide / Indice"
                          auto-grow
                          rows="1"
                          prepend-inner-icon="mdi-lightbulb-on"
                        ></v-textarea>
                        <v-textarea
                          disabled
                          background-color="green lighten-5"
                          v-model="subitem.mp3"
                          filled
                          label="Fichier mp3 (tts)"
                          auto-grow
                          rows="1"
                          prepend-inner-icon="mdi-text-to-speech"
                        ></v-textarea>

                        <div class="mb-2">
                          <small>Corrigé de la question</small>
                          <vue-editor
                            class="mb-6"
                            style="height:90px;"
                            :editor-toolbar="customToolbar"
                            v-model="subitem.corrige"
                          />
                          <br />
                        </div>
                      </v-col>
                      <v-col cols="1">
                        <v-btn
                          class="ma-2"
                          text
                          icon
                          color="red lighten-2"
                          v-on:click="showCapacities(bloc.content.level_1, subindex)"
                        >
                          <v-icon>mdi-tag-outline</v-icon>
                        </v-btn>
                        <v-btn
                          class="ma-2"
                          text
                          icon
                          color="red lighten-2"
                          v-on:click="deleteQuestion(bloc.content.level_1, subindex)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
                <v-row>
                  <v-col cols="12" class="text-center">
                    <v-btn
                      v-on:click="addQuestion(bloc.content.level_1)"
                      class="ma-2"
                      color="primary"
                      dark
                    >
                      Ajouter une question
                      <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <bloc-corriges
                  :title="'Commentaire sur les questions'"
                  v-bind:bloc="bloc"
                  v-bind:element="bloc.settings.infos.level_1"
                  v-bind:level="'level_1'"
                  :eleve="true"
                  :professeur="true"
                ></bloc-corriges>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-textarea
                v-model="bloc.settings.infos.level_2.consigne"
                outlined
                label="Consigne de l'exercice"
                auto-grow
                rows="2"
              ></v-textarea>
              <v-card v-if="bloc.content.level_2" color="basil" flat>
                <div v-for="(subitem, subindex) in bloc.content.level_2" :key="subindex">
                  <v-card v-if="subitem" class="pa-2" shaped style="width: 100%">
                    <v-row>
                      <v-col cols="11">
                        <vue-editor
                          class="mb-6"
                          style="height:140px;"
                          :editor-toolbar="customToolbar"
                          v-model="subitem.question"
                        />
                        <br />
                        <v-textarea
                          background-color="yellow lighten-5"
                          v-model="subitem.hint"
                          filled
                          label="Aide / Indice"
                          auto-grow
                          rows="1"
                          prepend-inner-icon="mdi-lightbulb-on"
                        ></v-textarea>
                        <v-textarea
                          disabled
                          background-color="green lighten-5"
                          v-model="subitem.mp3"
                          filled
                          label="Fichier mp3 (tts)"
                          auto-grow
                          rows="1"
                          prepend-inner-icon="mdi-text-to-speech"
                        ></v-textarea>

                        <div class="mb-2">
                          <small>Corrigé de la question</small>
                          <vue-editor
                            class="mb-6"
                            style="height:90px;"
                            :editor-toolbar="customToolbar"
                            v-model="subitem.corrige"
                          />
                          <br />
                        </div>
                      </v-col>
                      <v-col cols="1">
                        <v-btn
                          class="ma-2"
                          text
                          icon
                          color="red lighten-2"
                          v-on:click="showCapacities(bloc.content.level_2, subindex)"
                        >
                          <v-icon>mdi-tag-outline</v-icon>
                        </v-btn>
                        <v-btn
                          class="ma-2"
                          text
                          icon
                          color="red lighten-2"
                          v-on:click="deleteQuestion(bloc.content.level_2, subindex)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
                <v-row>
                  <v-col cols="12" class="text-center">
                    <v-btn
                      v-on:click="addQuestion(bloc.content.level_2)"
                      class="ma-2"
                      color="primary"
                      dark
                    >
                      Ajouter une question
                      <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <bloc-corriges
                  :title="'commentaire sur les questions'"
                  v-bind:bloc="bloc"
                  v-bind:element="bloc.settings.infos.level_2"
                  v-bind:level="'level_2'"
                  :eleve="true"
                  :professeur="true"
                ></bloc-corriges>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-textarea
                v-model="bloc.settings.infos.level_3.consigne"
                outlined
                label="Consigne de l'exercice"
                auto-grow
                rows="2"
              ></v-textarea>
              <v-card v-if="bloc.content.level_3" color="basil" flat>
                <div v-for="(subitem, subindex) in bloc.content.level_3" :key="subindex">
                  <v-card v-if="subitem" class="pa-2" shaped style="width: 100%">
                    <v-row>
                      <v-col cols="11">
                        <vue-editor
                          class="mb-6"
                          style="height:140px;"
                          :editor-toolbar="customToolbar"
                          v-model="subitem.question"
                        />
                        <br />
                        <v-textarea
                          background-color="yellow lighten-5"
                          v-model="subitem.hint"
                          filled
                          label="Aide / Indice"
                          auto-grow
                          rows="1"
                          prepend-inner-icon="mdi-lightbulb-on"
                        ></v-textarea>
                        <v-textarea
                          disabled
                          background-color="green lighten-5"
                          v-model="subitem.mp3"
                          filled
                          label="Fichier mp3 (tts)"
                          auto-grow
                          rows="1"
                          prepend-inner-icon="mdi-text-to-speech"
                        ></v-textarea>

                        <div class="mb-2">
                          <small>Corrigé de la question</small>
                          <vue-editor
                            class="mb-6"
                            style="height:90px;"
                            :editor-toolbar="customToolbar"
                            v-model="subitem.corrige"
                          />
                          <br />
                        </div>
                      </v-col>
                      <v-col cols="1">
                        <v-btn
                          class="ma-2"
                          text
                          icon
                          color="red lighten-2"
                          v-on:click="showCapacities(bloc.content.level_3, subindex)"
                        >
                          <v-icon>mdi-tag-outline</v-icon>
                        </v-btn>
                        <v-btn
                          class="ma-2"
                          text
                          icon
                          color="red lighten-2"
                          v-on:click="deleteQuestion(bloc.content.level_3, subindex)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
                <v-row>
                  <v-col cols="12" class="text-center">
                    <v-btn
                      v-on:click="addQuestion(bloc.content.level_3)"
                      class="ma-2"
                      color="primary"
                      dark
                    >
                      Ajouter une question
                      <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <bloc-corriges
                  :title="'commentaire sur les questions'"
                  v-bind:bloc="bloc"
                  v-bind:element="bloc.settings.infos.level_3"
                  v-bind:level="'level_3'"
                  :eleve="true"
                  :professeur="true"
                ></bloc-corriges>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <bloc-settings v-bind:bloc="bloc"></bloc-settings>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
        <v-card-actions class="bottom">
          <v-spacer></v-spacer>
          <v-btn outlined color="red darken-1" text @click="cancel">Annuler</v-btn>
          <v-btn outlined class="mr-2" text color="green darken-1" @click="save">Enregistrer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <bloc-capacities
      v-bind:current_item="current_item"
      v-bind:capacities_modal="capacities_modal"
      @close-capacities="closeCapacities"
    ></bloc-capacities>
  </div>
</template>
<script>
import BlocSettings from "../../Editors/blocSettings/BlocSettings.vue";
import BlocCapacities from "../../Editors/BlocCapacities/BlocCapacities.vue";
import BlocCorriges from "../../Shared/BlocCorriges.vue";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    "bloc-settings": BlocSettings,
    "bloc-capacities": BlocCapacities,
    "bloc-corriges": BlocCorriges,
    VueEditor
  },
  data() {
    return {
      retainFocusValue: new Boolean(false),
      current_item: { capacities: null, tags: null },
      capacities_modal: false,
      tab: null,
      levels: this.$store.getters.levels,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ script: "sub" }, { script: "super" }],
        ["link"],
        ["clean"]
      ]
    };
  },
  props: ["show_hide", "bloc"],
  methods: {
    cancel() {
      this.$emit("close");
    },
    save() {
      const payload = this.bloc;
      this.$emit("save", payload);
    },
    addQuestion(place) {
      place.push({ question: "", hint: "" });
    },
    deleteQuestion(place, index) {
      place.splice(index, 1);
    },
    showCapacities(place, index) {
      if (
        place[index].settings === undefined ||
        place[index].settings == null
      ) {
        place[index].settings = {
          capacities: [],
          tags: this.$store.getters.tags
        };
      }
      this.current_item = place[index].settings;

      this.capacities_modal = true;
    },
    closeCapacities() {
      this.capacities_modal = false;
    }
  }
};
</script>
<style>
.orange {
  background-color: orange;
}
</style>