<template>
  <v-dialog
    :retain-focus="retainFocusValue"
    v-model="show_hide"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-toolbar dark color="black">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title v-html="bloc.name" v-if="bloc"></v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-container v-if="bloc">
        <span class="title">Titre du bloc image</span>
        <tiptap-vuetify
          class="vuetify-for-title"
          v-model="bloc.name"
          :extensions="title_extensions"
        />
        <v-divider></v-divider>
        <v-tabs v-model="tab" background-color="blue" color="basil" grow>
          <v-tab v-for="item in levels" :key="item">{{ item }}</v-tab>
          <v-tab class="orange">
            <v-icon small color="white">mdi-settings-pencil</v-icon
            >&nbsp;Paramètres
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card color="basil" flat>
              <v-row>
                <v-col cols="3">
                  <div class="img-layout">
                    <img
                      v-if="bloc.content.level_1.url"
                      :src="bloc.content.level_1.url"
                      alt
                    />
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="flex">
                    <v-text-field
                      label="URL de l'image"
                      prepend-inner-icon="mdi-file-image"
                      v-model="bloc.content.level_1.url"
                      id="v-text-field-for-image"
                    ></v-text-field>
                    <v-btn
                      @click="showMediasLibrary(bloc.content.level_1)"
                      class="mx-2"
                      dark
                      color="primary"
                    >
                      <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp;
                      Bibliothèque
                    </v-btn>
                  </div>
                  <div>
                    <v-text-field
                      label="Lien externe de l'image avec ouverture d'une nouvelle fenêtre, devant débuter par 'https://'"
                      prepend-inner-icon="mdi-file-image"
                      hide-details
                      v-model="bloc.content.level_1.link"
                      id="v-text-field-for-link"
                    ></v-text-field>
                    <small class="red--text">Attention, à ne remplir que pour que l'élève aille sur cette page</small>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7">
                  <v-textarea
                    v-model="bloc.content.level_1.credits"
                    filled
                    label="Crédits de l'image"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
                <v-col cols="5">
                  <v-textarea
                    v-model="bloc.content.level_1.credits_more"
                    filled
                    hide-details
                    label="Précision sur les crédits (non affiché)"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                  <small class="red--text">Mettre ici les liens vers l'origine de l'image</small>
                </v-col>
              </v-row>
              <v-textarea
                v-model="bloc.content.level_1.description"
                filled
                label="Description de l'image (optionnel)"
                auto-grow
                rows="2"
              ></v-textarea>
              <v-textarea
                v-model="bloc.content.level_1.source"
                filled
                label="Légende du document"
                auto-grow
                row
                rows="1"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_1"
                v-bind:level="'level_1'"
                :eleve="false"
                :professeur="true"
                :exercice="false"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-row>
                <v-col cols="3">
                  <div class="img-layout">
                    <img
                      v-if="bloc.content.level_2.url"
                      :src="bloc.content.level_2.url"
                      alt
                    />
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="flex">
                    <v-text-field
                      label="URL de l'image"
                      prepend-inner-icon="mdi-file-image"
                      v-model="bloc.content.level_2.url"
                      id="v-text-field-for-image"
                    ></v-text-field>
                    <v-btn
                      @click="showMediasLibrary(bloc.content.level_2)"
                      class="mx-2"
                      dark
                      color="primary"
                    >
                      <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp;
                      Bibliothèque
                    </v-btn>
                  </div>
                  <div>
                    <v-text-field
                      label="Lien externe de l'image avec ouverture d'une nouvelle fenêtre, devant débuter par 'https://'"
                      prepend-inner-icon="mdi-file-image"
                      hide-details
                      v-model="bloc.content.level_2.link"
                      id="v-text-field-for-link"
                    ></v-text-field>
                    <small class="red--text">Attention, à ne remplir que pour que l'élève aille sur cette page</small>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7">
                  <v-textarea
                    v-model="bloc.content.level_2.credits"
                    filled
                    label="Crédits de l'image"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
                <v-col cols="5">
                  <v-textarea
                    v-model="bloc.content.level_2.credits_more"
                    filled
                    label="Précision sur les crédits (non affiché)"
                    hide-details
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                  <small class="red--text">Mettre ici les liens vers l'origine de l'image</small>
                </v-col>
              </v-row>
              <v-textarea
                v-model="bloc.content.level_2.description"
                filled
                label="Description de l'image (optionnel)"
                auto-grow
                rows="2"
              ></v-textarea>
              <v-textarea
                v-model="bloc.content.level_2.source"
                filled
                label="Légende du document"
                auto-grow
                row
                rows="1"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_2"
                v-bind:level="'level_2'"
                :eleve="false"
                :professeur="true"
                :exercice="false"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-row>
                <v-col cols="3">
                  <div class="img-layout">
                    <img
                      v-if="bloc.content.level_3.url"
                      :src="bloc.content.level_3.url"
                      alt
                    />
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="flex">
                    <v-text-field
                      label="URL de l'image"
                      prepend-inner-icon="mdi-file-image"
                      v-model="bloc.content.level_3.url"
                      id="v-text-field-for-image"
                    ></v-text-field>
                    <v-btn
                      @click="showMediasLibrary(bloc.content.level_3)"
                      class="mx-2"
                      dark
                      color="primary"
                    >
                      <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp;
                      Bibliothèque
                    </v-btn>
                  </div>
                  <div>
                    <v-text-field
                      label="Lien externe de l'image avec ouverture d'une nouvelle fenêtre, devant débuter par 'https://'"
                      prepend-inner-icon="mdi-file-image"
                      hide-details
                      v-model="bloc.content.level_3.link"
                      id="v-text-field-for-link"
                    ></v-text-field>
                    <small class="red--text">Attention, à ne remplir que pour que l'élève aille sur cette page</small>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7">
                  <v-textarea
                    v-model="bloc.content.level_3.credits"
                    filled
                    label="Crédits de l'image"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
                <v-col cols="5">
                  <v-textarea
                    v-model="bloc.content.level_3.credits_more"
                    filled
                    hide-details
                    label="Précision sur les crédits (non affiché)"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                  <small class="red--text">Mettre ici les liens vers l'origine de l'image</small>
                </v-col>
              </v-row>
              <v-textarea
                v-model="bloc.content.level_3.description"
                filled
                label="Description de l'image (optionnel)"
                auto-grow
                rows="2"
              ></v-textarea>
              <v-textarea
                v-model="bloc.content.level_3.source"
                filled
                label="Légende du document"
                auto-grow
                row
                rows="1"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_3"
                v-bind:level="'level_3'"
                :eleve="false"
                :professeur="true"
                :exercice="false"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <bloc-settings v-bind:bloc="bloc"></bloc-settings>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      <v-card-actions class="bottom">
        <v-spacer></v-spacer>
        <v-btn outlined color="red darken-1" text @click="cancel"
          >Annuler</v-btn
        >
        <v-btn outlined class="mr-2" text color="green darken-1" @click="save"
          >Enregistrer</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BlocSettings from "../blocSettings/BlocSettings.vue";
import { TiptapVuetify, Bold, Italic } from "tiptap-vuetify";
import BlocCorriges from "../../Shared/BlocCorriges.vue";

export default {
  components: {
    TiptapVuetify,
    "bloc-settings": BlocSettings,
    "bloc-corriges": BlocCorriges,
  },
  data() {
    return {
      retainFocusValue: new Boolean(false),
      title_extensions: [Bold, Italic],
      tab: null,
      levels: this.$store.getters.levels,
    };
  },
  props: ["show_hide", "bloc"],
  methods: {
    showMediasLibrary(field) {
      this.$emit("setSelectedMediaItem", field);
      this.$emit("showmedias", "IMAGE");
    },
    cancel() {
      this.$emit("close");
    },
    save() {
      const payload = this.bloc;
      this.$emit("save", payload);
    },
  },
};
</script>
<style scoped>
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.img-layout {
  background-color: gray;
}
.img-layout img {
  width: 100%;
  height: auto;
}
</style>
