<template>
  <div>
    <v-card-title v-html="bloc.name"></v-card-title>
    <v-card-text v-if="bloc.content.level_1">
      <ul v-for="(item, index) in bloc.content.level_1.items" v-bind:key="index">
        <li>
          <strong>{{ item.date }}</strong> -
          <span>{{ item.event }}</span>
        </li>
      </ul>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: ["bloc"]
};
</script>