<template>
  <v-app
    id="inspire"
    v-bind:class="{
      dyslexicFont: use_dyslexic_font,
      biggerFont: use_bigger_font,
      studentView: use_student_view,
    }"
  >
    <v-system-bar dark color="red" v-if="system_bar_message">
      <div class="network-error">{{ system_bar_message }}</div>
      <v-spacer></v-spacer>
      <v-icon @click="system_bar_message = null">mdi-close</v-icon>
    </v-system-bar>

    <v-overlay z-index="9" color="grey" opacity="0.5" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-navigation-drawer
      mobile-breakpoint="720"
      fixed
      right
      v-model="drawer"
      app
    >
      <left-app-bar></left-app-bar>
      <div v-if="this.section">
        <div class="infosection">
          <h3>{{ this.$store.state.current_section.section.name }}</h3>

          <h2>{{ this.$store.state.current_section.section.content.title }}</h2>
        </div>
        <div v-if="this.section.subsections">
          <draggable :list="this.section.subsections">
            <div
              v-for="(subsection, subsection_index) in this.section.subsections"
              :key="subsection.infos.settings.uuid"
              class="subsection"
              :class="subsection.infos.removed ? 'removed' : ''"
            >
              <v-app-bar color="dark" dense dark>
                <v-toolbar-title>
                  <small class="subsection-name">{{
                    subsection.infos.settings.name
                  }}</small>
                  <small
                    class="subsection-name-missing"
                    v-if="
                      subsection.infos.settings.name === null ||
                        subsection.infos.settings.name === undefined ||
                        subsection.infos.settings.name == ''
                    "
                    >???</small
                  >
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn
                  v-on:click="showSubsectionModal('MODIFY', subsection_index)"
                  icon
                >
                  <v-icon color="blue">mdi-pencil-circle</v-icon>
                </v-btn>
                <v-btn
                  v-on:click="askForDeleteSubSection(subsection_index)"
                  icon
                >
                  <v-icon color="red">mdi-delete-circle</v-icon>
                </v-btn>
              </v-app-bar>

              <div v-if="subsection.infos.title != ''" class="subsection-title">
                <div>
                  <v-text-field
                    class="ml-2 mr-2"
                    dark
                    dense
                    readonly
                    :value="
                      getBlocLink(section.section.id, subsection_index, null)
                    "
                  ></v-text-field>
                </div>
                <div>{{ subsection.infos.title }}</div>
              </div>

              <div class="subsection_body">
                <draggable
                  v-if="subsection.blocs"
                  class="left-elements"
                  :list="subsection.blocs"
                  group="subsections"
                >
                  <div
                    class="left-element"
                    :class="bloc.removed ? 'removed' : ''"
                    v-for="(bloc, bloc_index) in subsection.blocs"
                    :key="bloc.id"
                    @dblclick="
                      showSpecificBlocModal(
                        bloc.kind,
                        subsection_index,
                        bloc_index
                      )
                    "
                  >
                    <div class="left-element-top">
                      <h6>{{ longBlocDescription(bloc) }}</h6>
                      <bloc-levels :bloc="bloc"></bloc-levels>
                    </div>
                    <span>{{ getBlocName(bloc) }}</span>
                  </div>
                </draggable>
                <div class="subsection_add">
                  <v-btn
                    @click="showBottomSheet(subsection, subsection_index)"
                    class="mx-2"
                    small
                    fab
                    dark
                    color="indigo"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </v-navigation-drawer>

    <v-app-bar dark app>
      <v-toolbar-title v-if="this.section" class="toolbar-title">
        {{ this.section.section.content.title }}
      </v-toolbar-title>
      <div class="flex-grow-1"></div>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on">
            <v-badge
              v-if="uploadReminder"
              bottom
              left
              offset-x="5"
              offset-y="10"
              color="green"
              content="!"
            >
              <v-icon v-on:click="askForUpload()" color="green" large
                >mdi-cloud-upload-outline</v-icon
              >
            </v-badge>
            <v-icon
              v-if="!uploadReminder"
              v-on:click="askForUpload()"
              color="green"
              large
              >mdi-cloud-upload-outline</v-icon
            >
          </v-app-bar-nav-icon>
        </template>
        <span>Enregistrer</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on">
            <v-icon v-on:click="askForDowload()" color="pink"
              >mdi-cloud-download-outline</v-icon
            >
          </v-app-bar-nav-icon>
        </template>
        <span>Réinitialiser la section à partir de la version en ligne</span>
      </v-tooltip>

      <!--
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on">
            <v-icon v-on:click="showMediaLibrary('IMAGE')" color="orange">mdi-folder-multiple-image</v-icon>
          </v-app-bar-nav-icon>
        </template>
        <span>Bibliothèque de médias</span>
      </v-tooltip>
      -->

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on">
            <v-icon v-on:click="showSubsectionModal('ADD')" color="purple"
              >mdi-playlist-plus</v-icon
            >
          </v-app-bar-nav-icon>
        </template>
        <span>Ajouter une sous-section</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on">
            <v-icon @click="open_section_dialog()" color="blue"
              >mdi-information-outline</v-icon
            >
          </v-app-bar-nav-icon>
        </template>
        <span>Informations sur la section</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on">
            <v-icon v-on:click="askForDeleteSection()" color="red"
              >mdi-delete-outline</v-icon
            >
          </v-app-bar-nav-icon>
        </template>
        <span>Supprimer cette section</span>
      </v-tooltip>

      <user-settings
        v-bind:dyslexic_font="use_dyslexic_font"
        v-bind:bigger_font="use_bigger_font"
        v-bind:modal_user_settings="false"
        v-on:changeUserSettings="changeUserSettings($event)"
      ></user-settings>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on">
            <v-icon v-on:click="swapStudentView()" color="#fbf0d9"
              >mdi-eye</v-icon
            >
          </v-app-bar-nav-icon>
        </template>
        <span>Vue élève</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on">
            <v-icon v-on:click="summary()" color="brown"
              >mdi-book-open-page-variant</v-icon
            >
          </v-app-bar-nav-icon>
        </template>
        <span>Retour au sommaire</span>
      </v-tooltip>

      <logout @logout="logout"></logout>

      <div class="flex-grow-1"></div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon
            v-bind="attrs"
            v-on="on"
            @click="drawer = !drawer"
          ></v-app-bar-nav-icon>
        </template>
        <span>Afficher les sous-sections</span>
      </v-tooltip>
    </v-app-bar>

    <v-main class="kindle">
      <div v-if="this.section">
        <v-container v-if="this.section.subsections" class="my-container">
          <v-row
            v-for="(subsection, subsection_index) in this.section.subsections"
            :key="subsection.infos.title"
            class="row_subsection"
          >
            <div class="flex-container-subsection">
              <div class="flex-item">
                <strong>{{ subsection.infos.title }}</strong>
                <br />
                {{ subsection.infos.settings.problematique }}
              </div>
              <div class="flex-item">{{ subsection.infos.settings.name }}</div>
            </div>

            <v-col
              class="list-group-item element"
              element
              v-for="(bloc, bloc_index) in subsection.blocs"
              :key="bloc.id"
              :sm="bloc.settings.cols"
              :class="BlocIsVisible(subsection, bloc)"
            >
              <v-card>
                <v-app-bar dense dark class="app_bar">
                  <v-btn icon>
                    <v-icon
                      v-on:click="viewFullScreen(subsection, bloc)"
                      class="handle"
                      >mdi-fullscreen</v-icon
                    >
                  </v-btn>
                  <v-chip v-if="bloc.id" small class="ml-2 mr-4" color="primary"
                    >ID#{{ bloc.id }}</v-chip
                  >
                  <v-text-field
                    class="ml-2 mr-2"
                    dark
                    dense
                    v-if="bloc.id"
                    readonly
                    :value="
                      getBlocLink(section.section.id, subsection_index, bloc.id)
                    "
                  ></v-text-field>

                  <span>{{ longBlocDescription(bloc) }}</span>
                  <div class="flex-grow-1"></div>

                  <v-btn class="d-none d-sm-flex" icon>
                    <v-icon
                      v-on:click="expand(subsection_index, bloc_index)"
                      :disabled="bloc.settings.cols === 12"
                      class="handle"
                      >mdi-arrow-expand-horizontal</v-icon
                    >
                  </v-btn>

                  <v-btn class="d-none d-sm-flex" icon>
                    <v-icon
                      v-on:click="collapse(subsection_index, bloc_index)"
                      :disabled="bloc.settings.cols === 3"
                      class="handle"
                      >mdi-arrow-collapse-horizontal</v-icon
                    >
                  </v-btn>

                  <v-btn icon>
                    <v-icon
                      v-on:click="
                        showSpecificBlocModal(
                          bloc.kind,
                          subsection_index,
                          bloc_index
                        )
                      "
                      class="handle"
                      >mdi-square-edit-outline</v-icon
                    >
                  </v-btn>

                  <!--
                  <v-badge
                    :color="countTagsColor(bloc.settings.tags)"
                    :content="countTags(bloc.settings.tags)"
                    overlap
                    offset-y="24"
                    offset-x="24"
                    class="sm-none"
                  >
                    <v-btn icon>
                      <v-icon>mdi-tag-outline</v-icon>
                    </v-btn>
                  </v-badge>
                  -->
                  <!--
                  <v-badge
                    :color="countItemsColor(bloc.settings.capacities)"
                    :content="countItems(bloc.settings.capacities)"
                    overlap
                    offset-y="24"
                    offset-x="24"
                    class="sm-none"
                  >
                    <v-btn icon>
                      <v-icon>mdi-tag-text-outline</v-icon>
                    </v-btn>
                  </v-badge>
                  -->

                  <v-badge
                    color="error"
                    content="-"
                    overlap
                    offset-y="24"
                    offset-x="20"
                    class="d-none sm-none"
                  >
                    <v-btn icon>
                      <v-icon>mdi-chat</v-icon>
                    </v-btn>
                  </v-badge>

                  <v-btn icon>
                    <v-icon
                      v-on:click="askForDuplicate(subsection_index, bloc_index)"
                      class="handle"
                      >mdi-content-duplicate</v-icon
                    >
                  </v-btn>

                  <v-btn icon>
                    <v-icon
                      v-on:click="askForRemove(subsection_index, bloc_index)"
                      class="handle"
                      >mdi-delete-outline</v-icon
                    >
                  </v-btn>
                </v-app-bar>

                <bloc-generique v-if="bloc" v-bind:bloc="bloc"></bloc-generique>

                <div
                  v-if="
                    bloc.settings.other_attached !== null &&
                      bloc.settings.other_attached !== undefined
                  "
                >
                  <v-divider></v-divider>
                  <bloc-generique
                    v-bind:showEditButton="true"
                    v-bind:bloc="
                      getAttachedBlocUuid(
                        subsection,
                        bloc.settings.other_attached
                      )
                    "
                  ></bloc-generique>
                </div>

                <div
                  v-if="
                    bloc.settings.attached !== null &&
                      bloc.settings.attached !== undefined
                  "
                >
                  <v-divider></v-divider>
                  <bloc-generique
                    v-bind:showEditButton="true"
                    v-bind:bloc="
                      getAttachedBlocUuid(subsection, bloc.settings.attached)
                    "
                  ></bloc-generique>
                </div>

                <div v-if="bloc.content.level_1.image" class="text-center">
                  <img
                    class="image"
                    :src="bloc.content.level_1.image.src"
                    :alt="bloc.content.level_1.image.legend"
                  />
                </div>
                <v-footer class="legend" v-if="bloc.content.level_1.image">{{
                  bloc.content.level_1.image.legend
                }}</v-footer>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- DEBUT MODALE DOWNLOAD -->
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="dialogs.download"
        max-width="290"
        content-class="danger"
      >
        <v-card>
          <v-card-title class="text-h5">Attention !</v-card-title>
          <v-card-text>
            Vous allez télécharger la dernière version en ligne de cette section
            de chapitre.
            <strong>
              Toute les modifications stockées sur cet appareil et non
              enregistrées en ligne seront effacées </strong
            >. Confirmez-vous votre choix ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="white" text @click="dialogs.download = false"
              >Annuler</v-btn
            >
            <v-btn color="white" text @click="downloadSection()"
              >Confirmer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar_download" :timeout="2000">
        Le dernière version de cette section de chapitre sur le serveur a été
        récupérée.
        <v-btn color="green" text @click="snackbar_download = false">OK</v-btn>
      </v-snackbar>

      <!-- FIN MODALE DOWNLOAD -->

      <!-- DEBUT MODALE UPLOAD -->
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="dialogs.upload"
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">Enregistrer</v-card-title>

          <v-card-text>
            Vous allez envoyer en ligne les dernières modifications stockées
            <strong>sur cet appareil</strong>. La version de cette section de
            chapitre sera écrasée sur le serveur.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="red darken-1" text @click="dialogs.upload = false"
              >Annuler</v-btn
            >

            <v-btn color="green darken-1" text @click="uploadSection()"
              >Enregistrer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar_upload" :timeout="2000">
        Cette section de chapitre a été enregistrée sur le serveur
        <v-btn color="green" text @click="snackbar_upload = false">OK</v-btn>
      </v-snackbar>
      <!-- FIN MODALE UPLOAD -->

      <!-- DEBUT MODALE SUPPRESSION DE BLOC -->
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="dialogs.remove_bloc"
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">Supprimer le bloc</v-card-title>
          <v-card-text>
            <strong>&laquo;{{ blocTitle }}&raquo;</strong>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="dialogs.remove_bloc = false"
              >Annuler</v-btn
            >
            <v-btn color="green darken-1" text @click="removeBloc()"
              >Supprimer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- FIN MODALE SUPPRESSION DE BLOC -->

      <!-- DEBUT MODALE DUPLIQUER UN BLOC -->
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="dialogs.duplicate_bloc"
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">Dupliquer le bloc</v-card-title>
          <v-card-text>
            <strong>&laquo;{{ blocTitle }}&raquo;</strong>
            <br />
            <br />
            <em>Le nouveau bloc sera ajouté en fin de sous-section.</em>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="dialogs.duplicate_bloc = false"
              >Annuler</v-btn
            >
            <v-btn color="green darken-1" text @click="duplicateBloc()"
              >Dupliquer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- FIN MODALE  DUPLIQUER UN BLOC -->

      <!-- DEBUT MODALE SUPPRESSION DE SECTION -->
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="dialogs.remove_section"
        max-width="350"
      >
        <v-card>
          <v-card-title class="text-h5">Supprimer la section</v-card-title>
          <v-card-text>
            <strong>
              Cette section et la totalité des sous-sections et des documents
              inclus seront définitivement supprimés </strong
            >.
            <br />
            <br />Confirmez-vous la suppression de cete section&nbsp;?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="dialogs.remove_section = false"
              >Annuler</v-btn
            >
            <v-btn color="green darken-1" text @click="removeSection()"
              >Supprimer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- FIN MODALE SUPPRESSION DE SECTION -->

      <!-- DEBUT MODALE SUPPRESSION DE SOUS-SECTION -->
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="dialogs.remove_subsection"
        max-width="350"
      >
        <v-card>
          <v-card-title class="text-h5">Supprimer la sous-section</v-card-title>
          <v-card-text>
            <strong>
              Cette sous-section et ses documents inclus seront définitivement
              supprimés </strong
            >.
            <br />
            <br />Confirmez-vous la suppression de cete sous-section ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="dialogs.remove_subsection = false"
              >Annuler</v-btn
            >
            <v-btn color="green darken-1" text @click="removeSubsection()"
              >Supprimer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- FIN MODALE SUPPRESSION DE SOUS-SECTION -->

      <!-- DEBUT MODALE EDITION D'IMAGE  -->
      <image-editor
        v-if="dialogs.edit_image"
        v-bind:show_hide="dialogs.edit_image"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
        @showmedias="showMediaLibrary"
        @setSelectedMediaItem="setSelectedMediaItem"
      ></image-editor>
      <!-- FIN MODALE EDITION DE BLOC IMAGE  -->

      <!-- DEBUT MODALE EDITION D'IMAGE + ETIQUETTE -->
      <caption-image-editor
        v-if="dialogs.edit_caption_image"
        v-bind:show_hide="dialogs.edit_caption_image"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
        @showmedias="showMediaLibrary"
        @setSelectedMediaItem="setSelectedMediaItem"
      ></caption-image-editor>
      <!-- FIN MODALE EDITION DE BLOC IMAGE + ETIQUETTE  -->

      <!-- DEBUT MODALE GENIALLY -->
      <genially-editor
        v-bind:show_hide="dialogs.edit_genially"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></genially-editor>
      <!-- FIN MODALE GENIALLY  -->

      <!-- DEBUT MODALE TIMELINE -->
      <timeline-editor
        v-bind:show_hide="dialogs.edit_timeline"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></timeline-editor>
      <!-- FIN MODALE TIMELINE  -->

      <!-- DEBUT MODALE DIAGRAM -->
      <diagram-editor
        v-bind:show_hide="dialogs.edit_diagram"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></diagram-editor>
      <!-- FIN MODALE DIAGRAM  -->

      <!-- DEBUT MODALE RAW HTML -->
      <rawhtml-editor
        v-bind:show_hide="dialogs.edit_raw_html"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></rawhtml-editor>
      <!-- FIN MODALE RAW HTML  -->

      <!-- DEBUT MODALE SVG MAP -->
      <svgmap-editor
        v-bind:show_hide="dialogs.edit_svg_map"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></svgmap-editor>
      <!-- FIN MODALE SVG MAP  -->

      <!-- DEBUT MODALE EDITION DE VIDEOS  -->
      <video-editor
        v-bind:show_hide="dialogs.edit_video"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></video-editor>
      <!-- FIN MODALE EDITION DE BLOC VIDEO  -->

      <!-- DEBUT MODALE EDITION AUDIO  -->
      <audio-editor
        v-bind:show_hide="dialogs.edit_audio"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></audio-editor>
      <!-- FIN MODALE EDITION DE BLOC AUDIO  -->

      <!-- DEBUT MODALE EDITION TITRE  -->
      <title-editor
        v-bind:show_hide="dialogs.edit_title"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></title-editor>
      <!-- FIN MODALE EDITION DE BLOC TITRE  -->

      <!-- DEBUT MODALE EDITION DE BLOC HTML  -->
      <html-editor
        v-bind:show_hide="dialogs.edit_html"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
        @showmedias="showMediaLibrary"
        @setSelectedMediaItem="setSelectedMediaItem"
      ></html-editor>
      <!-- FIN MODALE EDITION DE BLOC HTML  -->

      <!-- DEBUT QUESTION LIBRE -->
      <free-question
        v-bind:show_hide="dialogs.free_question"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></free-question>
      <!-- FIN QUESTION LIBRE -->

      <!-- DEBUT TRACE ECRITE-->
      <written-trace
        v-bind:show_hide="dialogs.written_trace"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></written-trace>
      <!-- FIN TRACE ECRITE -->

      <!-- DEBUT VRAI/FAUX-->
      <true-false
        v-bind:show_hide="dialogs.true_false"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></true-false>
      <!-- FIN VRAI/FAUX -->

      <!-- DEBUT drag-and-drop -->
      <drag-and-drop
        v-bind:show_hide="dialogs.drag_and_drop"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></drag-and-drop>
      <!-- FIN drag-and-drop -->

      <!-- DEBUT association-links -->
      <associations-links
        v-bind:show_hide="dialogs.associations_links"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></associations-links>
      <!-- FIN association-links -->

      <!-- DEBUT QCM -->
      <qcm
        v-bind:show_hide="dialogs.qcm"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></qcm>
      <!-- FIN QCM -->

      <!-- DEBUT TEXTE A TROUS -->
      <text-with-gaps
        v-bind:show_hide="dialogs.text_with_gaps"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></text-with-gaps>
      <!-- FIN TEXTE A TROUS -->

      <!-- DEBUT QUESTION SANS REPONSE DEMANDEE -->
      <no-answer-needed
        v-bind:show_hide="dialogs.no_answer_needed"
        v-bind:bloc="bloc_template"
        @showmedias="showMediaLibrary"
        @setSelectedMediaItem="setSelectedMediaItem"
        @close="closeEditor"
        @save="saveEditor"
      ></no-answer-needed>
      <!-- FIN QUESTION SANS REPONSE DEMANDEE -->

      <!-- DEBUT TIMELINEDROP -->
      <timeline-drop
        v-bind:show_hide="dialogs.timeline_drop"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></timeline-drop>
      <!-- FIN TIMELINEDROP -->

      <!-- DEBUT CAPTIONDROP -->
      <caption-drop
        v-if="dialogs.caption_drop"
        v-bind:show_hide="dialogs.caption_drop"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
        @showmedias="showMediaLibrary"
        @setSelectedMediaItem="setSelectedMediaItem"
      ></caption-drop>
      <!-- FIN CAPTIONDROP -->

      <!-- DEBUT TEXTTOORDER-->
      <text-to-order
        v-bind:show_hide="dialogs.text_to_order"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></text-to-order>
      <!-- FIN TEXTTOORDER -->

      <!-- DEBUT TABLEDROP -->
      <table-drop
        v-bind:show_hide="dialogs.table_drop"
        v-bind:bloc="bloc_template"
        @close="closeEditor"
        @save="saveEditor"
      ></table-drop>
      <!-- FIN TABLEDROP -->

      <!-- DEBUT POPUP SUBSECTION DIALOG -->
      <dialog-add-subsection
        :subsection_dialog="subsection_dialog"
        :subsection_template="subsection_template"
        @cancel-subsection-dialog="hideSubsectionModal"
        @save-subsection-dialog="saveSubsection"
      ></dialog-add-subsection>
      <!-- FIN POPUP SUBSECTION DIALOG -->

      <!-- DEBUT POPUP FULL SCREEN -->
      <full-screen
        v-bind:show_hide="dialogs.full_screen"
        v-bind:bloc="selected_bloc"
        v-bind:subsection="selected_subsection"
        @close="closeFullScreen"
      ></full-screen>
      <!-- FIN POPUP FULL SCREEN -->

      <!-- DEBUT POPUP SECTION DIALOG -->
      <dialog-add-section
        v-if="section_dialog_chapter"
        :section_dialog="section_dialog"
        :section_dialog_chapter="section_dialog_chapter"
        v-bind:problematique="
          this.$store.state.current_section.section.content.problematique
        "
        v-bind:title="this.$store.state.current_section.section.content.title"
        v-bind:name="this.$store.state.current_section.section.name"
        v-bind:image="this.$store.state.current_section.section.image"
        @showmedias="showMediaLibrary"
        @setSelectedMediaItem="setSelectedMediaItem"
        @cancel-section-dialog="cancel_section_dialog"
        @save-section-dialog="save_section_dialog"
      ></dialog-add-section>
      <!-- FIN POPUP SECTION DIALOG -->

      <!-- MODALE ALERTE CONTENU -->
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="dialogs.alert_content"
        max-width="290"
        content-class="danger"
      >
        <v-card>
          <v-card-title class="text-h5">Attention !</v-card-title>
          <v-card-text>
            Pour ne pas supprimer les modifications faites par un tiers,
            <strong>cliquez sur "Charger le contenu en ligne"</strong> pour
            récupérer le contenu en ligne avant de saisir vos propres
            modifications.
            <br />
            <br />
            <em>
              Toute les modifications stockées sur cet appareil et non
              enregistrées en ligne seront effacées.
            </em>
          </v-card-text>
          <v-card-actions>
            <div class="text-center">
              <v-btn
                class="mb-4"
                small
                text
                @click="dialogs.alert_content = false"
                >Annuler</v-btn
              >
              <v-btn small class="mb-2" color="white" @click="downloadSection()"
                >Charger le contenu en ligne</v-btn
              >
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- FIN MODALE ALERTE CONTENU -->

      <bottom-sheet-menu
        :sheet="dialogs.sheet"
        @insert-html="insertHtml()"
        @insert-video="insertVideo()"
        @insert-audio="insertAudio()"
        @insert-title="insertTitle()"
        @insert-image="insertImage()"
        @insert-caption-image="insertCaptionImage()"
        @insert-genially="insertGenially()"
        @insert-timeline="insertTimeline()"
        @insert-diagram="insertDiagram()"
        @insert-rawhtml="insertRawHtml()"
        @insert-svgmap="insertSvgMap()"
        @insert-free-question="insertFreeQuestion()"
        @insert-text-with-gaps="insertTextWithGaps()"
        @insert-no-answer-needed="insertNoAnswerNeeded()"
        @insert-written-trace="insertWrittenTrace()"
        @insert-true-false="insertTrueFalse()"
        @insert-table-drop="insertTableDrop()"
        @insert-caption-image-drop="insertCaptionImageDrop()"
        @insert-timeline-drop="insertTimelineDrop()"
        @insert-text-to-order="insertTextToOrder()"
        @insert-qcm="insertQcm()"
        @insert-drag-and-drop="insertDragAndDrop()"
        @insert-associations-links="insertAssociationsLinks()"
        @close-bottom-sheet="closeBottomSheet"
      ></bottom-sheet-menu>

      <!-- DEBUT MODALE BIBLIOTHEQUE DE MEDIAS  -->
      <medias-library
        v-bind:show_hide="dialogs.medias"
        @close="closeMediasLibrary"
        @setSelectedMediaValue="setSelectedMediaValue"
        @showmedias="showMediaLibrary"
        :medias="medias"
      ></medias-library>
      <!-- FIN MODALE BIBLIOTHEQUE DE MEDIAS  -->
    </v-main>
  </v-app>
</template>

<script>
import draggable from "vuedraggable";
import LeftAppBar from "../LeftAppBar/LeftAppBar.vue";
import UserSettingsModal from "../UserSettingsModal/UserSettingsModal.vue";

import HtmlEditor from "../Editors/HtmlEditor/HtmlEditor.vue";
import VideoEditor from "../Editors/VideoEditor/VideoEditor.vue";
import AudioEditor from "../Editors/AudioEditor/AudioEditor.vue";
import TitleEditor from "../Editors/TitleEditor/TitleEditor.vue";

import FullScreenBlocView from "../FullScreenBlocView/FullScreenBlocView.vue";
import ImageEditor from "../Editors/ImageEditor/ImageEditor.vue";
import CaptionImageEditor from "../Editors/CaptionImageEditor/CaptionImageEditor.vue";

import GeniallyEditor from "../Editors/GeniallyEditor/GeniallyEditor.vue";

import TimelineEditor from "../Editors/TimelineEditor/TimelineEditor.vue";
import DiagramEditor from "../Editors/DiagramEditor/DiagramEditor.vue";
import RawHtmlEditor from "../Editors/RawHtmlEditor/RawHtmlEditor.vue";
import SvgMapEditor from "../Editors/SvgMapEditor/SvgMapEditor.vue";

import BottomSheetMenu from "../BottomSheetMenu/BottomSheetMenu.vue";
import Logout from "../Logout/Logout.vue";

import FreeQuestion from "../Exercises/FreeQuestion/FreeQuestion.vue";
import WrittenTrace from "../Exercises/WrittenTrace/WrittenTrace.vue";
import TrueFalse from "../Exercises/TrueFalse/TrueFalse.vue";

import DragAndDrop from "../Exercises/DragAndDrop/DragAndDrop.vue";
import AssociationsLinks from "../Exercises/AssociationsLinks/AssociationsLinks.vue";
import Qcm from "../Exercises/Qcm/Qcm.vue";
import TextWithGaps from "../Exercises/TextWithGaps/TextWithGaps.vue";
import NoAnswerNeeded from "../Exercises/NoAnwserNeeded/NoAnwserNeeded.vue";

import TimelineDrop from "../Exercises/TimelineDrop/TimelineDrop.vue";
import CaptionDrop from "../Exercises/CaptionDrop/CaptionDrop.vue";
import TextToOrder from "../Exercises/TextToOrder/TextToOrder.vue";
import TableDrop from "../Exercises/TableDrop/TableDrop.vue";

import DialogAddSubSection from "../DialogAddSubSection/DialogAddSubSection.vue";
import DialogAddSection from "../DialogAddSection/DialogAddSection.vue";

import BlocGeneric from "../BlocGeneric/BlocGeneric.vue";
import MediasLibrary from "../Editors/mediasLibrary/mediasLibrary.vue";
import BlocLevels from "../Shared/BlocLevels.vue";

import moment from "moment";
import md5 from "md5";

export default {
  name: "App",
  components: {
    "bloc-levels": BlocLevels,
    "bloc-generique": BlocGeneric,
    draggable,
    "html-editor": HtmlEditor,
    "video-editor": VideoEditor,
    "audio-editor": AudioEditor,
    "title-editor": TitleEditor,
    "image-editor": ImageEditor,
    "caption-image-editor": CaptionImageEditor,
    "genially-editor": GeniallyEditor,
    "timeline-editor": TimelineEditor,
    "diagram-editor": DiagramEditor,
    "rawhtml-editor": RawHtmlEditor,
    "svgmap-editor": SvgMapEditor,
    "left-app-bar": LeftAppBar,
    "user-settings": UserSettingsModal,
    "bottom-sheet-menu": BottomSheetMenu,
    "free-question": FreeQuestion,
    "written-trace": WrittenTrace,
    "true-false": TrueFalse,
    "text-with-gaps": TextWithGaps,
    "no-answer-needed": NoAnswerNeeded,
    "timeline-drop": TimelineDrop,
    "caption-drop": CaptionDrop,
    "text-to-order": TextToOrder,
    "table-drop": TableDrop,
    qcm: Qcm,
    "drag-and-drop": DragAndDrop,
    "associations-links": AssociationsLinks,
    "dialog-add-subsection": DialogAddSubSection,
    "dialog-add-section": DialogAddSection,
    "full-screen": FullScreenBlocView,
    "medias-library": MediasLibrary,
    Logout: Logout,
    BottomSheetMenu,
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.doSave);
  },
  mounted() {
    console.clear();
    document.addEventListener("keydown", this.doSave);
    this.checkAuthentification();
    this.section_id = this.$cookie.get("section_id");
    var storage_name = "STORED_SECTION_" + this.section_id;
    if (localStorage.getItem(storage_name)) {
      try {
        this.section = JSON.parse(localStorage.getItem(storage_name));
        //this.getSectionLastUpdate();
        this.overlay = false;
      } catch (e) {
        this.downloadSection();
      }
    } else {
      this.downloadSection();
    }
    this.dialogs.alert_content = true;
  },
  data() {
    return {
      section_last_update: null,
      exercicesList: [
        "FREEQUESTION",
        "WRITTENTRACE",
        "QCM",
        "TEXTWITHGAPS",
        "ASSOCIATIONS",
        "TRUEFALSE",
        "NOANSWERNEEDED",
      ],
      otherList: [
        "bloc-biographie",
        "bloc-aller-plus-loin",
        "bloc-vocabulaire",
        "bloc-notions",
        "bloc-attachable",
      ],
      initialMd5content: null,
      uploadReminder: false,
      long_descriptions: this.$store.getters.blocsDescription,
      /*cloned_bloc: null,*/
      exercices_list: [],
      other_list: [],
      subsection_template: this.$store.getters.subsectionTemplate,
      bloc_template: this.$store.getters.blocTemplate,
      section_dialog: false,
      section_dialog_chapter: false,
      subsection_to_remove: null,
      subsection_dialog: false,
      currentSubsection: null,
      currentSubsectionIndex: null,
      section: null,
      section_id: null,
      system_bar_message: null,
      use_student_view: false,
      use_dyslexic_font: false,
      use_bigger_font: false,
      modal_user_settings: false,
      overlay: true,
      drawer: null,
      enabled: true,
      dragging: false,
      snackbar_download: false,
      snackbar_upload: false,
      selected_bloc: null,
      selected_subsection: null,
      blocTitle: null,
      message: null,
      medias: null,
      medias_selected_item: null,
      dialogs: {
        alert_content: false,
        associations_links: false,
        download: false,
        drag_and_drop: false,
        edit_audio: false,
        edit_title: false,
        edit_caption_image: false,
        edit_diagram: false,
        edit_genially: false,
        edit_html: false,
        edit_image: false,
        edit_raw_html: false,
        edit_svg_map: false,
        edit_timeline: false,
        edit_video: false,
        free_question: false,
        full_screen: false,
        medias: false,
        no_answer_needed: false,
        timeline_drop: false,
        caption_drop: false,
        text_to_order: false,
        table_drop: false,
        qcm: false,
        remove_bloc: false,
        duplicate_bloc: false,
        remove_section: false,
        remove_subsection: false,
        sheet: false,
        text_with_gaps: false,
        true_false: false,
        upload: false,
        written_trace: false,
      },
    };
  },
  watch: {
    section: {
      immediate: true,
      deep: true,
      handler() {
        this.updateToLocalStorage();
      },
    },
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
  },
  methods: {
    getBlocLink(section_id, subsection_index, bloc_id) {
      let url =
        this.$store.getters.baseUrlManuel +
        "#/lien/" +
        section_id +
        "/" +
        subsection_index;
      if (bloc_id != null) {
        url += "/" + bloc_id;
      }
      return url;
    },
    setSelectedMediaItem(field) {
      this.medias_selected_item = field;
    },
    setSelectedMediaValue(media) {
      const name = media.name;

      if (
        this.medias_selected_item === undefined ||
        this.medias_selected_item == null
      ) {
        this.medias_selected_item = {};
      }

      this.medias_selected_item.filename = media.originalName;

      let url = this.$store.getters.baseMedias + 'images/' + name;
      if (media.category === 'IMAGE' && this.medias_selected_item.image_settings) {
        this.medias_selected_item.image_settings.ratio = media.settings.ratio;
      }

      if ( media.category === 'PDF') {
        url = this.$store.getters.baseMedias + 'pdf/' + name;
      }

      this.medias_selected_item.url = url;
      setTimeout(() => this.closeMediasLibrary(), 250);
    },
    doSave: function(e) {
      if (!(e.keyCode === 83 && (e.ctrlKey || e.metaKey))) {
        return;
      }
      e.preventDefault();
      this.askForUpload();
    },
    BlocIsVisible: function(subsection, bloc) {
      const hiddenBlocs = this.getHiddenBlocs(subsection);
      if (hiddenBlocs.includes(bloc.uuid) || bloc.removed) {
        return "hidden";
      }
      return "";
    },
    getHiddenBlocs: function(subsection) {
      let hiddenBlocs = [];
      for (let i = 0; i < subsection.blocs.length; i++) {
        if (
          subsection.blocs[i].settings.attached !== null &&
          subsection.blocs[i].settings.attached !== undefined
        ) {
          hiddenBlocs.push(subsection.blocs[i].settings.attached);
        }
        if (
          subsection.blocs[i].settings.other_attached !== null &&
          subsection.blocs[i].settings.other_attached !== undefined
        ) {
          hiddenBlocs.push(subsection.blocs[i].settings.other_attached);
        }
      }
      return hiddenBlocs;
    },

    getAttachedBlocUuid: function(subsection, attached_bloc_uuid) {
      for (let i = 0; i < subsection.blocs.length; i++) {
        if (subsection.blocs[i].uuid == attached_bloc_uuid) {
          return subsection.blocs[i];
        }
      }
    },

    truncateOnWord: function(str, limit) {
      var trimmable =
        "\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF";
      var reg = new RegExp("(?=[" + trimmable + "])");
      var words = str.split(reg);
      var count = 0;
      return words
        .filter(function(word) {
          count += word.length;
          return count <= limit;
        })
        .join("");
    },

    getBlocName: function(bloc) {
      const kind = bloc.kind;
      let description = "";
      switch (kind) {
        case "TITLE":
        case "IMAGE":
        case "CAPTION":
          if (bloc.name !== undefined) {
            description = bloc.name;
          }
          break;
        case "TIMELINE":
          if (bloc.content.level_1.items[0] !== undefined) {
            description =
              bloc.content.level_1.items[0].date +
              " : " +
              bloc.content.level_1.items[0].event;
          }
          break;
        case "HTML":
          console.log(bloc);
          if (
            bloc.name !== undefined &&
            bloc.name !== null &&
            bloc.name.replace(/(<([^>]+)>)/gi, "") != ""
          ) {
            description = bloc.name;
          } else if (bloc.content.level_1.html !== undefined) {
            description = bloc.content.level_1.html;
          }
          break;
        case "FREEQUESTION":
        case "QCM":
        case "TRUEFALSE":
        case "WRITTENTRACE":
          if (
            bloc.content.level_1 !== undefined &&
            bloc.content.level_1[0] !== undefined &&
            bloc.content.level_1[0].question !== undefined &&
            bloc.content.level_1[0].question != "" &&
            bloc.content.level_1[0].question != null
          ) {
            description = bloc.content.level_1[0].question;
          } else if (
            bloc.content.level_2 !== undefined &&
            bloc.content.level_2[0] !== undefined &&
            bloc.content.level_2[0].question !== undefined &&
            bloc.content.level_2[0].question != "" &&
            bloc.content.level_2[0].question != null
          ) {
            description = bloc.content.level_2[0].question;
          } else if (
            bloc.content.level_3 !== undefined &&
            bloc.content.level_3[0] !== undefined &&
            bloc.content.level_3[0].question !== undefined &&
            bloc.content.level_3[0].question != "" &&
            bloc.content.level_3[0].question != null
          ) {
            description = bloc.content.level_3[0].question;
          }
          break;
        case "TEXTWITHGAPS":
        case "DRAGANDDROP":
        case "ASSOCIATIONSLINKS":
        case "CAPTIONDROP":
        case "NOANSWERNEEDED":
        case "TEXTTOORDER":
        default:
          if (
            bloc.content.level_1.html !== undefined &&
            bloc.content.level_1.html != ""
          ) {
            description = bloc.content.level_1.html;
          } else if (bloc.settings.infos.level_1.consigne !== undefined) {
            description = bloc.settings.infos.level_1.consigne;
          } else if (
            bloc.content.level_2.html !== undefined &&
            bloc.content.level_2.html != ""
          ) {
            description = bloc.content.level_2.html;
          } else if (bloc.settings.infos.level_2.consigne !== undefined) {
            description = bloc.settings.infos.level_2.consigne;
          }
          break;
      }
      if (description !== null) {
        description = description.replace(/(<([^>]+)>)/gi, "");
        description = description.replace(/&nbsp;/g, " ");
        description = this.truncateOnWord(description, 75);
      }
      return description !== null ? this.$he.decode(description) : "";
    },

    longBlocDescription: function(bloc) {
      const kind = bloc.kind;
      let long_description =
        this.long_descriptions[kind] !== undefined
          ? this.long_descriptions[kind]
          : kind;
      if (bloc.settings.classes !== null) {
        long_description +=
          " - " + bloc.settings.classes.replace(/bloc-/g, " ").toUpperCase();
      }
      return long_description;
    },

    countItems: function(array) {
      return array !== undefined && array !== null ? array.length : "0";
    },

    countTags: function(tags) {
      let counter = 0;
      if (tags === undefined || tags === null) {
        return counter;
      } else if (tags.notions === undefined || tags.notions === null) {
        return counter;
      } else {
        counter +=
          tags.notions !== "undefined" && tags.notions !== null
            ? tags.notions.words.length
            : 0;
        counter +=
          tags.vocabulaire !== "undefined" && tags.vocabulaire !== null
            ? tags.vocabulaire.words.length
            : 0;
        counter +=
          tags.dates !== "undefined" && tags.dates !== null
            ? tags.dates.words.length
            : 0;
        counter +=
          tags.acteurs !== "undefined" && tags.acteurs !== null
            ? tags.acteurs.words.length
            : 0;
        counter +=
          tags.lieux !== "undefined" && tags.lieux !== null
            ? tags.lieux.words.length
            : 0;
        return counter;
      }
    },
    countItemsColor: function(array) {
      const total = array !== undefined && array !== null ? array.length : 0;
      return total != 0 ? "green" : "red";
    },
    countTagsColor: function(tags) {
      return this.countTags(tags) != 0 ? "green" : "red";
    },
    updateToLocalStorage: function() {
      this.$store.commit("SET_CURRENT_SECTION", this.section);
      if (this.section_id !== null && this.section_id !== undefined) {
        var storage_name = "STORED_SECTION_" + this.section_id;
        var storage_content = JSON.stringify(this.section);
        var md5_content = md5(storage_content);
        if (this.initialMd5content === null) {
          this.initialMd5content = md5_content;
        }
        this.uploadReminder =
          this.initialMd5content != md5_content ? true : false;
        console.log("MD5 : " + this.initialMd5content + " : " + md5_content);
        localStorage.setItem(storage_name, storage_content);
      }
    },

    getExercicesList: function(blocs) {
      let blocsTextAndValue = [{ text: "Aucun", value: null }];
      let text;
      for (let i = 0; i < blocs.length; i++) {
        text = "";
        switch (blocs[i].kind) {
          case "FREEQUESTION":
          case "QCM":
            if (
              blocs[i].content.level_1 !== undefined &&
              blocs[i].content.level_1[0] !== undefined
            ) {
              text = blocs[i].content.level_1[0].question;
            } else if (
              blocs[i].content.level_2 !== undefined &&
              blocs[i].content.level_2[0] !== undefined
            ) {
              text = blocs[i].content.level_2[0].question;
            } else if (
              blocs[i].content.level_3 !== undefined &&
              blocs[i].content.level_3[0] !== undefined
            ) {
              text = blocs[i].content.level_3[0].question;
            }
            break;
          case "ASSOCIATIONS":
            text = "ASSOCIATIONS";
            break;
          default:
            text = blocs[i].kind;
        }
        if (this.exercicesList.includes(blocs[i].kind) && text != "") {
          blocsTextAndValue.push({
            text:
              text.substring(0, 120) +
              " (" +
              this.longBlocDescription(blocs[i]) +
              ")",
            value: blocs[i].uuid,
          });
        }
      }
      return blocsTextAndValue;
    },

    getOtherList: function(blocs) {
      let blocsTextAndValue = [{ text: "Aucun", value: null }];
      for (let i = 0; i < blocs.length; i++) {
        if (this.otherList.includes(blocs[i].settings.classes)) {
          console.log(blocs[i].settings.classes);
          blocsTextAndValue.push({
            text: this.getBlocName(blocs[i]),
            value: blocs[i].uuid,
          });
        }
      }
      return blocsTextAndValue;
    },

    /* GESTION DES BLOCS : INSERTION */

    insertHtml: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "HTML";
      this.dialogs.edit_html = true;
    },

    insertTableDrop: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "TABLEDROP";
      this.dialogs.table_drop = true;
    },

    insertVideo: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "VIDEO";
      this.dialogs.edit_video = true;
    },

    insertAudio: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "AUDIO";
      this.dialogs.edit_audio = true;
    },

    insertTitle: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "TITLE";
      this.dialogs.edit_title = true;
    },

    insertImage: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.content = {
        level_1: { items: [], url: null, image_settings: { ratio: 1 } },
        level_2: { items: [], url: null, image_settings: { ratio: 1 } },
        level_3: { items: [], url: null, image_settings: { ratio: 1 } },
      };
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "IMAGE";
      this.dialogs.edit_image = true;
    },

    insertCaptionImage: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.content = {
        level_1: { items: [], url: null, image_settings: { ratio: 1 } },
        level_2: { items: [], url: null, image_settings: { ratio: 1 } },
        level_3: { items: [], url: null, image_settings: { ratio: 1 } },
      };
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "CAPTION";
      this.dialogs.edit_caption_image = true;
    },

    insertCaptionImageDrop: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.content = {
        level_1: { items: [], url: null, image_settings: { ratio: 1 } },
        level_2: { items: [], url: null, image_settings: { ratio: 1 } },
        level_3: { items: [], url: null, image_settings: { ratio: 1 } },
      };
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "CAPTIONDROP";
      this.dialogs.caption_drop = true;
    },

    insertGenially: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "GENIALLY";
      this.dialogs.edit_genially = true;
    },

    insertTimeline: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.content = {
        level_1: { kind: "vertical", items: [{ date: "", event: "" }] },
        level_2: { kind: "vertical", items: [{ date: "", event: "" }] },
        level_3: { kind: "vertical", items: [{ date: "", event: "" }] },
      };
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "TIMELINE";
      this.dialogs.edit_timeline = true;
    },

    insertTimelineDrop: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.content = {
        level_1: { kind: "vertical", items: [{ date: "", event: "" }] },
        level_2: { kind: "vertical", items: [{ date: "", event: "" }] },
        level_3: { kind: "vertical", items: [{ date: "", event: "" }] },
      };
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "TIMELINEDROP";
      this.dialogs.timeline_drop = true;
    },

    insertDiagram: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.content = {
        level_1: { kind: "vertical", items: [{ class: "bleu", body: "" }] },
        level_2: { kind: "vertical", items: [{ class: "bleu", body: "" }] },
        level_3: { kind: "vertical", items: [{ class: "bleu", body: "" }] },
      };
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "DIAGRAM";
      this.dialogs.edit_diagram = true;
    },

    insertRawHtml: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "RAWHTML";
      this.dialogs.edit_raw_html = true;
    },

    insertSvgMap: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "SVGMAP";
      this.dialogs.edit_svg_map = true;
    },

    insertFreeQuestion: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.content = {
        level_1: [{ question: "", hint: "", mp3: null }],
        level_2: [{ question: "", hint: "", mp3: null }],
        level_3: [{ question: "", hint: "", mp3: null }],
      };
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "FREEQUESTION";
      this.dialogs.free_question = true;
    },

    insertWrittenTrace: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.content = {
        level_1: [{ question: "", hint: "" }],
        level_2: [{ question: "", hint: "" }],
        level_3: [{ question: "", hint: "" }],
      };
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "WRITTENTRACE";
      this.dialogs.written_trace = true;
    },

    insertTrueFalse: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.content = {
        level_1: [{ question: "" }],
        level_2: [{ question: "" }],
        level_3: [{ question: "" }],
      };
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "TRUEFALSE";
      this.dialogs.true_false = true;
    },

    insertDragAndDrop: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.content = {
        level_1: [{ question: "", answers: [{ answer: "", valid: null }] }],
        level_2: [{ question: "", answers: [{ answer: "", valid: null }] }],
        level_3: [{ question: "", answers: [{ answer: "", valid: null }] }],
      };
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "DRAGANDDROP";
      this.dialogs.drag_and_drop = true;
    },

    insertAssociationsLinks: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.content = {
        level_1: [{ question: "", answers: [{ answer: "", valid: null }] }],
        level_2: [{ question: "", answers: [{ answer: "", valid: null }] }],
        level_3: [{ question: "", answers: [{ answer: "", valid: null }] }],
      };
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "ASSOCIATIONSLINKS";
      this.dialogs.associations_links = true;
    },

    insertQcm: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.content = {
        level_1: [{ question: "", answers: [{ answer: "", valid: null }] }],
        level_2: [{ question: "", answers: [{ answer: "", valid: null }] }],
        level_3: [{ question: "", answers: [{ answer: "", valid: null }] }],
      };
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "QCM";
      this.dialogs.qcm = true;
    },

    insertTextWithGaps: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "TEXTWITHGAPS";
      this.dialogs.text_with_gaps = true;
    },

    insertTextToOrder: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "TEXTTOORDER";
      this.dialogs.text_to_order = true;
    },
    insertNoAnswerNeeded: function() {
      this.dialogs.sheet = false;
      this.bloc_template = JSON.parse(
        JSON.stringify(this.$store.getters.blocTemplate)
      );
      this.bloc_template.subsection_index = this.currentSubsectionIndex;
      this.bloc_template.bloc_index = null;
      this.bloc_template.kind = "NOANSWERNEEDED";
      this.dialogs.no_answer_needed = true;
    },

    /* GESTION DES BLOCS : MODIFICATION  */

    showHtmlBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      if (this.bloc_template.settings.tags === null)
        this.bloc_template.settings.tags = this.$store.getters.tags;
      this.dialogs.edit_html = true;
    },

    showVideoBlocModal: function(subsection_index, bloc_index) {
      this.bloc_template = JSON.parse(
        JSON.stringify(
          this.section.subsections[subsection_index].blocs[bloc_index]
        )
      );
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.edit_video = true;
    },

    showAudioBlocModal: function(subsection_index, bloc_index) {
      this.bloc_template = JSON.parse(
        JSON.stringify(
          this.section.subsections[subsection_index].blocs[bloc_index]
        )
      );
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.edit_audio = true;
    },

    showTitleBlocModal: function(subsection_index, bloc_index) {
      this.bloc_template = JSON.parse(
        JSON.stringify(
          this.section.subsections[subsection_index].blocs[bloc_index]
        )
      );
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { html: "" },
          level_2: { html: "" },
          level_3: { html: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.edit_title = true;
    },

    showImageBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.edit_image = true;
    },

    showCaptionImageBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.edit_caption_image = true;
    },

    showCaptionImageDropBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.caption_drop = true;
    },

    showGeniallyBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.edit_genially = true;
    },

    showTimelineBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.edit_timeline = true;
    },

    showTimelineDropBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.timeline_drop = true;
    },

    showDiagramBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.edit_diagram = true;
    },

    showRawHtmlBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.edit_raw_html = true;
    },

    showTableDropBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.table_drop = true;
    },

    showSvgMapBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.edit_svg_map = true;
    },

    showFreeQuestionBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.free_question = true;
    },

    showWrittenTraceBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.written_trace = true;
    },

    showTrueFalseBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.true_false = true;
    },

    showQcmBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.qcm = true;
    },

    showDragAndDropBlocModal: function(subsection_index, bloc_index) {
      // console.log("bloc");
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      // console.log(bloc)
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.drag_and_drop = true;
    },

    showAssociationsLinksBlocModal: function(subsection_index, bloc_index) {
      // console.log("bloc");
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      // console.log(bloc)
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.associations_links = true;
    },

    showTextWithGapsBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.text_with_gaps = true;
    },

    showTextToOrderBlocModal: function(subsection_index, bloc_index) {
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.text_to_order = true;
    },

    showNoAnswerNeededBlocModal: function(subsection_index, bloc_index) {
      console.log('inside showNoAnswerNeededBlocModal');
      const bloc = this.section.subsections[subsection_index].blocs[bloc_index];
      this.bloc_template = JSON.parse(JSON.stringify(bloc));
      if (typeof this.bloc_template.settings.infos == "undefined") {
        this.bloc_template.settings.infos = {
          level_1: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_2: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
          level_3: { consigne: "", corrige_eleve: "", corrige_professeur: "" },
        };
      }
      this.bloc_template.subsection_index = subsection_index;
      this.bloc_template.bloc_index = bloc_index;
      this.bloc_template.exercices_list = this.getExercicesList(
        this.section.subsections[subsection_index].blocs
      );
      this.bloc_template.other_list = this.getOtherList(
        this.section.subsections[subsection_index].blocs
      );
      this.dialogs.no_answer_needed = true;
    },

    showSpecificBlocModal: function(bloc_kind, subsection_index, bloc_index) {
      // console.log(bloc_kind);
      switch (bloc_kind) {
        case "TITLE":
          this.showTitleBlocModal(subsection_index, bloc_index);
          break;
        case "HTML":
          this.showHtmlBlocModal(subsection_index, bloc_index);
          break;
        case "VIDEO":
          this.showVideoBlocModal(subsection_index, bloc_index);
          break;
        case "AUDIO":
          this.showAudioBlocModal(subsection_index, bloc_index);
          break;
        case "IMAGE":
          this.showImageBlocModal(subsection_index, bloc_index);
          break;
        case "GENIALLY":
          this.showGeniallyBlocModal(subsection_index, bloc_index);
          break;
        case "TIMELINE":
          this.showTimelineBlocModal(subsection_index, bloc_index);
          break;
        case "DIAGRAM":
          this.showDiagramBlocModal(subsection_index, bloc_index);
          break;
        case "RAWHTML":
          this.showRawHtmlBlocModal(subsection_index, bloc_index);
          break;
        case "SVGMAP":
          this.showSvgMapBlocModal(subsection_index, bloc_index);
          break;
        case "CAPTION":
          this.showCaptionImageBlocModal(subsection_index, bloc_index);
          break;
        case "FREEQUESTION":
          this.showFreeQuestionBlocModal(subsection_index, bloc_index);
          break;
        case "WRITTENTRACE":
          this.showWrittenTraceBlocModal(subsection_index, bloc_index);
          break;
        case "DRAGANDDROP":
          this.showDragAndDropBlocModal(subsection_index, bloc_index);
          break;
        case "ASSOCIATIONSLINKS":
          this.showAssociationsLinksBlocModal(subsection_index, bloc_index);
          break;
        case "QCM":
          this.showQcmBlocModal(subsection_index, bloc_index);
          break;
        case "TEXTWITHGAPS":
          this.showTextWithGapsBlocModal(subsection_index, bloc_index);
          break;
        case "NOANSWERNEEDED":
          this.showNoAnswerNeededBlocModal(subsection_index, bloc_index);
          break;
        case "ASSOCIATIONS":
          this.showAssociationsBlocModal(subsection_index, bloc_index);
          break;
        case "TRUEFALSE":
          this.showTrueFalseBlocModal(subsection_index, bloc_index);
          break;
        case "TIMELINEDROP":
          this.showTimelineDropBlocModal(subsection_index, bloc_index);
          break;
        case "CAPTIONDROP":
          this.showCaptionImageDropBlocModal(subsection_index, bloc_index);
          break;
        case "TEXTTOORDER":
          this.showTextToOrderBlocModal(subsection_index, bloc_index);
          break;
        case "TABLEDROP":
          this.showTableDropBlocModal(subsection_index, bloc_index);
          break;
        default:
          alert("Type de contenu introuvable");
      }
    },

    /* GESTION DES SOUS-SECTIONS  */

    removeSubsection: function() {
      this.section.subsections[this.subsection_to_remove].infos.removed = true;
      this.dialogs.remove_subsection = false;
      this.updateToLocalStorage();
    },

    showSubsectionModal: function(action, subsection_index = null) {
      if (action == "ADD") {
        this.subsection_template = JSON.parse(
          JSON.stringify(this.$store.getters.subsectionTemplate)
        );
        this.subsection_template.uuid = this.$uuid.v4();
      } else {
        const subsection = this.section.subsections[subsection_index].infos;
        this.subsection_template = {
          title: subsection.title,
          name: subsection.settings.name,
          display_title: subsection.settings.display_title,
          visibility: subsection.settings.visibility,
          ppo: subsection.settings.ppo,
          problematique: subsection.settings.problematique,
          id: subsection.id,
          index: subsection_index,
          uuid: subsection.uuid,
        };
      }
      this.subsection_dialog = true;
    },

    hideSubsectionModal: function() {
      this.subsection_dialog = false;
    },

    /* GESTION DES SECTIONS  */

    saveSubsection: function(payload) {
      console.log("payload", payload);
      payload.section_id = this.section_id;
      console.log(payload);
      if (payload.index !== null) {
        this.section.subsections[payload.index].infos.title =
          payload.infos.title;
        this.section.subsections[payload.index].infos.settings =
          payload.infos.settings;
      } else {
        this.section.subsections.push(payload);
      }
      this.subsection_dialog = false;
    },

    open_section_dialog: function() {
      this.section_dialog_chapter = {
        infos: {
          title: this.section.chapter.title,
          label: this.section.chapter.label,
        },
      };
      this.section_dialog = true;
    },

    cancel_section_dialog: function() {
      this.section_dialog = false;
    },

    save_section_dialog: function(payload) {
      payload.id = this.section_id;

      this.section_dialog = false;
      this.$store
        .dispatch("updateSection", payload)
        .then(
          (response) => {
            console.log(response);
            this.section.section.name = payload.name;
            this.section.section.content.title = payload.title;
            this.section.section.content.problematique = payload.problematique;
          },
          (error) => {
            this.system_bar_message = error.message;
          }
        )
        .finally(() => {
          this.overlay = false;
        });
    },

    removeSection: function() {
      this.dialogs.remove_section = false;
      this.overlay = true;
      this.$store
        .dispatch("deleteSection", {
          section_id: this.section_id,
        })
        .then(
          (response) => {
            this.$store.commit("SET_CURRENT_SECTION", null);
            var storage_name = "STORED_SECTION_" + response.data.id;
            localStorage.removeItem(storage_name);
            this.$router.push("/sommaire");
          },
          (error) => {
            this.errored = true;
            this.system_bar_message = error.message;
          }
        )
        .finally(() => {
          this.overlay = false;
        });
    },

    getSectionLastUpdate: function() {
      console.log(">>>>>> getSectionLastUpdate");
      this.overlay = true;
      this.$store
        .dispatch("getSectionLastUpdate", {
          section_id: this.section_id,
        })
        .then(
          () => {
            this.section_last_update = this.$store.getters.sectionLastUpdate;
            console.log(this.section_last_update);
            let date_update = this.section_last_update.last_update;
            this.system_bar_message =
              "La dernière version en ligne de cette section est datée du " +
              moment(String(date_update)).format("DD/MM/YYYY à HH:mm") +
              '. Veuillez télécharger cette version en cliquant sur "réinitialiser la section" si votre version locale est plus ancienne.';
          },
          (error) => {
            this.errored = true;
            this.system_bar_message = error.message;
          }
        )
        .finally(() => {
          this.overlay = false;
        });
    },

    downloadSection: function(showSnackbar = true) {
      console.log(">>>>>> downloadSection");
      this.overlay = true;
      this.dialogs.alert_content = false;
      this.$store
        .dispatch("getSection", {
          section_id: this.section_id,
          user_uuid: this.$cookie.get("user_uuid"),
        })
        .then(
          () => {
            if (showSnackbar) this.snackbar_download = true;
            this.section = this.$store.getters.currentSection;
            this.initialMd5content = md5(JSON.stringify(this.section));
          },
          (error) => {
            this.errored = true;
            this.system_bar_message = error.message;
          }
        )
        .finally(() => {
          this.overlay = false;
          this.dialogs.download = false;
          console.log(">>> SECTION");
          console.log(this.section);
        });
    },
    checkAuthentification: function() {
      const cookie = this.$cookie.get("user_id");
      if (!cookie) {
        this.$router.push("/");
      }
    },
    summary: function() {
      this.$router.push("/sommaire");
    },
    swapStudentView: function() {
      this.use_student_view = !this.use_student_view;
    },
    changeUserSettings: function(config) {
      if (typeof config.dyslexic_font !== "undefined")
        this.use_dyslexic_font = config.dyslexic_font;
      if (typeof config.bigger_font !== "undefined")
        this.use_bigger_font = config.bigger_font;
    },
    checkMove: function(e) {
      console.log(e);
    },
    expand: function(subsection_index, bloc_index) {
      var cols = this.section.subsections[subsection_index].blocs[bloc_index]
        .settings.cols;
      this.section.subsections[subsection_index].blocs[
        bloc_index
      ].settings.cols = cols == 12 ? 12 : cols + 1;
      //this.$forceUpdate();
    },
    collapse: function(subsection_index, bloc_index) {
      var cols = this.section.subsections[subsection_index].blocs[bloc_index]
        .settings.cols;
      this.section.subsections[subsection_index].blocs[
        bloc_index
      ].settings.cols = cols == 3 ? 3 : cols - 1;
      //this.$forceUpdate();
    },
    logout: function() {
      this.$cookie.delete("user_id");
      this.$router.push("/");
    },
    askForDowload: function() {
      this.dialogs.download = true;
    },
    askForDeleteSection: function() {
      this.dialogs.remove_section = true;
    },
    getMedias: function() {
      setTimeout(() => {
        this.overlay = false;
        this.dialogs.medias = true;
      }, 250);
    },
    showMediaLibrary: function() {
      this.overlay = true;
      this.getMedias();
    },
    askForDeleteSubSection: function(subsection_index) {
      this.subsection_to_remove = subsection_index;
      this.dialogs.remove_subsection = true;
    },
    askForUpload: function() {
      this.dialogs.upload = true;
    },
    uploadSection: function() {
      this.overlay = true;
      this.dialogs.upload = false;
      this.system_bar_message = null;
      var payload = {
        user_id: this.$cookie.get("user_id"),
        section_id: this.section_id,
        content: this.section,
        user_uuid: this.$cookie.get("user_uuid"),
      };

      this.$store
        .dispatch("saveHistory", payload)
        .then(
          (response) => {
            console.log("response >>");
            console.log(response);
            this.snackbar_upload = true;
            setTimeout(() => {
              this.downloadSection(false);
            }, 1000);
          },
          (error) => {
            console.log(error);
            this.overlay = false;
            this.system_bar_message = error.message;
          }
        )
        .finally(() => {
          window.scrollTo(0, 0);
          setTimeout(() => (this.system_bar_message = null), 3000);
        });
    },
    askForRemove: function(subsection_index, bloc_index) {
      this.blocToRemove = {
        subsection_index: subsection_index,
        bloc_index: bloc_index,
      };
      this.blocTitle = this.section.subsections[subsection_index].blocs[
        bloc_index
      ].name;
      this.dialogs.remove_bloc = true;
    },
    askForDuplicate: function(subsection_index, bloc_index) {
      this.blocToDuplicate = {
        subsection_index: subsection_index,
        bloc_index: bloc_index,
      };
      this.blocTitle = this.section.subsections[subsection_index].blocs[
        bloc_index
      ].name;
      this.dialogs.duplicate_bloc = true;
    },

    removeBloc: function() {
      this.section.subsections[this.blocToRemove.subsection_index].blocs[
        this.blocToRemove.bloc_index
      ].removed = true;
      this.dialogs.remove_bloc = false;
      this.updateToLocalStorage();
    },

    duplicateBloc: function() {
      let idx_bloc = this.section.subsections[
        this.blocToDuplicate.subsection_index
      ].blocs.length;
      let new_bloc = JSON.stringify(
        this.section.subsections[this.blocToDuplicate.subsection_index].blocs[
          this.blocToDuplicate.bloc_index
        ]
      );

      this.section.subsections[this.blocToDuplicate.subsection_index].blocs[
        idx_bloc
      ] = JSON.parse(new_bloc);
      this.section.subsections[this.blocToDuplicate.subsection_index].blocs[
        idx_bloc
      ].id = null;
      this.section.subsections[this.blocToDuplicate.subsection_index].blocs[
        idx_bloc
      ].uuid = this.$uuid.v4();
      console.log(JSON.parse(new_bloc));
      this.dialogs.duplicate_bloc = false;
      this.updateToLocalStorage();
    },

    setSelectedSubsection: function(subsection) {
      this.currentSubsection = subsection;
    },

    showBottomSheet: function(subsection, subsection_index) {
      this.setSelectedSubsection(subsection);
      this.currentSubsectionIndex = subsection_index;
      this.dialogs.sheet = true;
    },

    closeBottomSheet: function() {
      this.dialogs.sheet = false;
    },

    saveEditor: function(payload) {
      this.closeEditor();
      const bloc_index =
        payload.bloc_index === null
          ? this.section.subsections[payload.subsection_index].blocs.length
          : payload.bloc_index;
      const bloc = JSON.parse(JSON.stringify(payload));
      if (bloc.uuid === null || bloc.uid === undefined) {
        bloc.uuid = this.$uuid.v4();
      }
      this.section.subsections[payload.subsection_index].blocs[
        bloc_index
      ] = bloc;
      this.updateToLocalStorage();
    },

    closeEditor: function() {
      this.dialogs.sheet = false;
      this.dialogs.edit_html = false;
      this.dialogs.edit_video = false;
      this.dialogs.edit_audio = false;
      this.dialogs.edit_title = false;
      this.dialogs.edit_image = false;
      this.dialogs.edit_caption_image = false;
      this.dialogs.edit_genially = false;
      this.dialogs.edit_timeline = false;
      this.dialogs.edit_diagram = false;
      this.dialogs.edit_raw_html = false;
      this.dialogs.edit_svg_map = false;
      this.dialogs.free_question = false;
      this.dialogs.qcm = false;
      this.dialogs.drag_and_drop = false;
      this.dialogs.associations_links = false;
      this.dialogs.text_with_gaps = false;
      this.dialogs.written_trace = false;
      this.dialogs.true_false = false;
      this.dialogs.no_answer_needed = false;
      this.dialogs.timeline_drop = false;
      this.dialogs.caption_drop = false;
      this.dialogs.text_to_order = false;
      this.dialogs.table_drop = false;
      this.dialogs.medias = false;
    },

    closeMediasLibrary: function() {
      this.dialogs.medias = false;
    },

    closeModalCapacities: function() {
      this.capacities_modal = false;
    },

    closeFullScreen: function() {
      this.dialogs.full_screen = false;
    },

    viewFullScreen: function(subsection, bloc) {
      this.selected_bloc = bloc;
      this.selected_subsection = subsection;
      this.dialogs.full_screen = true;
    },
  },
};
</script>

<style>
.v-navigation-drawer__content {
  background-color: #a18f79;
}
.hidden {
  display: none;
}
.v-bottom-sheet.v-dialog {
  background-color: white;
}

.tiptap-vuetify-editor.vuetify-for-title {
  margin: 0 0 24px 0;
}

.tiptap-vuetify-editor.vuetify-for-title .ProseMirror {
  margin: 0 !important;
}

.vuetify-for-title .tiptap-vuetify-editor__content p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  min-height: 0 !important;
}

.tiptap-vuetify-editor.high .ProseMirror {
  min-height: 250px;
}

.tiptap-vuetify-editor.medium .ProseMirror {
  min-height: 150px;
}

.corriges {
  margin-top: 24px;
}
small.subsection-name {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}
small.subsection-name-missing {
  color: red;
  font-size: 17px;
  font-weight: 700;
}
.subsection-title {
  background-color: #272727;
  padding: 6px 12px 8px 12px;
  font-size: 15px;
  color: white;
  text-align: center;
  line-height: normal;
  font-weight: 300;
  color: white;
  border-top: 3px solid black;
}
.infosection {
  padding: 12px 18px 0 18px;
}
.infosection h2 {
  color: white;
  font-size: 18px;
  text-align: left;
  font-weight: 300;
}
.infosection h3 {
  color: white;
  font-size: 12px;
  text-align: left;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid white;
  padding: 0 0 4px 0;
}
</style>

<style src="../../assets/css/global.css"></style>
