<template>
  <div class="image">
    <!-- DEBUT CONSIGNE -->
    <div v-if="bloc.settings.infos">
      <div v-if="bloc.settings.infos.level_1">
        <em
          v-if="bloc.settings.infos.level_1.consigne"
          class="consigne"
          v-html="bloc.settings.infos.level_1.consigne"
        ></em>
      </div>
    </div>
    <!-- FIN CONSIGNE -->
    <v-card-title v-html="bloc.name"></v-card-title>
    <img :src="bloc.content.level_1.url" />
    <v-card-text v-if="bloc.content.level_1.description">
      <div>
        <em>{{ bloc.content.level_1.description }}</em>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: ["bloc"]
};
</script>
