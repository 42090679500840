<template>
  <v-dialog v-model="show_hide" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-toolbar dark color="black">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Texte à trous</v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-container v-if="bloc">
        <v-tabs v-model="tab" background-color="blue" color="basil" grow>
          <v-tab v-for="item in levels" :key="item">{{ item }}</v-tab>
          <v-tab class="orange">
            <v-icon small color="white">mdi-settings-pencil</v-icon>&nbsp;Paramètres
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-select
              value
              :items="['Glisser/déposer','Choix dans une liste','Saisie libre']"
              v-model="bloc.settings.infos.level_1.type"
              label="Type d'exercice"
            ></v-select>
            <v-textarea
              v-model="bloc.settings.infos.level_1.consigne"
              outlined
              label="Consigne de l'exercice"
              auto-grow
              rows="2"
            ></v-textarea>
            <v-card color="basil" flat>
              <em>
                Veuillez
                <strong>mettre en gras</strong> dans le texte les mots qui seront à glisser/déposer, saisir ou choisir dans une liste.
              </em>
              <v-tabs v-model="editortab">
                <v-tab>Mode visuel</v-tab>
                <v-tab>Mode HTML</v-tab>
              </v-tabs>
              <v-tabs-items v-model="editortab">
                <v-tab-item>
                  <editor
                    placeholder="Texte à compléter."
                    v-model="bloc.content.level_1.html"
                    :api-key="tinymceKey"
                    :init="tinyMceCustomInit"
                  />
                </v-tab-item>
                <v-tab-item>
                  <v-textarea v-model="bloc.content.level_1.html" filled row rows="15"></v-textarea>
                </v-tab-item>
              </v-tabs-items>
              <v-textarea
                v-model="bloc.content.level_1.additional_words"
                outlined
                label="Mots additionnels à séparer par des virgules"
                auto-grow
                rows="2"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_1"
                v-bind:level="'level_1'"
                :eleve="true"
                :professeur="true"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-select
              value
              :items="['Glisser/déposer','Choix dans une liste','Saisie libre']"
              v-model="bloc.settings.infos.level_2.type"
              label="Type d'exercice"
            ></v-select>
            <v-textarea
              v-model="bloc.settings.infos.level_2.consigne"
              outlined
              label="Consigne de l'exercice"
              auto-grow
              rows="2"
            ></v-textarea>
            <v-card color="basil" flat>
              <em>
                Veuillez
                <strong>mettre en gras</strong> dans le texte les mots qui seront à glisser/déposer, saisir ou choisir dans une liste.
              </em>
              <v-tabs v-model="editortab">
                <v-tab>Mode visuel</v-tab>
                <v-tab>Mode HTML</v-tab>
              </v-tabs>
              <v-tabs-items v-model="editortab">
                <v-tab-item>
                  <editor
                    placeholder="Texte à compléter."
                    v-model="bloc.content.level_2.html"
                    :api-key="tinymceKey"
                    :init="tinyMceCustomInit"
                  />
                </v-tab-item>
                <v-tab-item>
                  <v-textarea v-model="bloc.content.level_2.html" filled row rows="15"></v-textarea>
                </v-tab-item>
              </v-tabs-items>
              <v-textarea
                v-model="bloc.content.level_2.additional_words"
                outlined
                label="Mots additionnels à séparer par des virgules"
                auto-grow
                rows="2"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_2"
                v-bind:level="'level_2'"
                :eleve="true"
                :professeur="true"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-select
              value
              :items="['Glisser/déposer','Choix dans une liste','Saisie libre']"
              v-model="bloc.settings.infos.level_3.type"
              label="Type d'exercice"
            ></v-select>
            <v-textarea
              v-model="bloc.settings.infos.level_3.consigne"
              outlined
              label="Consigne de l'exercice"
              auto-grow
              rows="2"
            ></v-textarea>
            <v-card color="basil" flat>
              <em>
                Veuillez
                <strong>mettre en gras</strong> dans le texte les mots qui seront à glisser/déposer, saisir ou choisir dans une liste.
              </em>
              <v-tabs v-model="editortab">
                <v-tab>Mode visuel</v-tab>
                <v-tab>Mode HTML</v-tab>
              </v-tabs>
              <v-tabs-items v-model="editortab">
                <v-tab-item>
                  <editor
                    placeholder="Texte à compléter."
                    v-model="bloc.content.level_3.html"
                    :api-key="tinymceKey"
                    :init="tinyMceCustomInit"
                  />
                </v-tab-item>
                <v-tab-item>
                  <v-textarea v-model="bloc.content.level_3.html" filled row rows="15"></v-textarea>
                </v-tab-item>
              </v-tabs-items>
              <v-textarea
                v-model="bloc.content.level_3.additional_words"
                outlined
                label="Mots additionnels à séparer par des virgules"
                auto-grow
                rows="2"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_3"
                v-bind:level="'level_3'"
                :eleve="true"
                :professeur="true"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <bloc-settings v-bind:bloc="bloc"></bloc-settings>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      <v-card-actions class="bottom">
        <v-spacer></v-spacer>
        <v-btn outlined color="red darken-1" text @click="cancel">Annuler</v-btn>
        <v-btn outlined class="mr-2" text color="green darken-1" @click="save">Enregistrer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BlocSettings from "../../Editors/blocSettings/BlocSettings.vue";
import Editor from "@tinymce/tinymce-vue";
import BlocCorriges from "../../Shared/BlocCorriges.vue";

export default {
  components: {
    editor: Editor,
    "bloc-settings": BlocSettings,
    "bloc-corriges": BlocCorriges
  },
  data() {
    return {
      tab: null,
      editortab: null,
      levels: this.$store.getters.levels,
      name: "",
      html_level1: "",
      html_level2: "",
      html_level3: "",
      tinymceKey: this.$store.getters.tinymceKey,
      tinyMceInit: this.$store.getters.tynimceReducedConfig,
      retainFocusValue: new Boolean(false),
      tinyMceCustomInit: {
        selector: "textarea",
        statusbar: false,
        menubar: false,
        height: 300,
        plugins: " powerpaste",
        paste_as_text: true,
        toolbar: "undo redo | bold | table "
      }
    };
  },
  props: ["show_hide", "bloc"],
  methods: {
    cancel() {
      this.$emit("close");
    },
    save() {
      const payload = this.bloc;
      this.$emit("save", payload);
    }
  }
};
</script>

<style>
.tiptap-vuetify-editor {
  border: 1px solid grey;
  margin: 10px 0;
}
.tiptap-vuetify-editor img {
  width: 100%;
  height: auto;
}
</style>