<template>
  <div class="audio">
    <v-card-title v-html="bloc.name"></v-card-title>
    <figure>
      <audio controls :src="bloc.content.level_1.url"></audio>
    </figure>
    <v-card-text v-if="bloc.content.level_1.description">
      <v-card-title>{{ bloc.name }}</v-card-title>
      <!-- DEBUT CONSIGNE -->
      <div v-if="bloc.settings.infos">
        <div v-if="bloc.settings.infos.level_1">
          <em
            v-if="bloc.settings.infos.level_1.consigne"
            class="consigne"
            v-html="bloc.settings.infos.level_1.consigne"
          ></em>
        </div>
      </div>
      <!-- FIN CONSIGNE -->
      <div>
        <em>{{ bloc.content.level_1.description }}</em>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: ["bloc"]
};
</script>
