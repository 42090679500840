<template>
  <v-dialog v-model="show_hide" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-toolbar dark color="black">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Tableau interactif à compléter</v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-container v-if="bloc">
        <v-tabs v-model="tab" background-color="blue" color="basil" grow>
          <v-tab v-for="item in levels" :key="item">{{ item }}</v-tab>
          <v-tab class="orange">
            <v-icon small color="white">mdi-settings-pencil</v-icon>&nbsp;Paramètres
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card color="basil" flat>
              <editor
                placeholder="Consigne de l'exercice"
                v-model="bloc.settings.infos.level_1.consigne"
                :api-key="tinymceKey"
                :init="tinyMceInit"
              />
              <p></p>
              <v-select
                :items="variants"
                item-text="text"
                item-value="value"
                filled
                v-model="bloc.content.level_1.exercise_variant"
                label="Variante d'exercice"
              ></v-select>
              <p></p>
              <v-select
                :items="headers"
                filled
                v-model="bloc.content.level_1.headers"
                label="Têtières"
              ></v-select>

              <em>
                Veuillez
                <strong>mettre en gras</strong> le contenu des cellules à transformer en étiquettes à glisser/déposer
                <u>OU</u> en champs de saisie libre.
              </em>
              <v-alert
                v-if="bloc.content.level_1.exercise_variant=='caption'"
                dense
                outlined
                border="left"
                type="warning"
                class="mt-2"
              >
                Attention, l'élève n'aura un score à 100% que s'il remplit le tableau dans l'ordre, s'il peut mettre les lignes dans le désordre, utiliser un exercice de type
                <strong>drag and drop / tableaux à colonnes</strong>.
              </v-alert>

              <v-tabs v-model="editortab">
                <v-tab>Mode visuel</v-tab>
                <v-tab>Mode HTML</v-tab>
              </v-tabs>
              <v-tabs-items v-model="editortab">
                <v-tab-item>
                  <editor
                    placeholder="Tableau à compléter."
                    v-model="bloc.content.level_1.html"
                    :api-key="tinymceKey"
                    :init="tinyMceCustomInit"
                  />
                </v-tab-item>
                <v-tab-item>
                  <v-textarea v-model="bloc.content.level_1.html" filled row rows="15"></v-textarea>
                </v-tab-item>
              </v-tabs-items>

              <v-textarea
                v-model="bloc.content.level_1.source"
                filled
                label="Source des données"
                auto-grow
                row
                rows="1"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_1"
                v-bind:level="'level_1'"
                :eleve="false"
                :professeur="true"
                :exercice="false"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <editor
                placeholder="Consigne de l'exercice"
                v-model="bloc.settings.infos.level_2.consigne"
                :api-key="tinymceKey"
                :init="tinyMceInit"
              />
              <p></p>
              <v-select
                :items="variants"
                item-text="text"
                item-value="value"
                filled
                v-model="bloc.content.level_2.exercise_variant"
                label="Variante d'exercice"
              ></v-select>
              <p></p>
              <v-select
                :items="headers"
                filled
                v-model="bloc.content.level_2.headers"
                label="Têtières"
              ></v-select>

              <em>
                Veuillez
                <strong>mettre en gras</strong> le contenu des cellules à transformer en étiquettes à glisser/déposer
                <u>OU</u> en champs de saisie libre.
              </em>
              <v-alert
                v-if="bloc.content.level_2.exercise_variant=='caption'"
                dense
                outlined
                border="left"
                type="warning"
                class="mt-2"
              >
                Attention, l'élève n'aura un score à 100% que s'il remplit le tableau dans l'ordre, s'il peut mettre les lignes dans le désordre, utiliser un exercice de type
                <strong>drag and drop / tableaux à colonnes</strong>.
              </v-alert>

              <v-tabs v-model="editortab">
                <v-tab>Mode visuel</v-tab>
                <v-tab>Mode HTML</v-tab>
              </v-tabs>
              <v-tabs-items v-model="editortab">
                <v-tab-item>
                  <editor
                    placeholder="Tableau à compléter."
                    v-model="bloc.content.level_2.html"
                    :api-key="tinymceKey"
                    :init="tinyMceCustomInit"
                  />
                </v-tab-item>
                <v-tab-item>
                  <v-textarea v-model="bloc.content.level_2.html" filled row rows="15"></v-textarea>
                </v-tab-item>
              </v-tabs-items>

              <v-textarea
                v-model="bloc.content.level_2.source"
                filled
                label="Source des données"
                auto-grow
                row
                rows="1"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_2"
                v-bind:level="'level_2'"
                :eleve="false"
                :professeur="true"
                :exercice="false"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <editor
                placeholder="Consigne de l'exercice"
                v-model="bloc.settings.infos.level_3.consigne"
                :api-key="tinymceKey"
                :init="tinyMceInit"
              />
              <p></p>
              <v-select
                :items="variants"
                item-text="text"
                item-value="value"
                filled
                v-model="bloc.content.level_3.exercise_variant"
                label="Variante d'exercice"
              ></v-select>
              <p></p>
              <v-select
                :items="headers"
                filled
                v-model="bloc.content.level_3.headers"
                label="Têtières"
              ></v-select>

              <em>
                Veuillez
                <strong>mettre en gras</strong> le contenu des cellules à transformer en étiquettes à glisser/déposer
                <u>OU</u> en champs de saisie libre.
              </em>
              <v-alert
                v-if="bloc.content.level_3.exercise_variant=='caption'"
                dense
                outlined
                border="left"
                type="warning"
                class="mt-2"
              >
                Attention, l'élève n'aura un score à 100% que s'il remplit le tableau dans l'ordre, s'il peut mettre les lignes dans le désordre, utiliser un exercice de type
                <strong>drag and drop / tableaux à colonnes</strong>.
              </v-alert>

              <v-tabs v-model="editortab">
                <v-tab>Mode visuel</v-tab>
                <v-tab>Mode HTML</v-tab>
              </v-tabs>
              <v-tabs-items v-model="editortab">
                <v-tab-item>
                  <editor
                    placeholder
                    v-model="bloc.content.level_3.html"
                    :api-key="tinymceKey"
                    :init="tinyMceCustomInit"
                  />
                </v-tab-item>
                <v-tab-item>
                  <v-textarea v-model="bloc.content.level_3.html" filled row rows="15"></v-textarea>
                </v-tab-item>
              </v-tabs-items>

              <v-textarea
                v-model="bloc.content.level_3.source"
                filled
                label="Source des données"
                auto-grow
                row
                rows="1"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_3"
                v-bind:level="'level_3'"
                :eleve="false"
                :professeur="true"
                :exercice="false"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <bloc-settings v-bind:bloc="bloc"></bloc-settings>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      <v-card-actions class="bottom">
        <v-spacer></v-spacer>
        <v-btn outlined color="red darken-1" text @click="cancel">Annuler</v-btn>
        <v-btn outlined class="mr-2" text color="green darken-1" @click="save">Enregistrer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BlocSettings from "../../Editors/blocSettings/BlocSettings.vue";
import BlocCorriges from "../../Shared/BlocCorriges.vue";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    "bloc-settings": BlocSettings,
    "bloc-corriges": BlocCorriges,
    editor: Editor
  },
  data() {
    return {
      editortab: null,
      headers: [
        "Pas de têtière",
        "1ère ligne",
        "1ère colonne",
        "1ère ligne et 1ère colonne"
      ],
      variants: [
        {
          value: "caption",
          text: "Etiquettes à glisser/déposer"
        },
        {
          value: "textfield",
          text: "Champs de texte en saisie libre"
        }
      ],
      tab: null,
      levels: this.$store.getters.levels,
      tinymceKey: this.$store.getters.tinymceKey,
      tinyMceInit: this.$store.getters.tynimceReducedConfig,
      retainFocusValue: new Boolean(false),
      tinyMceCustomInit: {
        selector: "textarea",
        statusbar: false,
        menubar: false,
        height: 300,
        plugins: "table powerpaste",
        paste_as_text: true,
        toolbar: "undo redo | bold italic superscript subscript | link | table "
      }
    };
  },
  props: ["show_hide", "bloc"],
  methods: {
    cancel() {
      this.$emit("close");
    },
    save() {
      const payload = this.bloc;
      this.$emit("save", payload);
    }
  }
};
</script>
<style>
</style>