<template>
  <v-dialog
    v-model="show_hide"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    class="fullscreen"
  >
    <v-toolbar dark color="#a18f79">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title v-if="bloc">{{ bloc.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-slider hint="Im a hint" max="1.5" min="0.5" value="1" step=".1" label="zoom"></v-slider>
    </v-toolbar>
    <v-container v-if="bloc">
      <v-row>
        <v-col cols="12">
          <bloc-generique v-bind:bloc="bloc"></bloc-generique>

          <div v-if="bloc.settings.attached !== null && bloc.settings.attached !== undefined">
            <v-divider></v-divider>
            <bloc-generique
              v-bind:showEditButton="true"
              v-bind:bloc="getAttachedBlocUuid(subsection, bloc.settings.attached)"
            ></bloc-generique>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>
<script>
import BlocGeneric from "../BlocGeneric/BlocGeneric.vue";

export default {
  components: {
    "bloc-generique": BlocGeneric,
  },
  data() {
    return {};
  },
  props: ["show_hide", "bloc", "subsection"],
  methods: {
    getAttachedBlocUuid(subsection, attached_bloc_uuid) {
      for (let i = 0; i < subsection.blocs.length; i++) {
        if (subsection.blocs[i].uuid == attached_bloc_uuid) {
          return subsection.blocs[i];
        }
      }
    },
    cancel() {
      this.$emit("close");
    },
  },
};
</script>
<style>
.v-dialog--fullscreen {
  background-color: #fbf0d9;
  padding: 0 !important;
  opacity: 1;
  z-index: 999;
}
</style>