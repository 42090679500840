<template>
  <div>
    <v-card-title v-html="bloc.name"></v-card-title>
    <!-- DEBUT CONSIGNE -->
    <v-card-text>
      <div v-if="bloc.settings.infos">
        <div v-if="bloc.settings.infos.level_1">
          <em
            v-if="bloc.settings.infos.level_1.consigne"
            class="consigne"
            v-html="bloc.settings.infos.level_1.consigne"
          ></em>
        </div>
      </div>
    </v-card-text>
    <!-- FIN CONSIGNE -->
    <v-card-text
      v-for="(question, question_index) in bloc.content.level_1"
      v-bind:key="question_index+'_level1'"
    >
      <div v-if="question.question">
        <h3>{{question_index+1}}. {{ decode(question.question) }}</h3>
      </div>
    </v-card-text>
    <v-card-text
      v-for="(question, question_index) in bloc.content.level_2"
      v-bind:key="question_index+'_level2'"
    >
      <div v-if="question.question">
        <h3>{{question_index+1}}. {{ decode(question.question) }}</h3>
      </div>
    </v-card-text>
    <v-card-text
      v-for="(question, question_index) in bloc.content.level_3"
      v-bind:key="question_index+'_level3'"
    >
      <div v-if="question.question">
        <h3>{{question_index+1}}. {{ decode(question.question) }}</h3>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: ["bloc"],
  methods: {
    decode(text) {
      let result = text.replace(/(<([^>]+)>)/gi, "");
      return this.$he.decode(result);
    }
  }
};
</script>
