<template>
  <div>
    <v-card-title v-html="bloc.name"></v-card-title>
    <v-card-text>
      <!-- DEBUT CONSIGNE -->
      <div v-if="bloc.settings.infos">
        <div v-if="bloc.settings.infos.level_1">
          <em
            v-if="bloc.settings.infos.level_1.consigne"
            class="consigne"
            v-html="bloc.settings.infos.level_1.consigne"
          ></em>
        </div>
      </div>
      <!-- FIN CONSIGNE -->
      <div v-html="bloc.content.level_1.html"></div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: ["bloc"]
};
</script>

<style>
.v-toolbar__title p {
  margin-bottom: 0;
}
</style>