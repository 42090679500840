<template>
  <v-app id="inspire">
    <v-main class="background">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-toolbar dark>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-toolbar-title>Edition du manuel scolaire</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip
          v-if="user"
          class="ma-2"
          color="green"
          text-color="white"
        >{{user.firstname}} {{user.lastname}}</v-chip>
        <logout @logout="logout"></logout>
      </v-toolbar>
      <v-system-bar dark color="red" v-if="system_bar_message">
        <div class="network-error">{{ system_bar_message }}</div>
      </v-system-bar>
      <v-container darkclass="my-container">
        <v-row>
          <v-col cols="12">
            <h1 class="text-center">Mes manuels</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(item, index) in textbooks"
            v-bind:item="item"
            v-bind:index="index"
            v-bind:key="item.id"
            cols="4"
          >
            <v-card class="mx-auto my-12" max-width="374">
              <template slot="progress">
                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
              </template>
              <v-img height="250" :src="item.settings.cover">
                <v-chip color="orange" dark class="ma-5">{{ item.status }}</v-chip>
              </v-img>
              <v-card-title>{{ item.title }}</v-card-title>
              <v-card-subtitle>
                <strong>{{item.settings.classroom}}</strong>
              </v-card-subtitle>
              <v-card-text>
                <div class="text-subtitle-1">{{ item.year }}</div>
                <div>Version {{ item.version }}</div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn outlined color="indigo" @click="summary(item.id)">Editer ce manuel</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Logout from "../Logout/Logout.vue";

export default {
  name: "Textbook",
  components: {
    Logout: Logout
  },
  props: {
    source: String
  },
  data: () => ({
    user: null,
    overlay: true,
    system_bar_message: null
  }),
  mounted() {
    this.checkAuthentification();
    (this.user = this.$store.getters.user),
      this.getUserTextbooks({
        user_uuid: this.$cookie.get("user_uuid")
      });
  },
  computed: {
    textbooks() {
      return this.$store.state.user_textbooks;
    }
  },
  methods: {
    logout: function() {
      this.$cookie.delete("user_id");
      this.$router.push("/");
    },
    getUserTextbooks(payload) {
      this.$store
        .dispatch("getUserTextbooks", payload)
        .then(
          response => {
            console.log(response);
          },
          error => {
            this.system_bar_message = error.message;
          }
        )
        .finally(() => {
          this.overlay = false;
        });
    },
    summary: function(textbook_id) {
      this.overlay = true;
      this.$cookie.set("textbook_id", textbook_id);
      this.$router.push("/sommaire");
    },
    checkAuthentification: function() {
      const cookie = this.$cookie.get("user_id");
      if (!cookie) {
        this.$router.push("/");
      }
    }
  }
};
</script>

<style scoped>
.my-alert {
  margin: 0 24px 12px 24px;
}
.background {
  background-image: url("../../assets/images/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
h1 {
  color: white;
}
</style>