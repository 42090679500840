import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router';
import VueCookie from 'vue-cookie'

import {
  TiptapVuetifyPlugin
} from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

import Login from "./components/Pages/Login";
import Main from "./components/Pages/Main";
import Summary from "./components/Pages/Summary";
import Textbook from "./components/Pages/Textbook";
import ImagesLIbrary from "./components/Pages/ImagesLibrary"
import store from './store'
import UUID from "vue-uuid";

import moment from 'moment'

import he from "he";
Object.defineProperty(Vue.prototype, "$he", {
  value: he
});



Vue.prototype.moment = moment

Vue.use(VueRouter);
Vue.use(vuetify);
Vue.use(VueCookie);
Vue.use(UUID);

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})

const router = new VueRouter({
  routes: [{
      path: '/',
      component: Login,
      meta: {
        transition: 'slide-right'
      }
    },
    {
      path: '/manuels',
      component: Textbook,
      meta: {
        transition: 'slide-right'
      }
    },
    {
      path: '/sommaire',
      component: Summary,
      meta: {
        transition: 'slide-right'
      }
    },
    {
      path: '/main',
      component: Main,
      meta: {
        transition: 'slide-right'
      }
    },
    {
      path: '/images-library',
      component: ImagesLIbrary,
      meta: {
        transition: 'slide-right'
      }
    }
  ],
});

new Vue({
  router: router,
  vuetify,
  TiptapVuetifyPlugin,
  store,
  render: h => h(App)
}).$mount('#app')