<template>
  <div>
    <v-card-title v-html="bloc.name"></v-card-title>
    <!-- DEBUT CONSIGNE -->
    <div v-if="bloc.settings.infos">
      <div v-if="bloc.settings.infos.level_1">
        <em
          v-if="bloc.settings.infos.level_1.consigne"
          class="consigne"
          v-html="bloc.settings.infos.level_1.consigne"
        ></em>
      </div>
    </div>
    <!-- FIN CONSIGNE -->
    <div style="width: 100%;">
      <div style="position: relative; padding-bottom: 56.25%; padding-top: 0; height: 0;">
        <iframe
          frameborder="0"
          width="1200"
          height="675"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
          :src="bloc.content.level_1.url"
          type="text/html"
          allowscriptaccess="always"
          allowfullscreen="true"
          scrolling="yes"
          allownetworking="all"
        ></iframe>
      </div>
    </div>
    <v-card-text v-if="bloc.content.level_1.description">
      <div>
        <em>{{ bloc.content.level_1.description }}</em>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: ["bloc"],
  methods: {}
};
</script>
