<template>
  <v-dialog v-model="show_hide" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-toolbar dark color="black">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title v-html="bloc.name" v-if="bloc"></v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-container v-if="bloc">
        <span class="title">Titre du bloc</span>
        <tiptap-vuetify
          class="vuetify-for-title"
          v-model="bloc.name"
          :extensions="title_extensions"
        />
        <v-divider></v-divider>
        <v-tabs v-model="tab" background-color="blue" color="basil" grow>
          <v-tab v-for="item in levels" :key="item">{{ item }}</v-tab>
          <v-tab class="orange">
            <v-icon small color="white">mdi-settings-pencil</v-icon>&nbsp;Paramètres
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card color="basil" flat>
              <v-text-field
                label="URL de la carte"
                prepend-inner-icon="mdi-file-image"
                v-model="bloc.content.level_1.url"
                id="v-text-field-for-image"
              ></v-text-field>
              <v-text-field
                label="Styles complémentaires"
                prepend-inner-icon="mdi-language-css3"
                v-model="bloc.content.level_1.style"
                id="v-text-field-for-image"
              ></v-text-field>
              <v-textarea
                v-model="bloc.content.level_1.source"
                filled
                label="Source de la carte"
                auto-grow
                row
                rows="1"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_1"
                v-bind:level="'level_1'"
                :eleve="false"
                :professeur="true"
                :exercice="false"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-text-field
                label="URL de la carte"
                prepend-inner-icon="mdi-file-image"
                v-model="bloc.content.level_2.url"
                id="v-text-field-for-image"
              ></v-text-field>
              <v-text-field
                label="Styles complémentaires"
                prepend-inner-icon="mdi-language-css3"
                v-model="bloc.content.level_2.style"
                id="v-text-field-for-image"
              ></v-text-field>
              <v-textarea
                v-model="bloc.content.level_2.source"
                filled
                label="Source de la carte"
                auto-grow
                row
                rows="1"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_2"
                v-bind:level="'level_2'"
                :eleve="false"
                :professeur="true"
                :exercice="false"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-text-field
                label="URL de la carte"
                prepend-inner-icon="mdi-file-image"
                v-model="bloc.content.level_3.url"
                id="v-text-field-for-image"
              ></v-text-field>
              <v-text-field
                label="Styles complémentaires"
                prepend-inner-icon="mdi-language-css3"
                v-model="bloc.content.level_3.style"
                id="v-text-field-for-image"
              ></v-text-field>
              <v-textarea
                v-model="bloc.content.level_3.source"
                filled
                label="Source de la carte"
                auto-grow
                row
                rows="1"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_3"
                v-bind:level="'level_3'"
                :eleve="false"
                :professeur="true"
                :exercice="false"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <bloc-settings v-bind:bloc="bloc"></bloc-settings>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      <v-card-actions class="bottom">
        <v-spacer></v-spacer>
        <v-btn outlined color="red darken-1" text @click="cancel">Annuler</v-btn>
        <v-btn outlined class="mr-2" text color="green darken-1" @click="save">Enregistrer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BlocSettings from "../blocSettings/BlocSettings.vue";
import { TiptapVuetify, Bold, Italic } from "tiptap-vuetify";
import BlocCorriges from "../../Shared/BlocCorriges.vue";

export default {
  components: {
    TiptapVuetify,
    "bloc-settings": BlocSettings,
    "bloc-corriges": BlocCorriges
  },
  data() {
    return {
      title_extensions: [Bold, Italic],
      tab: null,
      levels: this.$store.getters.levels
    };
  },
  props: ["show_hide", "bloc"],
  methods: {
    cancel() {
      this.$emit("close");
    },
    save() {
      const payload = this.bloc;
      this.$emit("save", payload);
    }
  }
};
</script>
<style>
</style>