<template>
  <v-dialog
    v-model="modal_user_settings"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-app-bar-nav-icon class="d-none">
        <v-icon color="white" v-bind="attrs" v-on="on">
          mdi-tune-vertical
        </v-icon>
      </v-app-bar-nav-icon>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="modal_user_settings = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Préférences</v-toolbar-title>
      </v-toolbar>

      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-switch
            v-model="switch_dyslexic_font"
            label="Activer la police de caractère dyslexie"
          ></v-switch>
        </v-list-item>
        <v-list-item>
          <v-switch
            v-model="switch_bigger_font"
            label="Augmenter la taille de la typo"
          ></v-switch>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      switch_dyslexic_font: this.dyslexic_font,
      switch_bigger_font: this.bigger_font,
      modal_user_settings: false,
    };
  },
  methods: {},
  watch: {
    switch_dyslexic_font(newValue) {
      this.$emit("changeUserSettings", { dyslexic_font: newValue });
    },
    switch_bigger_font(newValue) {
      this.$emit("changeUserSettings", { bigger_font: newValue });
    },
  },
  props: ["dyslexic_font", "bigger_font"],
};
</script>