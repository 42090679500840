<template>
  <div>
    <v-overlay z-index="9" color="grey" opacity="0.5" :value="mediasOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog
      :retain-focus="retainFocusValue"
      v-model="show_hide"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-toolbar dark color="black">
        <v-btn icon dark @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Bibliothèque de médias</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-container>
          <v-card class="ma-3 pa-2">
            <v-card-title>
              <p>Sélectionnez un fichier sur votre ordinateur</p>
            </v-card-title>
            <v-card-subtitle>
              <v-icon>mdi-information</v-icon>Attention, les photos ne doivent pas être en pdf mais en jpg</v-card-subtitle>
            <v-card-actions>
              <div>
              <input type="file" id="file" ref="file" v-on:change="onChangeFileUpload()" />
                <p class="text-danger" v-if="saveFileErrorMessage">{{saveFileErrorMessage}}</p>

              </div>
              <v-spacer></v-spacer>
              <v-btn
                text
                outlined
                color="green darken-1"
                v-if="file"
                @click="save"
              >Enregistrer le fichier</v-btn>
            </v-card-actions>
          </v-card>
          <v-container>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn @click="setCategory('PDF')" class="mr-2" rounded color="primary" dark>Afficher les fichiers PDF</v-btn>
                <v-btn @click="setCategory('IMAGE')" rounded color="primary" dark>Afficher les images</v-btn>
              </v-col>
            </v-row>
            <v-row class="v-row medias">
              <v-col cols="3" v-for="media in displayMedias" :key="media.name">
                <v-card class="selectable" v-if="category == 'PDF'" @click.stop="selectMedia(media)">
                  <v-card-title>{{ media.originalName }}</v-card-title>
                  <v-card-subtitle>
                    {{
                      media.dateUpdate | formatDate
                    }}
                  </v-card-subtitle>
                </v-card>
                <v-card v-else class="mx-auto" max-width="344" @click.stop="selectMedia(media)">
                  <v-img v-if="category == 'IMAGE'"
                    :src="filePath(media)"
                    class="selectable"
                    height="200px"
                  >
                    <v-chip class="ma-2" color="primary">
                      {{
                      media.mimeType
                      }}
                    </v-chip>
                  </v-img>
                  <v-card-title>{{ media.originalName }}</v-card-title>
                  <v-card-subtitle>
                    {{
                    media.dateUpdate | formatDate
                    }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="6">
                <!--

                <v-text-field
                  label="Fitre de recherche"
                  v-model="search_filter"
                  placeholder="Saisissez une chaine de caractères pour filtrer la recherche d'image"
                ></v-text-field>
              
                -->
              </v-col>
              <v-col cols="6">
                <div class="text-center">
                  <v-btn v-if="category == 'PDF'" @click="loadMore()" class="mr-2" rounded color="primary" dark>{{ buttonPdfMessage() }}</v-btn>
                  <v-btn v-if="category == 'IMAGE'"  @click="loadMore()" rounded color="primary" dark>{{ buttonImgMessage() }}</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  components: {},
  mounted() {
    this.displayMedias = this.medias;
    console.log(this.displayMedias);
    this.setCategory("IMAGE");
  },
  data() {
    return {
      search_filter: null,
      displayMedias: [],
      retainFocusValue: new Boolean(false),
      booleanTrue: new Boolean(true),
      file: null,
      mediasOverlay: false,
      show: false,
      Item: [],
      page: 0,
      category: null,
      saveFileErrorMessage:null,
    };
  },
  props: ["show_hide", "bloc", "medias"],
  filters: {
    formatDate: function(value) {
      if (value) {
        return (
          "Uploadé le " + moment(String(value)).format("DD/MM/YYYY à HH:mm")
        );
      }
    }
  },
  methods: {
    buttonImgMessage: function() {
      return this.displayMedias != null && this.displayMedias != []
        ? "Afficher plus d'images"
        : "Afficher les images";
    },
    buttonPdfMessage: function() {
      return this.displayMedias != null && this.displayMedias != []
        ? "Afficher plus de fichiers"
        : "Afficher les fichiers PDF";
    },
    getMedias: function(payload) {
      this.$store.dispatch("getMedias", payload).then(
        response => {
          console.clear();
          console.log(this.displayMedias);
          console.log(response.data);
          this.displayMedias =
            this.displayMedias != null ? this.displayMedias : [];
          this.displayMedias = this.displayMedias.concat(response.data);
        },
        error => {
          console.log(error);
          this.system_bar_message = "Impossible de charger les médias";
        }
      );
    },
    cancel() {
      this.$emit("close");
    },
    setCategory(category) {
      this.category = category;
      this.displayMedias = [];
      this.page = 0;
      this.loadMore();
    },
    loadMore() {
      this.page++;
      const payload = {
        category: this.category,
        page: this.page,
        filter: this.search_filter
      };
      this.getMedias(payload);
    },
    filePath(media) {
      return media.s3Location;
    },
    onChangeFileUpload() {
      this.saveFileErrorMessage = null;
      this.file = this.$refs.file.files[0];
    },
    selectMedia(media) {
      this.$emit("setSelectedMediaValue", media);
    },
    save() {
      this.mediasOverlay = true;
      if (this.file.size > 2621440) {
        this.mediasOverlay = false;
        this.saveFileErrorMessage = "Le fichier est trop volumineux. La taille maximale autorisée est de 2,6 Mo.";
        return;
      }

      if (this.file !== null && this.file !== undefined) {
        const url = this.$store.getters.baseApi + "textbook/api/upload";
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("uuid", this.$uuid.v4());
        console.log(formData);
        const that = this;
        axios
          .post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(function(data) {
            console.log(data.data);
          })
          .catch(function(error) {
            console.log("FAILURE!!", error);
            this.saveFileErrorMessage = "Une erreur a été rencontrée lors de l'envoi du fichier.";
          })
          .finally(function() {
            that.mediasOverlay = false;
            that.file = null;
            that.displayMedias = [];
            that.page = 0;
            that.loadMore();
          });
      }
    }
  }
};
</script>
<style scoped>
.selectable {
  cursor: pointer;
  opacity: 0.8;
  border: 1px solid grey;
  border-radius: 16px !important;
}
.selectable:hover {
  opacity: 1;
}
.fullwidth {
  width: 100%;
}
.v-sheet.v-card {
  overflow: hidden;
}
.v-card__title {
  font-size: 14px;
  text-overflow: ellipsis;
}
</style>
