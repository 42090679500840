<template>
  <v-app id="inspire">
    <v-main class="background">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-toolbar dark>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-toolbar-title>Edition du manuel scolaire</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip
          v-if="user"
          class="ma-2"
          color="green"
          text-color="white"
        >{{user.firstname}} {{user.lastname}}</v-chip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon v-bind="attrs" v-on="on">
              <v-icon v-on:click="textbooks()" color="blue">mdi-book-multiple</v-icon>
            </v-app-bar-nav-icon>
          </template>
          <span>Retour aux manuels</span>
        </v-tooltip>

        <!--
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon v-bind="attrs" v-on="on">
              <v-icon v-on:click="imageLibrary()" color="yellow">mdi-folder-multiple-image</v-icon>
            </v-app-bar-nav-icon>
          </template>
          <span>Bibliothèque d'images</span>
        </v-tooltip>
        -->

        <logout @logout="logout"></logout>
      </v-toolbar>
      <v-system-bar dark color="red" v-if="system_bar_message">
        <div class="network-error">{{ system_bar_message }}</div>
      </v-system-bar>
      <v-container darkclass="my-container">
        <v-row>
          <v-col cols="12">
            <h1
              v-if="textbook.infos"
              class="text-center"
            >{{ textbook.infos.title }} - {{ textbook.infos.year }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(theme, index) in textbook.themes"
            v-bind:item="theme"
            v-bind:index="index"
            v-bind:key="theme.id"
            cols="12"
          >
            <v-card class="mx-auto card">
              <template slot="progress">
                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
              </template>
              <v-img height="250" src="../../assets/images/theme.jpg">
                <v-chip class="ma-5 chip">{{ theme.infos.label }}</v-chip>
              </v-img>
              <v-card-title>{{ theme.infos.title }}</v-card-title>
              <v-divider></v-divider>
              <div
                class="ma-4"
                v-for="chapter in theme.chapters"
                v-bind:item="chapter"
                v-bind:key="chapter.id"
              >
                <h4>
                  <strong>{{ chapter.infos.label }}</strong>
                  :
                  {{ chapter.infos.title }}
                </h4>

                <draggable
                  @end="saveNewSortedPositions(chapter)"
                  v-if="chapter.sections"
                  class="left-elements"
                  :list="chapter.sections"
                >
                  <v-badge
                    class="section-badge"
                    v-for="section in chapter.sections"
                    v-bind:item="section"
                    v-bind:key="section.id"
                    color="purple"
                    :content="section.count"
                    overlap
                    offset-y="10"
                    offset-x="10"
                  >
                    <div class="section" @click="edit(section.id)">
                      <div class="badge">
                        <div>
                          <small>{{ section.name }}</small>
                        </div>
                        <div>{{ section.content.title }}</div>
                      </div>
                    </div>
                  </v-badge>
                </draggable>

                <v-btn
                  @click="open_section_dialog(chapter)"
                  class="ma-2"
                  outlined
                  color="indigo"
                >Ajouter une section</v-btn>
              </div>
              <v-divider></v-divider>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <!-- DEBUT POPUP SECTION DIALOG -->
      <dialog-add-section
        v-if="section_dialog_chapter"
        :section_dialog="section_dialog"
        :section_dialog_chapter="section_dialog_chapter"
        v-bind:title="title"
        v-bind:name="default_section_name"
        @cancel-section-dialog="cancel_section_dialog"
        @save-section-dialog="save_section_dialog"
      ></dialog-add-section>
      <!-- FIN POPUP SECTION DIALOG -->
    </v-main>
  </v-app>
</template>

<script>
import Logout from "../Logout/Logout.vue";
import DialogAddSection from "../DialogAddSection/DialogAddSection.vue";
import draggable from "vuedraggable";

export default {
  name: "Summary",
  components: {
    Logout: Logout,
    "dialog-add-section": DialogAddSection,
    draggable
  },
  props: {
    source: String
  },
  data: () => ({
    user: null,
    default_section_name: "Section libre",
    overlay: true,
    section_dialog: false,
    section_dialog_chapter: false,
    title: null,
    name: null,
    system_bar_message: null,
    textbook: { themes: null }
  }),
  created() {},
  mounted() {
    this.checkAuthentification();
    this.user = this.$store.getters.user;
    this.loadSummary();
  },
  methods: {
    saveNewSortedPositions: function(chapter) {
      console.log(chapter.sections);
      this.overlay = true;
      this.$store
        .dispatch("setSectionsOrder", {
          textbook_id: this.$cookie.get("textbook_id"),
          sections: chapter.sections
        })
        .then(
          response => {
            console.log(response);
            this.textbook = this.$store.getters.textbookSummary;
          },
          error => {
            this.system_bar_message = error.message;
          }
        )
        .finally(() => {
          this.overlay = false;
        });
    },
    loadSummary: function() {
      this.$store
        .dispatch("getTextbookSummary", {
          textbook_id: this.$cookie.get("textbook_id")
        })
        .then(
          response => {
            console.log(response);
            this.textbook = this.$store.getters.textbookSummary;
          },
          error => {
            this.system_bar_message = error.message;
          }
        )
        .finally(() => {
          this.overlay = false;
        });
    },
    saveNewSection: function(payload) {
      this.overlay = true;
      this.$store
        .dispatch("setNewSection", payload)
        .then(
          response => {
            console.log(response);
            this.textbook = this.$store.getters.textbookSummary;
          },
          error => {
            this.system_bar_message = error.message;
          }
        )
        .finally(() => {
          this.overlay = false;
          this.loadSummary();
        });
    },
    open_section_dialog: function(chapter) {
      this.section_dialog_chapter = chapter;
      this.section_dialog = true;
    },
    cancel_section_dialog: function() {
      this.section_dialog = false;
    },
    save_section_dialog: function(params) {
      this.section_dialog = false;
      var payload = {
        textbook_id: this.$cookie.get("textbook_id"),
        chapter_id: this.section_dialog_chapter.infos.id,
        title: params.title,
        name: params.name,
        problematique: params.problematique,
        user_uuid: this.$cookie.get("user_uuid"),
        uuid: this.$uuid.v4()
      };
      this.saveNewSection(payload);
    },
    textbooks: function() {
      this.$router.push("/manuels");
    },
    imageLibrary: function() {
      this.$router.push("/images-library");
    },
    logout: function() {
      this.$cookie.delete("user_id");
      this.$router.push("/");
    },
    edit: function(section_id) {
      this.overlay = true;
      this.$cookie.set("section_id", section_id);
      this.$router.push("/main");
    },
    checkAuthentification: function() {
      const cookie = this.$cookie.get("user_id");
      if (!cookie) {
        this.$router.push("/");
      }
    }
  }
};
</script>

<style scoped>
.my-alert {
  margin: 0 24px 12px 24px;
}
.background {
  background-image: url("../../assets/images/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.chip {
  font-size: 1.25rem;
  font-weight: 600;
  color: royalblue;
}
.card {
  /*height: 100%;*/
  max-width: 600px;
}
.section {
  border-radius: 4px;
  padding: 8px;
  border: 1px solid #3f51b5;
  background-color: lightgoldenrodyellow;
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 8px;
}
.v-chip.v-size--default {
  border-radius: 16px;
  font-size: 14px;
  height: 32px;
  padding: 21px 10px;
}
.section-badge {
  margin-bottom: 8px;
}

.badge {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
}
.badge div {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  line-height: normal;
}
h1 {
  color: white;
}
h4 {
  font-weight: 400;
}
</style>