<template>
  <div>
    <v-dialog v-model="show_hide" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-toolbar dark color="black">
        <v-btn icon dark @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-html="bloc.name" v-if="bloc"></v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-container v-if="bloc">
          <span class="title">Titre du bloc</span>
          <tiptap-vuetify
            class="vuetify-for-title"
            v-model="bloc.name"
            :extensions="title_extensions"
          />
          <v-divider></v-divider>
          <v-tabs v-model="tab" background-color="blue" color="basil" grow>
            <v-tab v-for="item in levels" :key="item">{{ item }}</v-tab>
            <v-tab class="orange">
              <v-icon small color="white">mdi-settings-pencil</v-icon>&nbsp;Paramètres
            </v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card v-if="bloc.content.level_1" color="basil" flat>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      :items="['horizontal','vertical']"
                      filled
                      v-model="bloc.content.level_1.kind"
                      label="Sens de la frise"
                    ></v-select>
                  </v-col>
                </v-row>
                <div
                  class="pa-2"
                  v-for="(subitem, subindex) in bloc.content.level_1.items"
                  :key="subindex"
                >
                  <v-card v-if="subitem" class="pa-2" shaped style="width: 100%">
                    <v-row>
                      <v-col cols="2">
                        <v-textarea
                          v-model="subitem.date"
                          filled
                          label="Date"
                          auto-grow
                          rows="1"
                          background-color="blue-grey lighten-4"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="9">
                        <v-textarea
                          v-model="subitem.event"
                          filled
                          label="Evénement"
                          auto-grow
                          rows="1"
                          background-color="blue-grey lighten-4"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="1">
                        <v-btn
                          v-if="bloc.content.level_1.items.length > 1"
                          text
                          icon
                          color="red lighten-2"
                          v-on:click="
                          deleteItem(bloc.content.level_1.items, subindex)
                        "
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn
                          text
                          icon
                          color="green lighten-2"
                          v-on:click="showCapacities(bloc.content.level_1.items, subindex)"
                        >
                          <v-icon>mdi-tag-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
                <v-row>
                  <v-col cols="12" class="text-center">
                    <v-btn
                      v-on:click="addItem(bloc.content.level_1.items)"
                      class="ma-2"
                      color="primary"
                      dark
                    >
                      Ajouter une date
                      <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="bloc.content.level_1.source"
                      filled
                      label="Légende du document"
                      row
                      rows="3"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="bloc.content.level_1.credits"
                      filled
                      label="Crédits du document"
                      row
                      rows="2"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card v-if="bloc.content.level_2" color="basil" flat>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      :items="['horizontal','vertical']"
                      filled
                      v-model="bloc.content.level_2.kind"
                      label="Sens de la frise"
                    ></v-select>
                  </v-col>
                </v-row>
                <div
                  class="pa-2"
                  v-for="(subitem, subindex) in bloc.content.level_2.items"
                  :key="subindex"
                >
                  <v-card v-if="subitem" class="pa-2" shaped style="width: 100%">
                    <v-row>
                      <v-col cols="2">
                        <v-textarea
                          v-model="subitem.date"
                          filled
                          label="Date"
                          auto-grow
                          rows="1"
                          background-color="blue-grey lighten-4"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="9">
                        <v-textarea
                          v-model="subitem.event"
                          filled
                          label="Evénement"
                          auto-grow
                          rows="1"
                          background-color="blue-grey lighten-4"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="1">
                        <v-btn
                          v-if="bloc.content.level_2.items.length > 1"
                          text
                          icon
                          color="red lighten-2"
                          v-on:click="
                          deleteItem(bloc.content.level_2.items, subindex)
                        "
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn
                          text
                          icon
                          color="green lighten-2"
                          v-on:click="showCapacities(bloc.content.level_2.items, subindex)"
                        >
                          <v-icon>mdi-tag-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
                <v-row>
                  <v-col cols="12" class="text-center">
                    <v-btn
                      v-on:click="addItem(bloc.content.level_2.items)"
                      class="ma-2"
                      color="primary"
                      dark
                    >
                      Ajouter une date
                      <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="bloc.content.level_2.source"
                      filled
                      label="Légende du document"
                      row
                      rows="3"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="bloc.content.level_2.credits"
                      filled
                      label="Crédits du document"
                      row
                      rows="2"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card v-if="bloc.content.level_3" color="basil" flat>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      :items="['horizontal','vertical']"
                      filled
                      v-model="bloc.content.level_3.kind"
                      label="Sens de la frise"
                    ></v-select>
                  </v-col>
                </v-row>
                <div
                  class="pa-2"
                  v-for="(subitem, subindex) in bloc.content.level_3.items"
                  :key="subindex"
                >
                  <v-card v-if="subitem" class="pa-2" shaped style="width: 100%">
                    <v-row>
                      <v-col cols="2">
                        <v-textarea
                          v-model="subitem.date"
                          filled
                          label="Date"
                          auto-grow
                          rows="1"
                          background-color="blue-grey lighten-4"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="9">
                        <v-textarea
                          v-model="subitem.event"
                          filled
                          label="Evénement"
                          auto-grow
                          rows="1"
                          background-color="blue-grey lighten-4"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="1">
                        <v-btn
                          v-if="bloc.content.level_3.items.length > 1"
                          text
                          icon
                          color="red lighten-2"
                          v-on:click="
                          deleteItem(bloc.content.level_3.items, subindex)
                        "
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn
                          text
                          icon
                          color="green lighten-2"
                          v-on:click="showCapacities(bloc.content.level_3.items, subindex)"
                        >
                          <v-icon>mdi-tag-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
                <v-row>
                  <v-col cols="12" class="text-center">
                    <v-btn
                      v-on:click="addItem(bloc.content.level_3.items)"
                      class="ma-2"
                      color="primary"
                      dark
                    >
                      Ajouter une date
                      <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="bloc.content.level_3.source"
                      filled
                      label="Légende du document"
                      row
                      rows="3"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="bloc.content.level_3.credits"
                      filled
                      label="Crédits du document"
                      row
                      rows="2"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <bloc-settings v-bind:bloc="bloc"></bloc-settings>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
        <v-card-actions class="bottom">
          <v-spacer></v-spacer>
          <v-btn outlined color="red darken-1" text @click="cancel">Annuler</v-btn>
          <v-btn outlined class="mr-2" text color="green darken-1" @click="save">Enregistrer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <bloc-capacities
      v-bind:current_item="current_item"
      v-bind:capacities_modal="capacities_modal"
      @close-capacities="closeCapacities"
    ></bloc-capacities>
  </div>
</template>
<script>
import BlocSettings from "../../Editors/blocSettings/BlocSettings.vue";
import BlocCapacities from "../../Editors/BlocCapacities/BlocCapacities.vue";
import { TiptapVuetify, Bold, Italic } from "tiptap-vuetify";

export default {
  components: {
    TiptapVuetify,
    "bloc-settings": BlocSettings,
    "bloc-capacities": BlocCapacities
  },
  data() {
    return {
      title_extensions: [Bold, Italic],

      current_item: { capacities: null, tags: null },
      capacities_modal: false,
      tab: null,
      levels: this.$store.getters.levels
    };
  },
  props: ["show_hide", "bloc"],
  methods: {
    cancel() {
      this.$emit("close");
    },
    save() {
      const payload = this.bloc;
      this.$emit("save", payload);
    },
    addItem(place) {
      place.push({ date: "", event: "" });
    },
    deleteItem(place, index) {
      place.splice(index, 1);
    },
    showCapacities(place, index) {
      if (
        place[index].settings === undefined ||
        place[index].settings == null
      ) {
        place[index].settings = {
          capacities: [],
          tags: this.$store.getters.tags
        };
      }
      this.current_item = place[index].settings;

      this.capacities_modal = true;
    },
    closeCapacities() {
      this.capacities_modal = false;
    }
  }
};
</script>
<style>
</style>