<template>
  <v-dialog v-model="show_hide" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-toolbar dark color="black">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title v-html="bloc.name" v-if="bloc"></v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-container v-if="bloc">
        <span class="title">Intertitre</span>
        <vue-editor :editor-toolbar="customToolbar" v-model="bloc.name" />
        <v-divider></v-divider>
        <v-row class="pt-3">
          <v-col cols="12">
            <h4>Apparence spécifique du bloc</h4>
          </v-col>
          <v-col v-for="item in classes" v-bind:key="item.class" cols="2">
            <v-checkbox v-model="bloc.settings.classes" :label="item.label" :value="item.class"></v-checkbox>
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions class="bottom">
        <v-spacer></v-spacer>
        <v-btn outlined color="red darken-1" text @click="cancel">Annuler</v-btn>
        <v-btn outlined class="mr-2" text color="green darken-1" @click="save">Enregistrer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      tab: null,
      classes: [
        { label: "Cours", class: "bloc-cours" },
        { label: "Pour aller plus loin", class: "bloc-plus-loin" }
      ],
      retainFocusValue: new Boolean(false),
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ script: "sub" }, { script: "super" }],
        [{ header: [false, 3, 4, 5] }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        ["clean"]
      ]
    };
  },
  props: ["show_hide", "bloc"],
  methods: {
    cancel() {
      this.$emit("close");
    },
    save() {
      const payload = this.bloc;
      this.$emit("save", payload);
    }
  }
};
</script>
<style>
.orange {
  background-color: orange;
}
</style>