<template>
  <div>
    <div>
      <div class="mt-2">
        <hr />
      </div>
      <v-row class="mt-2">
        <v-col v-if="level!='level_1'" cols="6" class="text-center">
          <v-btn
            @click="duplicateLevel('level_1',level)"
            v-if="level!='level_1'"
            color="purple darken-4"
            dark
            small
            class="mr-3"
            elevation="2"
          >Récupérer le contenu intermédiaire</v-btn>
        </v-col>
        <v-col v-if="level!='level_2'" cols="6" class="text-center">
          <v-btn
            @click="duplicateLevel('level_2',level)"
            color="purple darken-4"
            dark
            small
            class="mr-3"
            elevation="2"
          >Récupérer le contenu novice</v-btn>
        </v-col>
        <v-col v-if="level!='level_3'" cols="6" class="text-center">
          <v-btn
            @click="duplicateLevel('level_3',level)"
            color="purple darken-4"
            dark
            small
            elevation="2"
          >Récupérer le contenu expert</v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="corriges">
      <h3 v-if="title">{{title}}</h3>
      <v-expansion-panels>
        <v-expansion-panel v-if="professeur">
          <v-expansion-panel-header color="white">Corrigé du professeur</v-expansion-panel-header>
          <v-expansion-panel-content color="white">
            <v-tabs v-model="editortab">
              <v-tab>Mode visuel</v-tab>
              <v-tab>Mode HTML</v-tab>
            </v-tabs>
            <v-tabs-items v-model="editortab">
              <v-tab-item>
                <editor
                  placeholder
                  v-model="element.corrige_professeur"
                  :api-key="tinymceKey"
                  :init="tinyMceCustomInit"
                />
              </v-tab-item>
              <v-tab-item>
                <v-textarea v-model="element.corrige_professeur" filled row rows="15"></v-textarea>
              </v-tab-item>
            </v-tabs-items>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="eleve">
          <v-expansion-panel-header color="white">Corrigé de l'élève</v-expansion-panel-header>
          <v-expansion-panel-content color="white">
            <editor
              placeholder
              v-model="element.corrige_eleve"
              :api-key="tinymceKey"
              :init="tinyMceCustomInit"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  components: { editor: Editor },
  data() {
    return {
      editortab: null,
      level_labels: {
        level_1: "intermédiaire",
        level_2: "novice",
        level_3: "expert"
      },
      tinyMceInit: this.$store.getters.tynimceReducedConfig,
      tinymceKey: this.$store.getters.tinymceKey,
      tinyMceCustomInit: {
        selector: "textarea",
        statusbar: false,
        menubar: false,
        height: 300,
        plugins: "table powerpaste",
        paste_as_text: true,
        toolbar: "undo redo | bold italic superscript subscript | link | table "
      }
    };
  },
  mounted() {
    //alert(this.level);
  },
  props: [
    "element",
    "eleve",
    "professeur",
    "exercice",
    "title",
    "level",
    "bloc"
  ],
  methods: {
    duplicateLevel(from, to) {
      if (from === undefined || to === undefined) {
        alert("Impossible de dupliquer le contenu");
      } else if (
        window.confirm(
          "Copier le niveau " +
            this.level_labels[from] +
            " vers le niveau " +
            this.level_labels[to] +
            " ?"
        )
      ) {
        this.bloc.content[to] = JSON.parse(
          JSON.stringify(this.bloc.content[from])
        );
      }
    },
    duplicate() {
      this.element.corrige_eleve = this.element.corrige_professeur;
    }
  }
};
</script>

<style scoped>
.corriges {
  padding: 18px;
  background-color: gainsboro;
  border-radius: 0;
  border: 1px solid grey;
}

.duplicate {
  font-size: 9px;
  margin: 0;
  text-transform: uppercase;
  color: #1976d2;
  font-weight: 600;
  cursor: pointer;
}

.v-card > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style> 