<template>
  <v-dialog
    v-if="section_dialog_chapter"
    v-model="section_dialog"
    persistent
    max-width="600px"
  >
    <template></template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <span class="text-h5">
              {{ section_dialog_chapter.infos.label }} :
              <strong>{{ section_dialog_chapter.infos.title }}</strong>
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Titre de la section"
                required
                v-model="title"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Problématique"
                v-model="problematique"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="section_types"
                label="Type de la section"
                v-model="name"
                required
              ></v-select>
            </v-col>
            <v-col cols="12">
              <div class="flex">
                <v-text-field
                  label="URL de l'image associée (menu de droite)"
                  prepend-inner-icon="mdi-file-image"
                  v-model="image"
                  id="v-text-field-for-image"
                ></v-text-field>
                <v-btn
                  @click="showMediasLibrary(image)"
                  class="mx-2"
                  dark
                  color="primary"
                >
                  <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp;
                  Bibliothèque
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancel()">Annuler</v-btn>
        <v-btn color="green darken-1" text @click="save()">Valider</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      section_types: [
        "--- Sections Histoire ---",
        "Introduction",
        "Section libre",
        "Repères",
        "Cours",
        "Méthode",
        "Synthèse - Je valide mes connaissances et capacités",
        "Pour aller plus loin",
        "--- Sections Géographie ---",
        "Entrée dans le thème",
        "Introduction",
        "Cartes",
        "Section libre",
        "Je teste mes connaissances",
        "Je teste mes connaissances et capacités",
        "Méthode et outils",
        "Synthèse",
      ],
    };
  },
  props: [
    "section_dialog_chapter",
    "section_dialog",
    "title",
    "name",
    "problematique",
    "image",
  ],
  methods: {
    showMediasLibrary(field) {
      this.$emit("setSelectedMediaItem", field);
      this.$emit("showmedias", "IMAGE");
    },
    cancel() {
      this.$emit("cancel-section-dialog");
    },
    save() {
      this.$emit("save-section-dialog", {
        title: this.title,
        name: this.name,
        image: this.image,
        problematique: this.problematique,
      });
    },
  },
};
</script>
<style>
</style>