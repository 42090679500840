<template>
  <v-dialog
    v-model="show_hide"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-toolbar dark color="black">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title v-html="bloc.name" v-if="bloc"></v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-container v-if="bloc">
        <span class="title">Titre du bloc audio</span>
        <tiptap-vuetify
          class="vuetify-for-title"
          v-model="bloc.name"
          :extensions="title_extensions"
        />
        <v-divider></v-divider>
        <v-tabs v-model="tab" background-color="blue" color="basil" grow>
          <v-tab v-for="item in levels" :key="item">{{ item }}</v-tab>
          <v-tab class="orange">
            <v-icon small color="white">mdi-settings-pencil</v-icon
            >&nbsp;Paramètres
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card color="basil" flat>
              <v-text-field
                label="URL du fichier audio (format mp3 uniquement)"
                prepend-inner-icon="mdi-file-audio"
                v-model="bloc.content.level_1.url"
              ></v-text-field>
              <v-row>
                <v-col cols="7">
                  <v-textarea
                    v-model="bloc.content.level_1.credits"
                    filled
                    label="Crédits du fichier audio"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
                <v-col cols="5"
                  ><v-textarea
                    v-model="bloc.content.level_1.credits_more"
                    filled
                    label="Précision sur les crédits (non affiché)"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea
                ></v-col>
              </v-row>
              <v-textarea
                v-model="bloc.content.level_1.description"
                filled
                label="Description du fichier audio"
                auto-grow
                rows="2"
              ></v-textarea>
              <v-textarea
                v-model="bloc.content.level_1.source"
                filled
                label="Légende du document"
                auto-grow
                row
                rows="1"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_1"
                v-bind:level="'level_1'"
                :eleve="false"
                :professeur="true"
                :exercice="false"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-text-field
                label="URL du fichier audio (format mp3 uniquement)"
                prepend-inner-icon="mdi-file-audio"
                v-model="bloc.content.level_2.url"
              ></v-text-field>
              <v-row>
                <v-col cols="7">
                  <v-textarea
                    v-model="bloc.content.level_2.credits"
                    filled
                    label="Crédits du fichier audio"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
                <v-col cols="5"
                  ><v-textarea
                    v-model="bloc.content.level_2.credits_more"
                    filled
                    label="Précision sur les crédits (non affiché)"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea
                ></v-col>
              </v-row>
              <v-textarea
                v-model="bloc.content.level_2.description"
                filled
                label="Description du fichier audio"
                auto-grow
                rows="2"
              ></v-textarea>
              <v-textarea
                v-model="bloc.content.level_2.source"
                filled
                label="Légende du document"
                auto-grow
                row
                rows="1"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_2"
                v-bind:level="'level_2'"
                :eleve="false"
                :professeur="true"
                :exercice="false"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-text-field
                label="URL du fichier audio (format mp3 uniquement)"
                prepend-inner-icon="mdi-file-audio"
                v-model="bloc.content.level_3.url"
              ></v-text-field>
              <v-row>
                <v-col cols="7">
                  <v-textarea
                    v-model="bloc.content.level_3.credits"
                    filled
                    label="Crédits du fichier audio"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
                <v-col cols="5"
                  ><v-textarea
                    v-model="bloc.content.level_3.credits_more"
                    filled
                    label="Précision sur les crédits (non affiché)"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea
                ></v-col>
              </v-row>
              <v-textarea
                v-model="bloc.content.level_3.description"
                filled
                label="Description du fichier audio"
                auto-grow
                rows="2"
              ></v-textarea>
              <v-textarea
                v-model="bloc.content.level_3.source"
                filled
                label="Légende du document"
                auto-grow
                row
                rows="1"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_3"
                v-bind:level="'level_3'"
                :eleve="false"
                :professeur="true"
                :exercice="false"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <bloc-settings v-bind:bloc="bloc"></bloc-settings>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      <v-card-actions class="bottom">
        <v-spacer></v-spacer>
        <v-btn outlined color="red darken-1" text @click="cancel"
          >Annuler</v-btn
        >
        <v-btn outlined class="mr-2" text color="green darken-1" @click="save"
          >Enregistrer</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BlocSettings from "../blocSettings/BlocSettings.vue";
import { TiptapVuetify, Bold, Italic } from "tiptap-vuetify";
import BlocCorriges from "../../Shared/BlocCorriges.vue";

export default {
  components: {
    TiptapVuetify,
    "bloc-corriges": BlocCorriges,
    "bloc-settings": BlocSettings,
  },
  data() {
    return {
      title_extensions: [Bold, Italic],
      tab: null,
      levels: this.$store.getters.levels,
    };
  },
  props: ["show_hide", "bloc"],
  methods: {
    cancel() {
      this.$emit("close");
    },
    save() {
      const payload = this.bloc;
      this.$emit("save", payload);
    },
  },
};
</script>
<style>
.orange {
  background-color: orange;
}
</style>
