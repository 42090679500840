<template>
  <div>
    <v-icon :class="getClass('level_2')" small color="amber">mdi-emoticon-neutral</v-icon>
    <v-icon :class="getClass('level_1')" small color="light-green">mdi-emoticon-happy</v-icon>
    <v-icon :class="getClass('level_3')" small color="cyan">mdi-emoticon-excited</v-icon>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["bloc"],
  methods: {
    getClass(level) {
      let className = "";
      const kind = this.bloc.kind;
      if (this.bloc.content === undefined) {
        className = "icon-level-disabled";
      } else if (this.bloc.content[level] === undefined) {
        className = "icon-level-disabled";
      } else {
        switch (kind) {
          case "TEXTWITHGAPS":
          case "HTML":
          case "NOANSWERNEEDED":
            if (
              this.bloc.content[level].html === null ||
              this.bloc.content[level].html === undefined ||
              this.bloc.content[level].html == ""
            ) {
              className = "icon-level-disabled";
            }
            break;
          case "IMAGE":
          case "CAPTION":
            if (
              this.bloc.content[level].url === null ||
              this.bloc.content[level].url === undefined ||
              this.bloc.content[level].url == ""
            ) {
              className = "icon-level-disabled";
            }
            break;
          case "QCM":
          case "FREEQUESTION":
          case "WRITTENTRACE":
          case "DRAGANDDROP":
          case "TRUEFALSE":
            /*
            if (
              this.bloc.content[level][0].question === null ||
              this.bloc.content[level][0].question === undefined ||
              this.bloc.content[level][0].question == ""
            ) {
              className = "icon-level-disabled";
            }
            */
            break;
          case "ASSOCIATIONS":
            if (
              this.bloc.content[level][0] === null ||
              this.bloc.content[level][0] === undefined ||
              this.bloc.content[level][0] == ""
            ) {
              className = "icon-level-disabled";
            }
            break;
        }
      }

      return className;
    }
  }
};
</script>