<template>
  <div v-if="bloc">
    <bloc-text-with-gaps v-if="bloc.kind == 'TEXTWITHGAPS'" v-bind:bloc="bloc"></bloc-text-with-gaps>
    <bloc-video v-if="bloc.kind == 'VIDEO'" v-bind:bloc="bloc"></bloc-video>
    <bloc-audio v-if="bloc.kind == 'AUDIO'" v-bind:bloc="bloc"></bloc-audio>
    <bloc-title v-if="bloc.kind == 'TITLE'" v-bind:bloc="bloc"></bloc-title>
    <bloc-image v-if="bloc.kind == 'IMAGE'" v-bind:bloc="bloc"></bloc-image>
    <bloc-caption-image v-if="bloc.kind == 'CAPTION'" v-bind:bloc="bloc"></bloc-caption-image>
    <bloc-genially v-if="bloc.kind == 'GENIALLY'" v-bind:bloc="bloc"></bloc-genially>
    <bloc-timeline v-if="bloc.kind == 'TIMELINE'" v-bind:bloc="bloc"></bloc-timeline>
    <bloc-diagram v-if="bloc.kind == 'DIAGRAM'" v-bind:bloc="bloc"></bloc-diagram>
    <bloc-rawhtml v-if="bloc.kind == 'RAWHTML'" v-bind:bloc="bloc"></bloc-rawhtml>
    <bloc-svgmap v-if="bloc.kind == 'SVGMAP'" v-bind:bloc="bloc"></bloc-svgmap>
    <bloc-html v-if="bloc.kind == 'HTML'" v-bind:bloc="bloc"></bloc-html>
    <bloc-free-question v-if="bloc.kind == 'FREEQUESTION'" v-bind:bloc="bloc"></bloc-free-question>
    <bloc-written-trace v-if="bloc.kind == 'WRITTENTRACE'" v-bind:bloc="bloc"></bloc-written-trace>
    <bloc-true-false v-if="bloc.kind == 'TRUEFALSE'" v-bind:bloc="bloc"></bloc-true-false>
    <bloc-qcm v-if="bloc.kind == 'QCM'" v-bind:bloc="bloc"></bloc-qcm>
    <bloc-dragAndDrop v-if="bloc.kind == 'DRAGANDDROP'" v-bind:bloc="bloc"></bloc-dragAndDrop>
    <bloc-associationsLinks v-if="bloc.kind == 'ASSOCIATIONSLINKS'" v-bind:bloc="bloc"></bloc-associationsLinks>
    <bloc-no-anwser-needed v-if="bloc.kind == 'NOANSWERNEEDED'" v-bind:bloc="bloc"></bloc-no-anwser-needed>
    <bloc-timelinedrop v-if="bloc.kind == 'TIMELINEDROP'" v-bind:bloc="bloc"></bloc-timelinedrop>
    <bloc-captiondrop v-if="bloc.kind == 'CAPTIONDROP'" v-bind:bloc="bloc"></bloc-captiondrop>
    <bloc-texttoorder v-if="bloc.kind == 'TEXTTOORDER'" v-bind:bloc="bloc"></bloc-texttoorder>
    <bloc-tabledrop v-if="bloc.kind == 'TABLEDROP'" v-bind:bloc="bloc"></bloc-tabledrop>
  </div>
</template>

<script>
import BlocAudio from "../Blocs/Author/audio.vue";
import BlocTitle from "../Blocs/Author/title.vue";

import BlocFreeQuestion from "../Blocs/Author/freequestion.vue";
import BlocWrittenTrace from "../Blocs/Author/writtentrace.vue";
import BlocTrueFalse from "../Blocs/Author/truefalse.vue";

import BlocHtml from "../Blocs/Author/html.vue";
import BlocImage from "../Blocs/Author/image.vue";
import BlocCaptionImage from "../Blocs/Author/captionimage.vue";
import BlocQcm from "../Blocs/Author/qcm.vue";
import BlocDragAndDrop from "../Blocs/Author/dragAndDrop.vue";
import BlocAssociationsLinks from "../Blocs/Author/associationsLinks.vue";
import BlocTextWithGaps from "../Blocs/Author/textwithgaps.vue";
import BlocVideo from "../Blocs/Author/video.vue";

import BlocGenially from "../Blocs/Author/genially.vue";
import BlocTimeline from "../Blocs/Author/timeline.vue";
import BlocDiagram from "../Blocs/Author/diagram.vue";
import BlocRawHtml from "../Blocs/Author/rawhtml.vue";
import BlocSvgMap from "../Blocs/Author/svgMap.vue";

import BlocTimelinedrop from "../Blocs/Author/timelinedrop.vue";
import BlocCaptiondrop from "../Blocs/Author/captiondrop.vue";
import BlocTexttoorder from "../Blocs/Author/texttoorder.vue";
import BlocTabledrop from "../Blocs/Author/tabledrop.vue";

import BlocNoAnwserNeeded from "../Blocs/Author/noanswerneeded.vue";
//import StudentBlocQcm from "../Blocs/Student/qcm.vue";

export default {
  props: ["bloc"],
  components: {
    "bloc-audio": BlocAudio,
    "bloc-title": BlocTitle,
    "bloc-free-question": BlocFreeQuestion,
    "bloc-written-trace": BlocWrittenTrace,
    "bloc-true-false": BlocTrueFalse,
    "bloc-html": BlocHtml,
    "bloc-image": BlocImage,
    "bloc-genially": BlocGenially,
    "bloc-timeline": BlocTimeline,
    "bloc-diagram": BlocDiagram,
    "bloc-rawhtml": BlocRawHtml,
    "bloc-svgmap": BlocSvgMap,
    "bloc-caption-image": BlocCaptionImage,
    "bloc-qcm": BlocQcm,
    "bloc-text-with-gaps": BlocTextWithGaps,
    "bloc-video": BlocVideo,
    "bloc-dragAndDrop": BlocDragAndDrop,
    "bloc-associationsLinks": BlocAssociationsLinks,
    "bloc-no-anwser-needed": BlocNoAnwserNeeded,
    "bloc-timelinedrop": BlocTimelinedrop,
    "bloc-captiondrop": BlocCaptiondrop,
    "bloc-texttoorder": BlocTexttoorder,
    "bloc-tabledrop": BlocTabledrop
    /*"student-bloc-qcm": StudentBlocQcm*/
  },
  methods: {}
};
</script>
