<template>
  <v-app id="inspire">
    <v-main class="background">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-toolbar dark>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-toolbar-title>Bibliothèque d'images</v-toolbar-title>
        <v-spacer></v-spacer>
        <logout @logout="logout"></logout>
      </v-toolbar>
      <v-system-bar dark color="red" v-if="system_bar_message">
        <div class="network-error">{{ system_bar_message }}</div>
      </v-system-bar>
      <v-container darkclass="my-container">
        <UploadImages
          uploadMsg="Ajoutez vos images ici"
          clearAll=""
          @changed="handleImages"
        />
      <v-btn v-on:click="showFiles">Envoyer sur le serveur</v-btn>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Logout from "../Logout/Logout.vue";
import UploadImages from "vue-upload-drop-images";

export default {
  name: "ImagesLibrary",
  components: {
    Logout: Logout,
    UploadImages,
  },
  props: {},
  data: () => ({
    filesToUpload: [],
    overlay: false,
    system_bar_message: null,
  }),
  mounted() {},
  computed: {},
  methods: {
    logout: function () {
      this.$cookie.delete("user_id");
      this.$router.push("/");
    },
    showFiles:function() {
      
    },
    handleImages(files) {
      for (let i = 0; i < files.length; i++) {
        var reader = new FileReader();
        var that = this
        reader.readAsDataURL(files[i]);
        reader.onload = function () {
          that.filesToUpload.push({"datas":files[i], "content":reader.result})
        };
        reader.onerror = function (error) {
          console.log(error)
        };
      }
    },
  },
};
</script>

<style scoped>
.my-alert {
  margin: 0 24px 12px 24px;
}
.background {
  background-image: url("../../assets/images/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
h1 {
  color: white;
}
</style>