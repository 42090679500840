
<template>
  <v-app id="app">
    <v-main>
      <keep-alive :include="['Login']">
        <router-view v-slot="{ Component, route }">
          <transition :name="route.meta.transition || 'fade'">
            <component :is="Component" />
          </transition>
        </router-view>
      </keep-alive>
    </v-main>
  </v-app>
</template>


<script>
export default {
  name: "App",
  data() {
    return {
      drawer: false,
    };
  },
};
</script>

<style></style>
