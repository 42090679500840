<template>
  <div class="text-center">
    <v-bottom-sheet v-model="sheet" :retain-focus="retainFocusValue">
      <v-container>
        <v-row>
          <v-btn class="close-menu" fab small dark color="red" @click="closeBottomSheet()">
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
          <v-col cols="6">
            <v-list>
              <v-subheader>Ajouter un bloc de contenu</v-subheader>
              <v-list-item
                v-for="tile in editor_menu"
                :key="tile.title"
                v-on:click="goto(tile.emit)"
              >
                <v-list-item-avatar min-height="24px">
                  <v-avatar size="16px" tile>
                    <v-icon :disabled="tile.disabled" :color="tile.color">
                      {{
                      tile.icon
                      }}
                    </v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title>{{ tile.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6">
            <v-list>
              <v-subheader>Ajouter un exercice</v-subheader>
              <v-list-item
                v-for="tile in exercice_tiles1"
                :key="tile.title"
                v-on:click="goto(tile.emit)"
              >
                <v-list-item-avatar min-height="24px">
                  <v-avatar size="16px" tile>
                    <v-icon :disabled="tile.disabled" :color="tile.color">
                      {{
                      tile.icon
                      }}
                    </v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title>{{ tile.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  data() {
    return {
      retainFocusValue: new Boolean(false),
      editor_menu: [
        {
          icon: "mdi-text-box-outline",
          title: "Texte",
          color: "red",
          emit: "insert-html"
        },
        {
          icon: "mdi-format-title",
          title: "Intertitre",
          color: "orange",
          emit: "insert-title"
        },
        {
          icon: "mdi-file-video",
          title: "Vidéo",
          color: "green",
          emit: "insert-video"
        },
        {
          icon: "mdi-drawing-box",
          title: "Image",
          color: "blue",
          emit: "insert-image"
        },
        {
          icon: "mdi-image-album",
          title: "Image & étiquettes",
          color: "cyan",
          emit: "insert-caption-image"
        },
        {
          icon: "mdi-music-box",
          title: "Audio",
          color: "purple",
          emit: "insert-audio"
        },
        {
          icon: "mdi-drawing-box",
          title: "genial.ly",
          color: "black",
          emit: "insert-genially"
        },
        {
          icon: "mdi-chart-timeline",
          title: "Frise chronologique",
          color: "green",
          emit: "insert-timeline"
        },
        {
          icon: "mdi-arrow-down-bold-box",
          title: "Schéma fléché",
          color: "cyan",
          emit: "insert-diagram"
        },
        {
          icon: "mdi-language-html5",
          title: "HTML brut",
          color: "orange",
          emit: "insert-rawhtml"
        },
        {
          icon: "mdi-map",
          title: "Carte interactive",
          color: "yellow",
          emit: "insert-svgmap"
        }
      ],
      exercice_tiles1: [
        {
          icon: "mdi-cursor-pointer",
          title: "Drag and drop / tableaux à colonnes",
          color: "red",
          emit: "insert-drag-and-drop"
        },
        {
          icon: "mdi-call-made",
          title: "Associations fléchées",
          color: "purple",
          emit: "insert-associations-links"
        },
        {
          icon: "mdi-checkbox-multiple-marked-circle",
          title: "QCM",
          color: "red",
          emit: "insert-qcm"
        },
        {
          icon: "mdi-comment",
          title: "Question libre",
          color: "blue",
          emit: "insert-free-question"
        },
        {
          icon: "mdi-note-text",
          title: "Texte à compléter",
          color: "purple",
          emit: "insert-text-with-gaps"
        },
        {
          icon: "mdi-comment-account",
          title: "Trace écrite",
          color: "blue",
          emit: "insert-written-trace"
        },
        {
          icon: "mdi-compare",
          title: "Vrai ou faux",
          color: "brown",
          emit: "insert-true-false"
        },
        {
          icon: "mdi-comment-question-outline",
          title: "Exercice sans réponse demandée",
          color: "green",
          emit: "insert-no-answer-needed"
        },
        {
          icon: "mdi-image-area-close",
          title: "Etiquettes à placer sur une image",
          color: "orange",
          emit: "insert-caption-image-drop"
        },
        {
          icon: "mdi-chart-timeline",
          title: "Frise chronologique interactive",
          color: "cyan",
          emit: "insert-timeline-drop"
        },
        {
          icon: "mdi-table",
          title: "Tableau interactif à compléter",
          color: "red",
          emit: "insert-table-drop"
        },
        {
          icon: "mdi-sort",
          title: "Texte à remettre en ordre",
          color: "blue",
          emit: "insert-text-to-order"
        }
      ],
      exercice_tiles2: []
    };
  },
  methods: {
    goto: function(item) {
      this.closeBottomSheet();
      if (item) {
        this.$emit(item);
      }
    },
    closeBottomSheet: function() {
      this.$emit("close-bottom-sheet");
    }
  },
  props: ["sheet"]
};
</script>

<style scoped>
.close-menu {
  position: absolute;
  right: 16px;
  bottom: 16px;
  cursor: pointer;
}
</style>