<template>
  <div class="image">
    <!-- DEBUT CONSIGNE -->
    <div v-if="bloc.settings.infos">
      <div v-if="bloc.settings.infos.level_1">
        <em
          v-if="bloc.settings.infos.level_1.consigne"
          class="consigne"
          v-html="bloc.settings.infos.level_1.consigne"
        ></em>
      </div>
    </div>
    <!-- FIN CONSIGNE -->
    <v-card-title v-html="bloc.name"></v-card-title>
    <v-row>
      <v-col v-if="bloc.content.level_1.url" cols="12">
        <div
          id="level_1_wrapper"
          class="wrapper"
          :style="{
            backgroundImage: `url(${bloc.content.level_1.url})`,
            aspectRatio: bloc.content.level_1.image_settings.ratio,
          }"
        >
          <div
            class="drag-it-dude"
            v-for="(item, key) in bloc.content.level_1.items"
            :key="item.id"
            :id="'item-level_1-' + key"
            :class="item.position"
            :style="{ left: item.percentLeft, top: item.percentTop }"
          >
            <div class="caption">
              <div class="caption-index">{{ key + 1 }}</div>
              <div class="caption-label">{{ item.caption }}</div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-card-text v-if="bloc.content.level_1.description">
      <div>
        <em>{{ bloc.content.level_1.description }}</em>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: ["bloc"],
};
</script>

<style scoped>
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.img-layout img {
  width: 100%;
  height: auto;
}
.wrapper {
  width: 1000px;
  height: auto;
  min-height: 200px;
  background-size: cover;
  position: relative;
  max-width: 1127px;
}
.wrapper img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.centered-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}
</style>