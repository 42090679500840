<template>
  <v-dialog
    v-model="capacities_modal"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-toolbar dark color="black">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Capacités</v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-container>
        <v-form>
          <v-col cols="12">
            <h4>Capacités</h4>
          </v-col>
          <v-row class="pt-1">
            <v-col cols="12" v-if="current_item.capacities">
              <v-autocomplete
                v-model="current_item.capacities"
                :items="skills"
                :filter="customFilter"
                item-text="label"
                chips
                multiple
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pt-3">
            <v-col cols="12">
              <h4>Tags (mots-clés)</h4>
            </v-col>
            <v-col cols="12">
              <v-card color="basil">
                <v-tabs v-model="tag_tabs" background-color="transparent" color="basil" grow>
                  <v-tab
                    v-for="(tag, tag_index) in current_item.tags"
                    :key="tag_index"
                  >{{ tag.label }}</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tag_tabs">
                  <v-tab-item v-for="(tag, tag_index) in current_item.tags" :key="tag_index">
                    <v-card color="basil" flat>
                      <v-card-text>
                        <v-combobox :label="tag.label" v-model="tag.words" multiple chips></v-combobox>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      tag_tabs: true,
      skills: this.$store.getters.skills
    };
  },
  props: ["current_item", "capacities_modal"],
  watch: {
    model(val) {
      if (val.length > 5) {
        this.$nextTick(() => this.model.pop());
      }
    }
  },
  methods: {
    cancel() {
      this.$emit("close-capacities");
    },
    customFilter(item, queryText) {
      const textOne = item.label.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    }
  }
};
</script>

<style>
</style>