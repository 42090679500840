<template>
  <div class="image">
    <v-card-title v-html="bloc.name"></v-card-title>
    <v-card-text>
      <div :style="bloc.content.level_1.style">
        <iframe
          scrolling="no"
          :style="bloc.content.level_1.style"
          :src="bloc.content.level_1.url"
          frameborder="0"
        ></iframe>
      </div>

      <div v-html="bloc.content.level_1.rawhtml"></div>
    </v-card-text>²
  </div>
</template>

<script>
export default {
  props: ["bloc"]
};
</script>
