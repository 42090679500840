<template>
  <v-app-bar dark>
    <template v-if="this.$store.state.current_section">
      <h1 class="infos_section">{{this.$store.state.current_section.chapter.label}}</h1>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style scoped>
.v-toolbar__title.toolbar-title {
  font-size: 30px;
  font-weight: 900;
}
.v-select__slot {
  font-size: 22px;
  font-weight: 700;
}
.v-select__selections {
  line-height: normal;
}

h1.infos_section {
  font-size: 0.9rem;
  display: block;
  width: 100%;
  text-align: right;
  margin: 0 16px 0 0;
}

h2.infos_section {
  font-size: 0.7rem;
  display: block;
  width: 100%;
}
</style>