<template>
  <div>
    <v-dialog v-model="show_hide" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-toolbar dark color="black">
        <v-btn icon dark @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Associations fléchées</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-container v-if="bloc">
          <v-textarea
            v-model="bloc.name"
            filled
            label="Titre du bloc de texte"
            auto-grow
            rows="1"
            background-color="blue-grey lighten-4"
          ></v-textarea>
          <v-tabs v-model="tab" background-color="blue" color="basil" grow>
            <v-tab v-for="item in levels" :key="item">{{ item }}</v-tab>
            <v-tab class="orange">
              <v-icon small color="white">mdi-settings-pencil</v-icon>&nbsp;Paramètres
            </v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-textarea
                v-model="bloc.settings.infos.level_1.consigne"
                outlined
                label="Consigne de l'exercice"
                auto-grow
                rows="2"
              ></v-textarea>
              <v-card v-if="bloc.content.level_1" color="basil" flat>
                <!-- <v-textarea
                          v-model="bloc.content.level_1.title"
                          filled
                          label="Titre du bloc de texte"
                          auto-grow
                          rows="1"
                          background-color="blue-grey lighten-4"
                ></v-textarea>-->
                <div class="scrollZone">
                  <div
                    class="pa-2 Exercicecolonne"
                    v-for="(subitem, subindex) in bloc.content.level_1"
                    :key="subindex"
                  >
                    <v-card v-if="subitem" class="pa-2" shaped style="width: 100%">
                      <v-row>
                        <v-col cols="12" class="col-Butons">
                          <v-btn
                            class="ma-2"
                            text
                            icon
                            color="red lighten-2"
                            v-on:click="
                            deleteQuestion(bloc.content.level_1, subindex)
                          "
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            text
                            icon
                            color="red lighten-2"
                            v-on:click="showCapacities(bloc.content.level_1, subindex)"
                          >
                            <v-icon>mdi-tag-outline</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            text
                            icon
                            color="red lighten-2"
                            v-on:click="addAnswer(subitem.answers)"
                          >
                            <v-icon>mdi-comment-plus-outline</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model="subitem.question"
                            filled
                            label="Titre de la colonne"
                            auto-grow
                            rows="1"
                            prepend-inner-icon="mdi-comment"
                            background-color="blue-grey lighten-4"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row v-for="(answer, answer_key) in subitem.answers" :key="answer_key">
                        <v-col cols="11">
                          <v-text-field v-model="answer.answer" label="Proposition"></v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-btn
                            text
                            icon
                            x-small
                            color="red lighten-2"
                            v-on:click="deleteAnswer(bloc.content.level_1[subindex].answers,answer_key)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </div>
                <v-row>
                  <v-col
                    v-on:click="addQuestion(bloc.content.level_1)"
                    cols="12"
                    class="text-center"
                  >
                    <v-btn class="ma-2" color="primary" dark>
                      Ajouter une colonne
                      <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <bloc-corriges
                  :title="'Corrigés'"
                  v-bind:bloc="bloc"
                  v-bind:element="bloc.settings.infos.level_1"
                  v-bind:level="'level_1'"
                  :eleve="true"
                  :professeur="true"
                ></bloc-corriges>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-textarea
                v-model="bloc.settings.infos.level_2.consigne"
                outlined
                label="Consigne de l'exercice"
                auto-grow
                rows="2"
              ></v-textarea>
              <v-card v-if="bloc.content.level_2" color="basil" flat>
                <!-- <v-textarea
                v-model="bloc.content.level_2.title"
                filled
                label="Titre du bloc de texte"
                auto-grow
                rows="1"
                background-color="blue-grey lighten-4"
                ></v-textarea>-->
                <div class="scrollZone">
                  <div
                    class="pa-2 Exercicecolonne"
                    v-for="(subitem, subindex) in bloc.content.level_2"
                    :key="subindex"
                  >
                    <v-card v-if="subitem" class="pa-2" shaped style="width: 100%">
                      <v-row>
                        <v-col cols="12" class="col-Butons">
                          <v-btn
                            class="ma-2"
                            text
                            icon
                            color="red lighten-2"
                            v-on:click="
                  deleteQuestion(bloc.content.level_2, subindex)
                "
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            text
                            icon
                            color="red lighten-2"
                            v-on:click="showCapacities(bloc.content.level_2, subindex)"
                          >
                            <v-icon>mdi-tag-outline</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            text
                            icon
                            color="red lighten-2"
                            v-on:click="addAnswer(subitem.answers)"
                          >
                            <v-icon>mdi-comment-plus-outline</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model="subitem.question"
                            filled
                            label="Titre de la colonne"
                            auto-grow
                            rows="1"
                            prepend-inner-icon="mdi-comment"
                            background-color="blue-grey lighten-4"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row v-for="(answer, answer_key) in subitem.answers" :key="answer_key">
                        <v-col cols="11">
                          <v-text-field v-model="answer.answer" label="Proposition"></v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-btn
                            text
                            icon
                            x-small
                            color="red lighten-2"
                            v-on:click="deleteAnswer(bloc.content.level_2[subindex].answers,answer_key)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </div>
                <v-row>
                  <v-col
                    v-on:click="addQuestion(bloc.content.level_2)"
                    cols="12"
                    class="text-center"
                  >
                    <v-btn class="ma-2" color="primary" dark>
                      Ajouter une colonne
                      <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <bloc-corriges
                  :title="'Corrigés'"
                  v-bind:bloc="bloc"
                  v-bind:element="bloc.settings.infos.level_2"
                  v-bind:level="'level_2'"
                  :eleve="true"
                  :professeur="true"
                ></bloc-corriges>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-textarea
                v-model="bloc.settings.infos.level_3.consigne"
                outlined
                label="Consigne de l'exercice"
                auto-grow
                rows="2"
              ></v-textarea>
              <v-card v-if="bloc.content.level_3" color="basil" flat>
                <!-- <v-textarea
                v-model="bloc.content.level_3.title"
                filled
                label="Titre du bloc de texte"
                auto-grow
                rows="1"
                background-color="blue-grey lighten-4"
                ></v-textarea>-->
                <div class="scrollZone">
                  <div
                    class="pa-2 Exercicecolonne"
                    v-for="(subitem, subindex) in bloc.content.level_3"
                    :key="subindex"
                  >
                    <v-card v-if="subitem" class="pa-2" shaped style="width: 100%">
                      <v-row>
                        <v-col cols="12" class="col-Butons">
                          <v-btn
                            class="ma-2"
                            text
                            icon
                            color="red lighten-2"
                            v-on:click="
                  deleteQuestion(bloc.content.level_3, subindex)
                "
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            text
                            icon
                            color="red lighten-2"
                            v-on:click="showCapacities(bloc.content.level_3, subindex)"
                          >
                            <v-icon>mdi-tag-outline</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            text
                            icon
                            color="red lighten-2"
                            v-on:click="addAnswer(subitem.answers)"
                          >
                            <v-icon>mdi-comment-plus-outline</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model="subitem.question"
                            filled
                            label="Titre de la colonne"
                            auto-grow
                            rows="1"
                            prepend-inner-icon="mdi-comment"
                            background-color="blue-grey lighten-4"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row v-for="(answer, answer_key) in subitem.answers" :key="answer_key">
                        <v-col cols="11">
                          <v-text-field v-model="answer.answer" label="Proposition"></v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-btn
                            text
                            icon
                            x-small
                            color="red lighten-2"
                            v-on:click="deleteAnswer(bloc.content.level_3[subindex].answers,answer_key)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </div>
                <v-row>
                  <v-col
                    v-on:click="addQuestion(bloc.content.level_3)"
                    cols="12"
                    class="text-center"
                  >
                    <v-btn class="ma-2" color="primary" dark>
                      Ajouter une colonne
                      <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <bloc-corriges
                  :title="'Corrigés'"
                  v-bind:bloc="bloc"
                  v-bind:element="bloc.settings.infos.level_3"
                  v-bind:level="'level_3'"
                  :eleve="true"
                  :professeur="true"
                ></bloc-corriges>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <bloc-settings v-bind:bloc="bloc"></bloc-settings>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
        <v-card-actions class="bottom">
          <v-spacer></v-spacer>
          <v-btn outlined color="red darken-1" text @click="cancel">Annuler</v-btn>
          <v-btn outlined class="mr-2" text color="green darken-1" @click="save">Enregistrer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <bloc-capacities
      v-bind:current_item="current_item"
      v-bind:capacities_modal="capacities_modal"
      @close-capacities="closeCapacities"
    ></bloc-capacities>
  </div>
</template>
<script>
import BlocSettings from "../../Editors/blocSettings/BlocSettings.vue";
import BlocCapacities from "../../Editors/BlocCapacities/BlocCapacities.vue";
import BlocCorriges from "../../Shared/BlocCorriges.vue";

export default {
  components: {
    "bloc-settings": BlocSettings,
    "bloc-capacities": BlocCapacities,
    "bloc-corriges": BlocCorriges
  },
  data() {
    return {
      current_item: { capacities: null, tags: null },
      capacities_modal: false,
      tab: null,
      levels: this.$store.getters.levels
      // title_extensions: [Bold, Italic],
      // titrePage: 'titre'
    };
  },
  props: ["show_hide", "bloc"],
  methods: {
    cancel() {
      this.$emit("close");
    },
    save() {
      console.log("this.bloc", this.bloc);
      const payload = this.bloc;
      this.$emit("save", payload);
    },
    addAnswer(place) {
      place.push({ valid: null, answer: "" });
    },
    addQuestion(place) {
      place.push({ question: "", answers: [{ answer: "", valid: null }] });
    },
    deleteQuestion(place, index) {
      place.splice(index, 1);
    },
    deleteAnswer(place, index) {
      place.splice(index, 1);
    },
    showCapacities(place, index) {
      if (
        place[index].settings === undefined ||
        place[index].settings == null
      ) {
        place[index].settings = {
          capacities: [],
          tags: this.$store.getters.tags
        };
      }
      this.current_item = place[index].settings;
      this.capacities_modal = true;
    },
    closeCapacities() {
      this.capacities_modal = false;
    }
  }
};
</script>
<style scoped>
.orange {
  background-color: orange;
}

.scrollZone {
  display: flex;
  overflow-x: auto;
}
.Exercicecolonne {
  min-width: 350px;
  /* display: inline-block; */
  /* display:table-cell; */
}
.col-Butons {
  text-align: right;
  padding: 0;
}
</style>