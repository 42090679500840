<template>
  <v-app id="inspire">
    <v-main class="background">
      <v-overlay :value="overlay">
        <v-progress-circular color="white" indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-toolbar dark>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-toolbar-title>Edition du manuel scolaire</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="dialog_recycle=true" v-bind="attrs" v-on="on" icon>
              <v-icon color="white">mdi-recycle</v-icon>
            </v-btn>
          </template>
          <span>Effacer les données locales</span>
        </v-tooltip>
      </v-toolbar>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark>
                <v-toolbar-title>Connexion à l'espace auteurs</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    id="email"
                    name="email"
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                    placeholder="Votre e-mail"
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    name="password"
                    label="Votre mot de passe"
                    v-model="password"
                    :rules="passwordRules"
                    required
                    type="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <div v-if="error">
                <v-alert dense border="left" type="warning" class="my-alert">{{ error }}</v-alert>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  @click="validate"
                >Se connecter</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
        <v-dialog v-model="dialog_recycle" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5">Effacer les données ?</v-card-title>
            <v-card-text>
              Vous supprimerez tout le contenu stocké sur votre poste de travail qui n'aurait pas été envoyé en ligne.
              <br />N'utilisez cette action que si vous rencontrez des difficultés lors de l'envoi de contenu en ligne.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                class="px-4"
                color="red darken-1"
                text
                @click="dialog_recycle = false"
              >Annuler</v-btn>
              <v-btn depressed class="px-4" color="green darken-1" text @click="recycle">Valider</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  props: {
    source: String
  },
  data: () => ({
    dialog_recycle: false,
    valid: true,
    error: null,
    overlay: false,
    password: "",
    passwordRules: [v => !!v || "Veuillez saisir votre mot de passe"],
    email: "",
    emailRules: [
      v => !!v || "Veuillez saisir votre e-mail",
      v => /.+@.+\..+/.test(v) || "E-mail non valide"
    ]
  }),
  updated() {
    //this.overlay = false;
  },
  mounted() {
    this.checkAuthentification();
  },
  methods: {
    checkAuthentification: function() {
      const cookie = this.$cookie.get("user_id");
      if (cookie) {
        this.$router.push("/main");
      }
    },
    recycle() {
      this.overlay = true;
      this.overlay = false;
      localStorage.clear();
      this.$router.go();
    },
    validate: async function() {
      this.overlay = true;
      const username = this.email;
      const password = this.password;
      const url = this.$store.getters.baseApi + "api/login";
      this.error = null;
      try {
        const res = await axios
          .post(url, {
            username: username,
            password: password
          })
          .then(res => res.data)
          .finally(() => {
            this.overlay = false;
          });
        if (res.roles) {
          this.$store.dispatch("setUserDatas", res);
          if (res.roles.includes("ROLE_AUTHOR")) {
            this.$cookie.set("user_id", res.id);
            this.$cookie.set("user_uuid", res.uuid);
            this.$router.push("/manuels");
          }
        }
      } catch (err) {
        this.overlay = false;
        this.error = err.message;
      }
    }
  }
};
</script>

<style scoped>
.my-alert {
  margin: 0 24px 12px 24px;
}
.background {
  background-image: url("../../assets/images/background.jpg");
  background-size: cover;
  background-repeat: round;
}
.container.fill-height {
  max-height: 90vh;
}
</style>