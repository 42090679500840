<template>
  <div>
    <v-card-title v-html="bloc.name"></v-card-title>
    <v-card-text v-if="bloc.content.level_1">
      <div class="column" v-for="(question, question_index) in bloc.content.level_1" v-bind:key="question_index">
        <!-- DEBUT CONSIGNE -->
        <div v-if="bloc.settings.infos">
          <div v-if="bloc.settings.infos.level_1" >
            <em
              v-if="bloc.settings.infos.level_1.consigne"
              class="consigne"
            ></em>
          </div>
        </div>
        <!-- FIN CONSIGNE -->
        <h3>{{ question.question }}</h3>
        <ul v-for="(answer, answer_index) in question.answers" v-bind:key="answer_index">
          <li>{{ answer.answer }}</li>
        </ul>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: ["bloc"]
};
</script>

<style scoped>
.column {
  width: 320px;
  display: inline-block;
}
.column ul {
  height: 60px;
}

</style>