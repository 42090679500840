import axios from "axios";
import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

/*
let base_api = 'https://pds-api.netfreelance.fr/';
let base_medias = 'https://pds-medias-images.s3.eu-west-3.amazonaws.com/';
*/

let base_api = "https://apis.lecrandusavoir.fr/";
let base_medias =
  "https://elasticbeanstalk-eu-west-3-469937184891.s3.eu-west-3.amazonaws.com/";
let base_url_manuel = "https://histoire2de.lecrandusavoir.fr/";

if (process.env.NODE_ENV == "development") {
  base_api = "http://localhost:8000/";
  base_url_manuel = "http://localhost:8080/";
}

const state = {
  user: null,
  base_api: base_api,
  base_url_manuel: base_url_manuel,
  base_medias: base_medias,
  tinymce_key: "w18z3aavvn0nip4541oe8ds9ndpkb18asggi1s65i22pynjt",
  user_textbooks: null,
  textbook_summary: null,
  current_section: null,
  section_last_update: {},
  medias: null,
  levels: ["Doc. intermédiaire", "Doc. novice", "Doc. expert"],
  tags: {
    notions: {
      label: "Notions",
      words: "",
    },
    vocabulaire: {
      label: "Connaissances vocabulaire",
      words: "",
    },
    dates: {
      label: "Connaissances dates",
      words: "",
    },
    acteurs: {
      label: "Connaissances acteurs",
      words: "",
    },
    lieux: {
      label: "Connaissances lieux",
      words: "",
    },
  },
  blocs_descriptions: {
    HTML: "Texte",
    TITLE: "Intertitre",
    VIDEO: "Vidéo",
    AUDIO: "Audio",
    IMAGE: "Image",
    FREEQUESTION: "Question",
    QCM: "QCM",
    TEXTWITHGAPS: "Texte à compléter",
    ASSOCIATIONSLINKS: "Associations fléchées",
    WRITTENTRACE: "Trace écrite",
    TRUEFALSE: "Vrai ou faux",
    CAPTION: "Image avec étiquettes",
    GENIALLY: "Genially",
    TIMELINE: "Frise chronologique",
    DIAGRAM: "Diagramme",
    RAWHTML: "HTML brut",
    SVGMAP: "Carte interactive",
    NOANSWERNEEDED: "Réponse non demandée",
    TIMELINEDROP: "Frise chronologique interactive",
    CAPTIONDROP: "Etiquettes à placer sur une image",
    TEXTTOORDER: "Texte à remettre en ordre",
    TABLEDROP: "Tableau interactif à compléter",
  },
  subsection_template: {
    title: "",
    name: "",
    display: "",
    ppo: "",
    problematique: "",
    visibility: "visible",
    id: null,
    index: null,
    blocs: [],
    uuid: null,
    display_title: true,
  },
  bloc_template: {
    id: null,
    kind: null,
    name: null,
    status: null,
    content: {
      level_1: {
        html: null,
        url: null,
        description: null,
        items: [],
      },
      level_2: {
        html: null,
        url: null,
        description: null,
        items: [],
      },
      level_3: {
        html: null,
        url: null,
        description: null,
        items: [],
      },
    },
    comments: "[]",
    position: null,
    settings: {
      cols: 12,
      classes: null,
      capacities: null,
      tags: null,
      level: 5,
      infos: {
        level_1: {
          consigne: "",
          corrige_eleve: "",
          corrige_professeur: "",
        },
        level_2: {
          consigne: "",
          corrige_eleve: "",
          corrige_professeur: "",
        },
        level_3: {
          consigne: "",
          corrige_eleve: "",
          corrige_professeur: "",
        },
      },
    },
    date_update: null,
    subsection_id: null,
  },
  skills: [
    {
      id: "1",
      label:
        "Identifier et nommer les périodes historiques, les continuités et ruptures chronologiques",
      skill_id: "1",
    },
    {
      id: "2",
      label:
        "Identifier et expliciter les dates et acteurs clés des grands événements",
      skill_id: "1",
    },
    {
      id: "3",
      label:
        "Nommer et localiser les grands repères géographiques ainsi que les principaux processus et phénomènes étudiés",
      skill_id: "1",
    },
    {
      id: "4",
      label: "Utiliser l’échelle appropriée pour étudier un phénomène",
      skill_id: "1",
    },
    {
      id: "5",
      label: "Mettre un événement ou une figure en perspective",
      skill_id: "2",
    },
    {
      id: "6",
      label:
        "Mettre en œuvre le changement d’échelles, ou l’analyse à différentes échelles (multiscalaire), en géographie",
      skill_id: "2",
    },
    {
      id: "7",
      label:
        "Identifier les contraintes et les ressources d’un événement, d’un contexte historique, d’une situation géographique",
      skill_id: "2",
    },
    {
      id: "8",
      label:
        "Mettre en relation des faits ou événements de natures, de périodes, de localisations différentes",
      skill_id: "2",
    },
    {
      id: "9",
      label:
        "Confronter le savoir acquis en histoire et en géographie avec ce qui est entendu, lu et vécu",
      skill_id: "2",
    },
    {
      id: "10",
      label:
        "Employer les notions et le lexique acquis en histoire et en géographie à bon escient",
      skill_id: "3",
    },
    {
      id: "11",
      label: "Transposer un texte en croquis",
      skill_id: "3",
    },
    {
      id: "12",
      label:
        "Réaliser des productions graphiques et cartographiques dans le cadre d’une analyse",
      skill_id: "3",
    },
    {
      id: "13",
      label:
        "Savoir lire, comprendre et apprécier une carte, un croquis, un document iconographique, une série statistique...",
      skill_id: "3",
    },
    {
      id: "14",
      label: "S’approprier un questionnement historique et géographique",
      skill_id: "4",
    },
    {
      id: "15",
      label:
        "Construire et vérifier des hypothèses sur une situation historique ou géographique",
      skill_id: "4",
    },
    {
      id: "16",
      label: "Justifier des choix, une interprétation, une production",
      skill_id: "4",
    },
    {
      id: "17",
      label:
        "Procéder à l’analyse critique d’un document selon une approche historique ou géographique",
      skill_id: "5",
    },
    {
      id: "18",
      label:
        "Utiliser une approche historique ou géographique pour mener une analyse ou construire une argumentation",
      skill_id: "5",
    },
    {
      id: "19",
      label:
        "Utiliser le numérique pour réaliser des cartes, des graphiques, des présentations",
      skill_id: "6",
    },
    {
      id: "20",
      label:
        "Identifier et évaluer les ressources pertinentes en histoiregéographie",
      skill_id: "6",
    },
  ],
  tynimce_reduced_config: {
    selector: "textarea",
    statusbar: false,
    menubar: false,
    height: 100,
    plugins: "link code powerpaste",
    paste_as_text: true,
    toolbar: "bold italic superscript subscript | link",
    setup: function(editor) {
      editor.ui.registry.addButton("InternalLink", {
        text: "Lien interne",
        onAction: function() {
          editor.insertContent(
            "<a class='internal-link' href='#'>Lien interne à définir</a>"
          );
        },
      });
    },
  },
};

const getters = {
  tinymceKey: (state) => {
    return state.tinymce_key;
  },
  tynimceReducedConfig: (state) => {
    return state.tynimce_reduced_config;
  },
  baseApi: (state) => {
    return state.base_api;
  },
  baseUrlManuel: (state) => {
    return state.base_url_manuel;
  },
  baseMedias: (state) => {
    return state.base_medias;
  },
  currentSection: (state) => {
    return state.current_section;
  },
  textbookSummary: (state) => {
    return state.textbook_summary;
  },
  subsectionTemplate: (state) => {
    return state.subsection_template;
  },
  blocTemplate: (state) => {
    return state.bloc_template;
  },
  levels: (state) => {
    return state.levels;
  },
  skills: (state) => {
    return state.skills;
  },
  tags: (state) => {
    return state.tags;
  },
  blocsDescription: (state) => {
    return state.blocs_descriptions;
  },
  user: (state) => {
    return state.user;
  },
  medias: (state) => {
    return state.medias;
  },
  sectionLastUpdate: (state) => {
    return state.section_last_update;
  },
};

const actions = {
  /* LISTE DES MEDIAS */
  getMedias({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(base_api + "textbook/api/get_medias", payload).then(
        (response) => {
          commit("SET_MEDIAS", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  /* RECUPERATION DISTANTE DE LA DERNIERE DATE DE MAJ DE BLOCS  */
  getSectionLastUpdate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(base_api + "textbook/api/get_section_last_update", payload)
        .then(
          (response) => {
            commit("SET_SECTION_LAST_UPDATE", response.data);
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  },

  /* RECUPERATION DISTANTE DE LA SECTION A EDITER */
  getSection({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(base_api + "textbook/api/get_section_blocs", payload).then(
        (response) => {
          commit("SET_CURRENT_SECTION", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  /* LISTE DES MANUELS AUTORISES A L'UTILISATEUR */
  getUserTextbooks({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(base_api + "textbook/api/get_user_textbooks", payload).then(
        (response) => {
          commit("SET_USER_TEXTBOOKS", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  /* SOMMAIRE DU MANUEL */
  getTextbookSummary({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(base_api + "textbook/api/get_textbook_summary", payload).then(
        (response) => {
          commit("SET_TEXTBOOK_SUMMARY", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  /* AJOUT DE SECTION */
  setNewSection({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(base_api + "textbook/api/set_new_section", payload).then(
        (response) => {
          commit("SET_CURRENT_SECTION", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  /* TRI DES SECTIONS D'UN CHAPTER */
  setSectionsOrder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(base_api + "textbook/api/set_sections_order", payload).then(
        (response) => {
          commit("SET_TEXTBOOK_SUMMARY", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  /* SAUVEGARDE DE L'HISTORIQUE ET DES SUBSECTIONS/BLOCS */
  saveHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(base_api + "textbook/api/save_history", payload).then(
        (response) => {
          commit("SET_NOTHING", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  /* SUPPRESSION DE SECTION */
  deleteSection({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(base_api + "textbook/api/delete_section", payload).then(
        (response) => {
          commit("SET_NOTHING", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  /* MISE A JOUR DU NOM/LABEL DE LA SECTION */
  updateSection({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(base_api + "textbook/api/update_section", payload).then(
        (response) => {
          commit("SET_NOTHING", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  /* UTILISATEUR  */
  setUserDatas({ commit }, payload) {
    commit("SET_USER_DATAS", payload);
  },
};

const mutations = {
  SET_USER_TEXTBOOKS(state, user_textbooks) {
    state.user_textbooks = user_textbooks;
  },
  SET_TEXTBOOK_SUMMARY(state, summary) {
    state.textbook_summary = summary;
  },
  SET_CURRENT_SECTION(state, section) {
    state.current_section = section;
  },
  SET_NOTHING(state, section) {
    console.log(state, section);
  },
  SET_USER_DATAS(state, payload) {
    state.user = payload;
  },
  SET_MEDIAS(state, payload) {
    state.medias = payload;
  },
  SET_SECTION_LAST_UPDATE(state, payload) {
    state.section_last_update = payload;
  },
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
});
