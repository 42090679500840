<template>
  <v-dialog v-model="show_hide" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-toolbar dark color="black">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title v-html="bloc.name" v-if="bloc"></v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-container v-if="bloc">
        <span class="title">Titre du bloc image</span>
        <tiptap-vuetify
          class="vuetify-for-title"
          v-model="bloc.name"
          :extensions="title_extensions"
        />
        <v-divider></v-divider>
        <v-tabs v-model="tab" background-color="blue" color="basil" grow>
          <v-tab v-for="item in levels" :key="item">{{ item }}</v-tab>
          <v-tab class="orange">
            <v-icon small color="white">mdi-settings-pencil</v-icon>&nbsp;Paramètres
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card color="basil" flat>
              <v-row>
                <v-col cols="3">
                  <div class="img-layout">
                    <img
                      id="level_1_img"
                      ref="level_1_img"
                      v-if="bloc.content.level_1.url"
                      :src="bloc.content.level_1.url"
                      alt
                    />
                  </div>
                  <div class="img-infos" v-if="bloc.content.level_1.url">
                    <div>
                      <v-btn
                        depressed
                        x-small
                        @click="reinitItems('level_1')"
                        color="error"
                      >Réinitialiser les positions des étiquettes</v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="flex">
                    <v-text-field
                      label="URL de l'image"
                      prepend-inner-icon="mdi-file-image"
                      v-model="bloc.content.level_1.url"
                      id="v-text-field-for-image"
                    ></v-text-field>
                    <v-btn
                      @click="showMediasLibrary(bloc.content.level_1)"
                      class="mx-2"
                      dark
                      color="primary"
                    >
                      <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp;
                      Bibliothèque
                    </v-btn>
                  </div>
                  <div>
                    <v-text-field
                      label="Ratio largeur/hauteur de l'image"
                      prepend-inner-icon="mdi-file-image"
                      v-model="bloc.content.level_1.image_settings.ratio"
                      id="v-text-field-for-image"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col
                  v-if="bloc.content.level_1.url"
                  cols="12"
                  id="level_1_supwrapper"
                  ref="level_1_supwrapper"
                >
                  <div
                    id="level_1_wrapper"
                    ref="level_1_wrapper"
                    class="wrapper"
                    :style="{
                      aspectRatio: bloc.content.level_1.image_settings.ratio,
                      backgroundImage: `url(${bloc.content.level_1.url})`,
                    }"
                    @click="forceUpdate"
                  >
                    <drag-it-dude
                      v-for="(item, key) in bloc.content.level_1.items"
                      :key="item.id"
                      :id="'item_level_1_' + key"
                      :ref="'item_level_1_' + key"
                      :class="item.position"
                      :style="{ left: item.percentLeft, top: item.percentTop }"
                    >
                      <div class="caption">
                        <div class="caption-index">{{ key + 1 }}</div>
                        <div class="caption-label">{{ item.caption }}</div>
                      </div>
                    </drag-it-dude>
                  </div>
                </v-col>
                <v-col v-if="bloc.content.level_1.url" cols="12" class="text-center">
                  <v-btn
                    v-on:click="
                      addCaption('level_1', bloc.content.level_1.items)
                    "
                    class="ma-2"
                    color="primary"
                    dark
                  >
                    Ajouter une étiquette
                    <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <div v-for="(subitem, subindex) in bloc.content.level_1.items" :key="subindex">
                <v-card v-if="subitem" class="pa-2" shaped style="width: 100%">
                  <v-row>
                    <v-col cols="1" class="centered-flex">
                      <div class="index">{{ subindex + 1 }}</div>
                    </v-col>
                    <v-col cols="5">
                      <v-textarea
                        v-model="subitem.caption"
                        filled
                        label="Etiquette"
                        auto-grow
                        rows="1"
                        @change="forceUpdate"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        :items="positions"
                        filled
                        v-model="subitem.position"
                        label="Position"
                        @change="forceUpdate"
                      ></v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-switch v-model="subitem.hidden" label="Masqué"></v-switch>
                    </v-col>
                    <v-col cols="2" class="centered-flex">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="red lighten-2"
                        v-on:click="
                          deleteCaption(bloc.content.level_1.items, subindex)
                        "
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="subitem.image">
                    <v-col cols="1" class="centered-flex">
                      <div class="img-layout">
                        <img v-if="subitem.image && subitem.image.url" :src="subitem.image.url" alt />
                      </div>
                    </v-col>
                    <v-col cols="11">
                      <div class="flex">
                        <v-text-field
                          label="URL de l'image"
                          prepend-inner-icon="mdi-file-image"
                          v-model="subitem.image.url"
                          id="v-text-field-for-image"
                        ></v-text-field>
                        <v-btn
                          @click="showMediasLibrary(subitem.image)"
                          class="mx-2"
                          dark
                          color="primary"
                        >
                          <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp;
                          Bibliothèque
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <div class="flex">
                        <v-text-field label="Libellé du lien" v-model="subitem.link_label"></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="flex">
                        <v-text-field
                          label="URL du lien"
                          prepend-inner-icon="mdi-link-variant"
                          v-model="subitem.link_url"
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </div>

              <v-row>
                <v-col cols="7">
                  <v-textarea
                    v-model="bloc.content.level_1.credits"
                    filled
                    label="Crédits de l'image"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
                <v-col cols="5">
                  <v-textarea
                    v-model="bloc.content.level_1.credits_more"
                    filled
                    label="Précision sur les crédits (non affiché)"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-textarea
                v-model="bloc.content.level_1.description"
                filled
                label="Description de l'image (optionnel)"
                auto-grow
                rows="2"
              ></v-textarea>
              <v-textarea
                v-model="bloc.content.level_1.source"
                filled
                label="Légende du document"
                auto-grow
                row
                rows="1"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_1"
                v-bind:level="'level_1'"
                :eleve="false"
                :professeur="true"
                :exercice="false"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card color="basil" flat>
              <v-row>
                <v-col cols="3">
                  <div class="img-layout">
                    <img
                      id="level_2_img"
                      ref="level_2_img"
                      v-if="bloc.content.level_2.url"
                      :src="bloc.content.level_2.url"
                      alt
                    />
                  </div>
                  <div class="img-infos" v-if="bloc.content.level_2.url">
                    <div>
                      <v-btn
                        depressed
                        x-small
                        @click="reinitItems('level_2')"
                        color="error"
                      >Réinitialiser les positions des étiquettes</v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="flex">
                    <v-text-field
                      label="URL de l'image"
                      prepend-inner-icon="mdi-file-image"
                      v-model="bloc.content.level_2.url"
                      id="v-text-field-for-image"
                    ></v-text-field>
                    <v-btn
                      @click="showMediasLibrary(bloc.content.level_2)"
                      class="mx-2"
                      dark
                      color="primary"
                    >
                      <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp;
                      Bibliothèque
                    </v-btn>
                  </div>
                  <div>
                    <v-text-field
                      label="Ratio largeur/hauteur de l'image"
                      prepend-inner-icon="mdi-file-image"
                      v-model="bloc.content.level_2.image_settings.ratio"
                      id="v-text-field-for-image"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col
                  v-if="bloc.content.level_2.url"
                  cols="12"
                  id="level_2_supwrapper"
                  ref="level_2_supwrapper"
                >
                  <div
                    id="level_2_wrapper"
                    ref="level_2_wrapper"
                    class="wrapper"
                    :style="{
                      aspectRatio: bloc.content.level_2.image_settings.ratio,
                      backgroundImage: `url(${bloc.content.level_2.url})`,
                    }"
                    @click="forceUpdate"
                  >
                    <drag-it-dude
                      v-for="(item, key) in bloc.content.level_2.items"
                      :key="item.id"
                      :id="'item_level_2_' + key"
                      :ref="'item_level_2_' + key"
                      :class="item.position"
                      :style="{ left: item.percentLeft, top: item.percentTop }"
                    >
                      <div class="caption">
                        <div class="caption-index">{{ key + 1 }}</div>
                        <div class="caption-label">{{ item.caption }}</div>
                      </div>
                    </drag-it-dude>
                  </div>
                </v-col>
                <v-col v-if="bloc.content.level_2.url" cols="12" class="text-center">
                  <v-btn
                    v-on:click="
                      addCaption('level_2', bloc.content.level_2.items)
                    "
                    class="ma-2"
                    color="primary"
                    dark
                  >
                    Ajouter une étiquette
                    <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <div v-for="(subitem, subindex) in bloc.content.level_2.items" :key="subindex">
                <v-card v-if="subitem" class="pa-2" shaped style="width: 100%">
                  <v-row>
                    <v-col cols="1" class="centered-flex">
                      <div class="index">{{ subindex + 1 }}</div>
                    </v-col>
                    <v-col cols="5">
                      <v-textarea
                        v-model="subitem.caption"
                        filled
                        label="Etiquette"
                        auto-grow
                        rows="1"
                        @change="forceUpdate"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        :items="positions"
                        filled
                        v-model="subitem.position"
                        label="Position"
                        @change="forceUpdate"
                      ></v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-switch v-model="subitem.hidden" label="Masqué"></v-switch>
                    </v-col>
                    <v-col cols="2" class="centered-flex">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="red lighten-2"
                        v-on:click="
                          deleteCaption(bloc.content.level_2.items, subindex)
                        "
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="subitem.image">
                    <v-col cols="1" class="centered-flex">
                      <div class="img-layout">
                        <img v-if="subitem.image && subitem.image.url" :src="subitem.image.url" alt />
                      </div>
                    </v-col>
                    <v-col cols="11">
                      <div class="flex">
                        <v-text-field
                          label="URL de l'image"
                          prepend-inner-icon="mdi-file-image"
                          v-model="subitem.image.url"
                          id="v-text-field-for-image"
                        ></v-text-field>
                        <v-btn
                          @click="showMediasLibrary(subitem.image)"
                          class="mx-2"
                          dark
                          color="primary"
                        >
                          <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp;
                          Bibliothèque
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <div class="flex">
                        <v-text-field label="Libellé du lien" v-model="subitem.link_label"></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="flex">
                        <v-text-field
                          label="URL du lien"
                          prepend-inner-icon="mdi-link-variant"
                          v-model="subitem.link_url"
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </div>

              <v-row>
                <v-col cols="7">
                  <v-textarea
                    v-model="bloc.content.level_2.credits"
                    filled
                    label="Crédits de l'image"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
                <v-col cols="5">
                  <v-textarea
                    v-model="bloc.content.level_2.credits_more"
                    filled
                    label="Précision sur les crédits (non affiché)"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-textarea
                v-model="bloc.content.level_2.description"
                filled
                label="Description de l'image (optionnel)"
                auto-grow
                rows="2"
              ></v-textarea>
              <v-textarea
                v-model="bloc.content.level_2.source"
                filled
                label="Légende du document"
                auto-grow
                row
                rows="1"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_2"
                v-bind:level="'level_2'"
                :eleve="false"
                :professeur="true"
                :exercice="false"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-row>
                <v-col cols="3">
                  <div class="img-layout">
                    <img
                      id="level_3_img"
                      ref="level_3_img"
                      v-if="bloc.content.level_3.url"
                      :src="bloc.content.level_3.url"
                      alt
                    />
                  </div>
                  <div class="img-infos" v-if="bloc.content.level_3.url">
                    <div>
                      <v-btn
                        depressed
                        x-small
                        @click="reinitItems('level_3')"
                        color="error"
                      >Réinitialiser les positions des étiquettes</v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="flex">
                    <v-text-field
                      label="URL de l'image"
                      prepend-inner-icon="mdi-file-image"
                      v-model="bloc.content.level_3.url"
                      id="v-text-field-for-image"
                    ></v-text-field>
                    <v-btn
                      @click="showMediasLibrary(bloc.content.level_3)"
                      class="mx-2"
                      dark
                      color="primary"
                    >
                      <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp;
                      Bibliothèque
                    </v-btn>
                  </div>
                  <div>
                    <v-text-field
                      label="Ratio largeur/hauteur de l'image"
                      prepend-inner-icon="mdi-file-image"
                      v-model="bloc.content.level_3.image_settings.ratio"
                      id="v-text-field-for-image"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col
                  v-if="bloc.content.level_3.url"
                  cols="12"
                  id="level_3_supwrapper"
                  ref="level_3_supwrapper"
                >
                  <div
                    id="level_3_wrapper"
                    ref="level_3_wrapper"
                    class="wrapper"
                    :style="{
                      aspectRatio: bloc.content.level_3.image_settings.ratio,
                      backgroundImage: `url(${bloc.content.level_3.url})`,
                    }"
                    @click="forceUpdate"
                  >
                    <drag-it-dude
                      v-for="(item, key) in bloc.content.level_3.items"
                      :key="item.id"
                      :id="'item_level_3_' + key"
                      :ref="'item_level_3_' + key"
                      :class="item.position"
                      :style="{ left: item.percentLeft, top: item.percentTop }"
                    >
                      <div class="caption">
                        <div class="caption-index">{{ key + 1 }}</div>
                        <div class="caption-label">{{ item.caption }}</div>
                      </div>
                    </drag-it-dude>
                  </div>
                </v-col>
                <v-col v-if="bloc.content.level_3.url" cols="12" class="text-center">
                  <v-btn
                    v-on:click="
                      addCaption('level_3', bloc.content.level_3.items)
                    "
                    class="ma-2"
                    color="primary"
                    dark
                  >
                    Ajouter une étiquette
                    <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <div v-for="(subitem, subindex) in bloc.content.level_3.items" :key="subindex">
                <v-card v-if="subitem" class="pa-2" shaped style="width: 100%">
                  <v-row>
                    <v-col cols="1" class="centered-flex">
                      <div class="index">{{ subindex + 1 }}</div>
                    </v-col>
                    <v-col cols="5">
                      <v-textarea
                        v-model="subitem.caption"
                        filled
                        label="Etiquette"
                        auto-grow
                        rows="1"
                        @change="forceUpdate"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        :items="positions"
                        filled
                        v-model="subitem.position"
                        label="Position"
                        @change="forceUpdate"
                      ></v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-switch v-model="subitem.hidden" label="Masqué"></v-switch>
                    </v-col>
                    <v-col cols="2" class="centered-flex">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="red lighten-2"
                        v-on:click="
                          deleteCaption(bloc.content.level_3.items, subindex)
                        "
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="subitem.image">
                    <v-col cols="1" class="centered-flex">
                      <div class="img-layout">
                        <img v-if="subitem.image && subitem.image.url" :src="subitem.image.url" alt />
                      </div>
                    </v-col>
                    <v-col cols="11">
                      <div class="flex">
                        <v-text-field
                          label="URL de l'image"
                          prepend-inner-icon="mdi-file-image"
                          v-model="subitem.image.url"
                          id="v-text-field-for-image"
                        ></v-text-field>
                        <v-btn
                          @click="showMediasLibrary(subitem.image)"
                          class="mx-2"
                          dark
                          color="primary"
                        >
                          <v-icon dark>mdi-folder-multiple-image</v-icon>&nbsp;
                          Bibliothèque
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <div class="flex">
                        <v-text-field label="Libellé du lien" v-model="subitem.link_label"></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="flex">
                        <v-text-field
                          label="URL du lien"
                          prepend-inner-icon="mdi-link-variant"
                          v-model="subitem.link_url"
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </div>

              <v-row>
                <v-col cols="7">
                  <v-textarea
                    v-model="bloc.content.level_3.credits"
                    filled
                    label="Crédits de l'image"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
                <v-col cols="5">
                  <v-textarea
                    v-model="bloc.content.level_3.credits_more"
                    filled
                    label="Précision sur les crédits (non affiché)"
                    auto-grow
                    row
                    rows="4"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-textarea
                v-model="bloc.content.level_3.description"
                filled
                label="Description de l'image (optionnel)"
                auto-grow
                rows="2"
              ></v-textarea>
              <v-textarea
                v-model="bloc.content.level_3.source"
                filled
                label="Légende du document"
                auto-grow
                row
                rows="1"
              ></v-textarea>
              <bloc-corriges
                :title="'Corrigés'"
                v-bind:bloc="bloc"
                v-bind:element="bloc.settings.infos.level_3"
                v-bind:level="'level_3'"
                :eleve="false"
                :professeur="true"
                :exercice="false"
              ></bloc-corriges>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <bloc-settings v-bind:bloc="bloc"></bloc-settings>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      <v-card-actions class="bottom">
        <v-spacer></v-spacer>
        <v-btn outlined color="red darken-1" text @click="cancel">Annuler</v-btn>
        <v-btn outlined class="mr-2" text color="green darken-1" @click="save">Enregistrer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BlocSettings from "../blocSettings/BlocSettings.vue";
import { TiptapVuetify, Bold, Italic } from "tiptap-vuetify";
import BlocCorriges from "../../Shared/BlocCorriges.vue";
import DragItDude from "vue-drag-it-dude";

export default {
  components: {
    DragItDude,
    TiptapVuetify,
    "bloc-settings": BlocSettings,
    "bloc-corriges": BlocCorriges
  },
  data() {
    return {
      title_extensions: [Bold, Italic],
      tab: null,
      levels: this.$store.getters.levels,
      positions: ["bas", "droite", "haut", "gauche"]
    };
  },
  props: ["show_hide", "bloc"],
  mounted() {},
  updated: function() {},
  computed: {},
  methods: {
    reinitItems(level) {
      for (let j = 0; j < this.bloc.content[level].items.length; j++) {
        let item = this.bloc.content[level].items[j];
        item.position = "droite";
        item.left = "0px";
        item.right = "0px";
        item.percentLeft = "0%";
        item.percentTop = "0%";
      }
    },
    forceUpdate() {
      //this.$forceUpdate();
    },
    addCaption(level, place) {
      const id = place.length;
      let item_id = "item_" + level + "_" + id;
      let new_item = {};
      new_item.left = "0px";
      new_item.top = "0px";
      new_item.clientWidth = 1000;
      new_item.clientHeight =
        new_item.clientWidth / this.bloc.content[level].image_settings.ratio;
      new_item.percentLeft = "0%";
      new_item.percentTop = "0%";
      place.push({
        id: item_id,
        left: new_item.left,
        top: new_item.top,
        percentLeft: new_item.percentLeft,
        percentTop: new_item.percentTop,
        clientWidth: new_item.clientWidth,
        clientHeight: new_item.clientHeight,
        caption: "Nouvelle étiquette",
        position: "droite",
        hidden: false,
        image: {
          url: null
        }
      });
    },
    deleteCaption(place, index) {
      place.splice(index, 1);
    },
    showMediasLibrary(field) {
      this.$emit("setSelectedMediaItem", field);
      this.$emit("showmedias", "IMAGE");
    },
    cancel() {
      this.$emit("close");
    },
    save() {
      console.clear();
      const payload = this.bloc;
      let wrapperWidth = 1000;
      let levels = ["level_1", "level_2", "level_3"];
      for (let i = 0; i < levels.length; i++) {
        let wrapperHeight =
          wrapperWidth / payload.content[levels[i]].image_settings.ratio;
        for (let j = 0; j < payload.content[levels[i]].items.length; j++) {
          let id = "item_" + levels[i] + "_" + j;
          console.log("id > " + id);
          console.log(this.$refs[id]);
          if (this.$refs[id] !== undefined) {
            let left = this.$refs[id][0].left;
            let top = this.$refs[id][0].top;
            if (left != 0 || top != 0) {
              payload.content[levels[i]].items[j].left = parseInt(left) + "px";
              payload.content[levels[i]].items[j].top = parseInt(top) + "px";
              payload.content[levels[i]].items[j].clientWidth =
                parseInt(wrapperWidth) - 24;
              payload.content[levels[i]].items[j].clientHeight =
                parseInt(wrapperHeight) - 24;
              payload.content[levels[i]].items[j].percentLeft =
                parseFloat(
                  (100 * parseInt(payload.content[levels[i]].items[j].left)) /
                    wrapperWidth
                ) + "%";
              payload.content[levels[i]].items[j].percentTop =
                parseFloat(
                  (100 * parseInt(payload.content[levels[i]].items[j].top)) /
                    wrapperHeight
                ) + "%";
            }
          }
        }
      }
      this.$emit("save", payload);
    }
  }
};
</script>
<style scoped>
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.img-layout img {
  width: 100%;
  height: auto;
}
.wrapper {
  width: 1000px !important;
  height: auto;
  min-height: 200px;
  background-size: cover;
  position: relative;
}

.centered-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}
</style>