<template>
  <div>
    <v-card-text>
      <div v-html="bloc.name"></div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: ["bloc"]
};
</script>

<style>
.v-toolbar__title p {
  margin-bottom: 0;
}
</style>